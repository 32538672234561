import React from 'react'
import { Link } from 'react-router-dom'
import styles from './ClassroomHome.module.css'

const ClassroomHome = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.firstPaintWrapper}>
                <div className={styles.fpBackgroundWrapper}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/fantasy-make-up-landscape.png" />
                </div>
                <div className={styles.fpContentWrapper}>
                    <div className={styles.mainHeader}>
                        <h1>
                            Explore our classroom program courses
                        </h1>
                    </div>
                    <div className={styles.cardTilesWrapper}>
                        <div className={styles.cardTilesContainer}>
                            <Link to="/classroom-courses/make-up-courses" className={styles.eachCardTile}>
                                <div className={styles.eachCardTileTxt}>
                                    <h3>Make-up Artist</h3>
                                </div>
                            </Link>
                            <Link to="/classroom-courses/beauty-courses/enroll-now" className={styles.eachCardTile}>
                                <div className={styles.eachCardTileTxt}>
                                    <h3>Beautician</h3>
                                </div>
                            </Link>
                            <Link to="/classroom-courses/hair-dresser-courses" className={styles.eachCardTile}>
                                <div className={styles.eachCardTileTxt}>
                                    <h3>Hair Dresser / Stylist</h3>
                                </div>
                            </Link>
                            <Link to="/classroom-courses/nail-courses/enroll-now" className={styles.eachCardTile}>
                                <div className={styles.eachCardTileTxt}>
                                    <h3>Nail Artist</h3>
                                </div>
                            </Link>
                            <Link to="/classroom-courses/salon-manager-courses/enroll-now" className={styles.eachCardTile}>
                                <div className={styles.eachCardTileTxt}>
                                    <h3>Salon Manager</h3>
                                </div>
                            </Link>
                            <Link to="/classroom-courses/mehandi-courses/enroll-now" className={styles.eachCardTile}>
                                <div className={styles.eachCardTileTxt}>
                                    <h3>Mehandi</h3>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.secondPaintWrapper}>
                Hey
            </div> */}
        </div>
    )
}

export default ClassroomHome