import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import OtpInput from 'react-otp-input'
import { Link } from 'react-router-dom'
import styles from '../Workshops/GCWCertificateAdmittance.module.css'
import GCWValidateWorkshopRegistration from './GCWValidateWorkshopRegistration'
import { useNavigate } from 'react-router';

const GCWCertificateAdmittance = () => {
    let navigate = useNavigate()
    const [isChecked, setIsChecked] = useState(true);
    const [secondsLeft, setSecondsLeft] = useState(45);
    const [phone, setPhone] = useState('')
    const [isOtpRequested, setIsOtpRequested] = useState(false)
    const [otpRequestedCount, setOtpRequestedCount] = useState(0)
    const [otpEntered, setOtpEntered] = useState({ otp: `` });
    const [isPhoneValidated, setIsPhoneValidated] = useState(false)
    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsLeft((p) => p - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [])


    const handleOtp = (otp) => {
        setOtpEntered({ otp })
        
    }

    const sendOtpHandler = () => {
        setIsOtpRequested(true)
        setSecondsLeft(45)
        setOtpRequestedCount(otpRequestedCount + 1);
    }

    const phoneNumberHandler = (k) => {
        setPhone(k)
        if(k.length===10) {
            setIsPhoneValidated(true)
        } else {
            setIsPhoneValidated(false)
        }
    }

    const phoneNumberValidation = k => {
       
    }

    const validateOtp = () => {
        navigate("/workshop/certificate/assessment/24513/cover");
    }

    useEffect(() => {
        if(otpEntered.otp.length===4) {
            validateOtp();
        }
    }, [otpEntered])
    

    // console.log(otpEntered)

    return (
        <div className={styles.mainWrapper}>
            {isOtpRequested === false ?
                <div className={styles.mainHeaderWrapper}>
                    Get Your Certificate!
                </div>
                :
                <div className={styles.mainHeaderWrapper}>
                    <div className={styles.headerIcon} onClick={() => setIsOtpRequested(false)}>
                        <FaChevronLeft size={22} />
                    </div>
                    <div className={styles.headerTxt}>
                        Enter Otp
                    </div>
                </div>
            }
            {isOtpRequested === false ?
                <div className={styles.mainBodyWrapper}>
                    <div className={styles.mainBodyContent}>
                        <div className={styles.titleBigWrapper}>
                            <div className={styles.titleBigBold}>
                                Claim
                            </div>
                            <div>
                                your certificate here!
                            </div>
                        </div>
                        {/* <div className={styles.title}>
                        Have you attended the workshop?
                    </div> */}

                        {/* <div className="timer" style={{position:'absolute', top: '100vh'}}> */}
                        <div className={styles.dataBoxWrapper}>
                            <div className={styles.smallInstruction}>
                                Please enter your phone number to get your workshop certificate!
                            </div>
                            <div className={styles.inputBoxWrapper}>
                                <input placeholder="Please enter 10 digits phone number..."
                                    type="number"
                                    onChange={(e) => phoneNumberHandler(e.target.value)}
                                    value={phone}
                                    className={isPhoneValidated===true ? styles.phoneBox : styles.phoneBoxInvalid}
                                ></input>
                            </div>
                            <div className={styles.checkTncWrapper}>
                                <div className={styles.checkTnc} onClick={() => setIsChecked(!isChecked)}>
                                    <div className={styles.checkBox}>
                                        {isChecked ?
                                            <MdCheckBox size={20} />
                                            :
                                            <MdCheckBoxOutlineBlank size={20} />
                                        }
                                    </div>
                                    <div className={styles.tncTxt}>
                                        Do you qualify to get the certificate?
                                    </div>
                                </div>
                                <div className={styles.readCertificateCriteria}>
                                    <Link to="/workshop/certificate/eligiblity"
                                        className={styles.readCertificateCriteriaTxt}
                                    >
                                        Read certificate eligiblity!
                                    </Link>
                                </div>
                            </div>
                            <div className={styles.submitBtnWrapper}>
                                {isPhoneValidated === true ?
                                    <button onClick={() => sendOtpHandler()}>Submit</button>
                                    :
                                    <button onClick={() => alert(`Please enter 10 digit phone number`)}>Submit</button>
                                }
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>

                :
                <div className={styles.enterOtpWrapper} >
                    <div style={{ position: 'absolute', top: `100vh`, width: `100%` }} className="timer">
                        <div className={styles.enterOtpContent}>
                            <div className={styles.sectionTitle}>
                                Verify you Phone Number
                            </div>
                            <div className={styles.sectionSubTitle}>
                                Enter otp received on +91-{phone}
                            </div>
                            <div className={styles.otpEnterHandler}>
                                <OtpInput
                                    numInputs={4}
                                    isInputNum={true}
                                    className={styles.otpInputs}
                                    onChange={handleOtp}
                                    value={otpEntered.otp}
                                />
                            </div>
                            {/* <div className={styles.otpActionsWrapper}>
                                <button className={styles.otpCancelBtn}>Cancel</button>
                                <button className={styles.otpSubmitBtn} onClick={() => setIsOtpRequested(false)}>Submit</button>
                            </div> */}
                            {otpRequestedCount < 6 ?

                                <div className={styles.resendCodeWrapper}>
                                    <div className={styles.didnotReceiveTxt}>
                                        Didn't receive any code?
                                    </div>

                                    <div>
                                        {secondsLeft > 0 ?
                                            <div className={styles.resendCode}>
                                                Resend code in {secondsLeft} seconds
                                            </div>
                                            :
                                            <div className={styles.resendCode} onClick={() => sendOtpHandler()}>
                                                Resend Code
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className={styles.resendCodeWrapper}>
                                    <div className={styles.didnotReceiveTxt}>
                                        Having trouble receiving Otp?
                                    </div>
                                    <a href="https://wa.me/919319408406" target="_blank" className={styles.contactUs} >
                                        <div className={styles.contactUsTxt}>
                                            Contact us!
                                        </div>
                                    </a>
                                </div>
                            }


                        </div>
                    </div>
                </div>
            }

            {/* <div className={styles.mainFooterWrapper}>
                <Link to="/workshop/certificate/assesment/24513" className={styles.verifyBtn}>
                    Assesment
                </Link>
            </div> */}
        </div>
    )
}

export default GCWCertificateAdmittance