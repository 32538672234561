import React from 'react'
import '../Workshops/EachWorkshopPageComponent.scss'



const EachWorkshopPageComponent = () => {
    return (
        <div className="topmain">
            <div class="page-bg"></div>
            <div class="animation-wrapper">
                <div class="particle particle-1"></div>
                <div class="particle particle-2"></div>
                <div class="particle particle-3"></div>
                <div class="particle particle-4"></div>
            </div>
        </div>
    )
}

export default EachWorkshopPageComponent