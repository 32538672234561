import React from 'react'
import { useLottie } from 'lottie-react';
import automatedNotificationsAnimation from '../Json/automatedNotificationsAnimation.json'

const AutomatedNotificationsAI = () => {
    const options = {
        animationData: automatedNotificationsAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default AutomatedNotificationsAI