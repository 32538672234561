import React from 'react'
import styles from '../Workshops/EachWorkshopTheory.module.css'

const EachWorkshopTheory = props => {
    let item = props.item;
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.aboutSection}>
                <div className={styles.title}>about Workshop</div>
                <div className={styles.description}>
                    {item.workshopDescription}
                </div>
            </div>
        </div>
    )
}

export default EachWorkshopTheory