import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HireSalonStaffAI from '../../Icons/Animated/HireSalonStaffAI';
import SearchSalonJobsAI from '../../Icons/Animated/SearchSalonJobsAI';
import styles from './StickyBottomNavAtHome.module.css';

const StickyBottomNavAtHome = () => {
    const [isFooterVisible, setIsFooterVisible] = useState(false);

    useEffect(() => {
        const homeFooter = document.getElementById('fpal');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    setIsFooterVisible(entry.isIntersecting);
                });
            },
            {
                threshold: 0.1, // Adjust this value to detect partial visibility
            }
        );

        if (homeFooter) {
            observer.observe(homeFooter);
        }

        return () => {
            if (homeFooter) {
                observer.unobserve(homeFooter);
            }
        };
    }, []);

    return (
        <div
            className={styles.mainWrapper}
            style={{ display: isFooterVisible ? 'block' : 'none' }}
        >
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <Link to="/find-salon-jobs" className={styles.findSalonJobsWrapper}>
                        <div className={styles.iconWrapper}>
                            <SearchSalonJobsAI />
                        </div>
                        <div className={styles.btnTxt}>
                            Find Salon Jobs
                        </div>
                    </Link>
                    <Link to="/hire-salon-staff" className={styles.hireSalonStaffWrapper}>
                        <div className={styles.iconWrapper}>
                            <HireSalonStaffAI />
                        </div>
                        <div className={styles.btnTxt}>
                            Hire Salon Staff
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default StickyBottomNavAtHome;
