import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import OtpInput from 'react-otp-input'
import { useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import NavbarHandler from '../../Helper/NavbarHandler'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/AuthContext'
import styles from './LoginForWorkshop.module.css'


const LoginForWorkshop = () => {
    const { workshopIdentifier } = useParams()
    const authCtx = useContext(AuthContext)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [isSendingOtp, setIsSendingOtp] = useState(false)
    const [hasOtpSent, setHasOtpSent] = useState(false)
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false)
    const [secondsLeft, setSecondsLeft] = useState(30)
    const [requestCount, setRequestCount] = useState(0)
    const [sessionKey, setSessionKey] = useState('')
    const [isValidatingOTP, setIsValidatingOTP] = useState(false)

    const [otp, setOtp] = useState('');

    const phoneNumberHandler = (k) => {
        if (k.length < 11) {
            if (validatePhoneNumber(k)) {
                setIsPhoneNumberValid(true)
                setPhoneNumber(k)
            } else {
                setIsPhoneNumberValid(false)
                setPhoneNumber(k)
            }
        }
    }

    function validatePhoneNumber(phoneNumber) {
        const regex = /^[6789]\d{9}$/;
        return regex.test(phoneNumber);
    }


    const sendOTPOnWhatsApp = async () => {
        console.log(requestCount, sessionKey, phoneNumber)
        setIsSendingOtp(true)
        const sendOTPOnWhatsAppResponse = await fetch(BASE_URL_API + "/sendOTPOnWhatsApp",
            {
                method: "POST",
                body: JSON.stringify({
                    phoneNumber: phoneNumber,
                    requestCount: requestCount,
                    sessionKey: sessionKey
                })
            });

        if (!sendOTPOnWhatsAppResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendOTPOnWhatsAppRespo = await sendOTPOnWhatsAppResponse.json()
            if (sendOTPOnWhatsAppRespo.status === "success") {
                setHasOtpSent(true)
                startResendTimer()
                setRequestCount(sendOTPOnWhatsAppRespo?.response?.requestCount)
                setSessionKey(sendOTPOnWhatsAppRespo?.response?.sessionKey)
            } else {

            }
            console.log(sendOTPOnWhatsAppRespo)
        }
        setIsSendingOtp(false)

    }

    const startResendTimer = () => {
        let intervalId = setInterval(() => {
            setSecondsLeft(prevSecondsLeft => {
                if (prevSecondsLeft > 0) {
                    return prevSecondsLeft - 1;
                } else {
                    clearInterval(intervalId); // Stop the timer
                    return prevSecondsLeft;
                }
            });
        }, 1000);
    };

    const verifyAuthOTP = async () => {
        setIsValidatingOTP(true)
        const verifyAuthOTPResponse = await fetch(BASE_URL_API + "/verifyAuthOTP",
            {
                method: "POST",
                body: JSON.stringify({
                    phoneNumber: phoneNumber,
                    sessionKey: sessionKey,
                    otp: otp
                })
            });

        if (!verifyAuthOTPResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const verifyAuthOTPRespo = await verifyAuthOTPResponse.json()
            if (verifyAuthOTPRespo.status === "success") {
                toast.success(verifyAuthOTPRespo?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                authCtx.login(verifyAuthOTPRespo?.authToken, verifyAuthOTPRespo.userId)
            } else {
                toast.error(verifyAuthOTPRespo?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }



            console.log(verifyAuthOTPRespo)
        }
        setIsValidatingOTP(false)
    }


    useEffect(() => {
        if (otp.length === 4) {
            verifyAuthOTP()
        }
    }, [otp])




    return (
        <div className={styles.mainWrapper} >
            <NavbarHandler type="home" />
            <Helmet>
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            // transition: Bounce,
            />
            <div className={styles.mainContainer}>
                <div className={styles.upperContainer}>

                    <div className={styles.firstLineWrapper}>
                        <div className={styles.registerTxt}>
                            <h2>Register</h2>
                        </div>
                        <div className={styles.forWorkshopTxt}>
                            <p>
                                For Workshop
                            </p>
                        </div>
                    </div>

                    <div className={styles.threeLineDivider}>
                        <div className={styles.eachLine}></div>
                        <div className={styles.eachLine}></div>
                        <div className={styles.eachLine}></div>
                    </div>

                    {hasOtpSent === false ?
                        <div className={styles.fieldsWrapper}>
                            <div className={styles.phoneNumberWrapper}>
                                <div className={styles.phoneCaption}>
                                    Phone Number
                                </div>
                                <div className={styles.phoneInput}>
                                    <input
                                        placeholder="Enter 10 digit phone number"
                                        onChange={(e) => phoneNumberHandler(e.target.value)}
                                        type="number"
                                        value={phoneNumber}
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div className={styles.registerBtnWrapper}>
                                {isPhoneNumberValid === false ?
                                    <button className={styles.registerBtnDull}>Register</button>
                                    :
                                    isSendingOtp === false ?
                                        <button className={styles.registerBtnActive} onClick={() => sendOTPOnWhatsApp()} >Register</button>
                                        :
                                        <button className={styles.registerBtnActive} >Sending OTP...</button>
                                }
                            </div>
                            <div className={styles.promoTxtWrapper}>
                                <div className={styles.limitedSpotTxt}> Only limited Spots left, hurry up!</div>
                            </div>
                        </div>
                        :
                        <div className={styles.fieldsWrapper}>
                            <div>
                                <div className={styles.otpSentTxt}>
                                    We have sent a 4 digit OTP through WhatsApp
                                </div>
                                <div className={styles.changePhoneWrapper}>
                                    <div className={styles.sentOnNumber}>
                                        {phoneNumber}
                                    </div>
                                    <div className={styles.changePhoneTxt} onClick={() => setHasOtpSent(false)}>
                                        Change
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.pleaseEnterOtp}>
                                        Please enter OTP
                                    </div>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        renderSeparator={<span>-</span>}
                                        containerStyle={styles.otpContainer}
                                        autoFocus
                                    />
                                    {isValidatingOTP && `validating otp....`}
                                </div>

                                <div className={styles.resendWrapper}>
                                    <div className={styles.haventReceivedTxt}>Have not received the OTP?</div>
                                    {secondsLeft > 0 ?
                                        <div className={styles.resendOtpTimer}>
                                            Resend OTP in {secondsLeft} seconds
                                        </div>
                                        :
                                        isSendingOtp === false ?
                                            <div className={styles.resendOtp} onClick={() => sendOTPOnWhatsApp()} >
                                                Resend OTP
                                            </div>
                                            :
                                            <div className={styles.resendOtp}  >
                                                Sending OTP...
                                            </div>
                                    }
                                </div>
                            </div>
                            {/* <div className={styles.registerBtnWrapper}>
                                <button className={styles.registerBtnDull}>Register</button>
                            </div> */}
                        </div>
                    }


                </div>




            </div>
        </div >
    )
}

export default LoginForWorkshop