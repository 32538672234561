import React from 'react'
import { useLottie } from 'lottie-react';
import businessLoginAnimation from '../Json/businessLoginAnimation.json'

const BusinessLoginAI = () => {
    const options = {
        animationData: businessLoginAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default BusinessLoginAI