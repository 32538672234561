import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer'
import NavbarHandler from '../../Helper/NavbarHandler'
import AutomatedNotificationsAI from '../../Icons/Animated/AutomatedNotificationsAI'
import BusinessLoginAI from '../../Icons/Animated/BusinessLoginAI'
import CentralizedManagementAI from '../../Icons/Animated/CentralizedManagementAI'
import HireSalonStaffBigAI from '../../Icons/Animated/HireSalonStaffBigAI'
import RealTimeTrackingAI from '../../Icons/Animated/RealTimeTrackingAI'
import StreamlinedCordinationAI from '../../Icons/Animated/StreamlinedCordinationAI'
import TrainYourStaffAI from '../../Icons/Animated/TrainYourStaffAI'
import styles from './ForSalon.module.css'

const ForSalon = () => {
    useEffect(() => {
        const bodyEl = document.getElementsByTagName("body")[0]
        if (bodyEl) {
            bodyEl.classList.remove("no-scroll")
        }
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ffffff");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ffffff";
            document.head.appendChild(meta);
        }
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <NavbarHandler type="home" />
            <div className={styles.mainContainer}>
                <div className={styles.mainPageTitle}>
                    <h1>Solutions of Businesses</h1>
                </div>
                <div>
                    <div className={styles.eachBusinessWrapper}>
                        <div className={styles.businessHeader}>
                            <div className={styles.businessHeaderContainer}>
                                For Salons Owners
                            </div>
                            <div className={styles.businessSubHeaderContainer}>
                                <p>
                                    More than 2000+ salons across India trusts stylelink and rely for their immediate staffing need.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={styles.tilesContainer}>
                                <div className={styles.eachTileWrapper}>
                                    <div className={styles.eachTileHeader}>
                                        <div className={styles.eachTileIcon}>
                                            <BusinessLoginAI />
                                        </div>
                                    </div>
                                    <div className={styles.eachTileBody}>
                                        <h3>Login Into Business Account</h3>
                                        <p>
                                            Manage all your hiring requests, staff interviews, trials, generate offer letter,
                                            pay slips, get verifications done, all in just one place.
                                        </p>
                                    </div>
                                    <div className={styles.eachTileFooter}>
                                        <a href="https://business.stylelink.in" className={styles.actionBtn}>
                                            Login Now
                                        </a>
                                    </div>
                                </div>
                                <div className={styles.eachTileWrapper}>
                                    <div className={styles.eachTileHeader}>
                                        <div className={styles.eachTileIcon}>
                                            <HireSalonStaffBigAI />
                                        </div>
                                    </div>
                                    <div className={styles.eachTileBody}>
                                        <h3>Hire Salon Staff</h3>
                                        <p>
                                            Are you facing problems with salon staff hiring?
                                        </p>
                                        <p>
                                            Hire professional staff in just 48 hours
                                        </p>
                                    </div>
                                    <div className={styles.eachTileFooter}>
                                        <Link to={`/hire-salon-staff`} className={styles.actionBtn}>Hire Staff</Link>
                                    </div>
                                </div>
                                <div className={styles.eachTileWrapper}>
                                    <div className={styles.eachTileHeader}>
                                        <div className={styles.eachTileIcon}>
                                            <TrainYourStaffAI />
                                        </div>
                                    </div>
                                    <div className={styles.eachTileBody}>
                                        <h3>Get Training for Your Staff</h3>
                                        <p>
                                            Conduct a training needs analysis for employees and source appropriate training to help improve and develop their skills.
                                        </p>
                                    </div>
                                    <div className={styles.eachTileFooter}>
                                        <Link to={`/train-your-salon-staff`} className={styles.actionBtn}>Train Your Staff</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={styles.eachBusinessWrapper}>
                        <div className={styles.businessHeader}>
                            <div className={styles.businessHeaderContainer}>
                                For Academies / Institutions in Beauty Industry
                            </div>
                            <div className={styles.businessSubHeaderContainer}>
                                <p>
                                    Solution to enhance recruitment and placement
                                    processes, ensuring efficiency and real-time
                                    coordination across all students / academies / franchise.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={styles.tilesContainer}>
                                <div className={styles.eachTileWrapper}>
                                    <div className={styles.eachTileHeader}>
                                        <div className={styles.eachTileIconFt}>
                                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/fast-track-assets/fastTrack-black.png" />
                                        </div>
                                    </div>
                                    <div className={styles.eachTileBody}>
                                        <h3 className={styles.ftTitle}>FAST TRACK</h3>
                                        <p>
                                            Empower every talent of your institution
                                            with Fast Track.
                                        </p>
                                        <p>
                                            Explore the leading integrated students management platform for academies, with tools for beauty professionals and anyone else whoever is a part of their beauty & salon industry.
                                        </p>
                                        <div className={styles.ftUspWrapper}>
                                            <div className={styles.eachFtUspWrapper}>
                                                <div className={styles.eftuIconWrapper}>

                                                    <div className={styles.eftuIcon}>
                                                        <RealTimeTrackingAI />
                                                    </div>
                                                </div>
                                                <div className={styles.eftuTitle}>
                                                    Real-Time Tracking
                                                </div>
                                            </div>
                                            <div className={styles.eachFtUspWrapper}>
                                                <div className={styles.eftuIconWrapper}>

                                                    <div className={styles.eftuIcon}>
                                                        <AutomatedNotificationsAI />
                                                    </div>
                                                </div>
                                                <div className={styles.eftuTitle}>
                                                    Automated Notifications
                                                </div>
                                            </div>
                                            <div className={styles.eachFtUspWrapper}>
                                                <div className={styles.eftuIconWrapper}>

                                                    <div className={styles.eftuIcon}>
                                                        <StreamlinedCordinationAI />
                                                    </div>
                                                </div>
                                                <div className={styles.eftuTitle}>
                                                    Streamlined Coordination
                                                </div>
                                            </div>
                                            <div className={styles.eachFtUspWrapper}>
                                                <div className={styles.eftuIconWrapper}>

                                                    <div className={styles.eftuIcon}>
                                                        <CentralizedManagementAI />
                                                    </div>
                                                </div>
                                                <div className={styles.eftuTitle}>
                                                    Centralized Management
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachTileFooterFt}>
                                        <a href="https://fast-track.stylelink.in" className={styles.actionBtnFt}>
                                            Explore Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ForSalon