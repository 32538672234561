import React from 'react'
import styles from '../Styles/Components/OtherJobsByCompany.module.css'

const OtherJobsByCompany = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.ch}>
        <h2>Other Jobs By Salon</h2>

      </div>
    </div>
  )
}

export default OtherJobsByCompany