import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { BsTrophy } from 'react-icons/bs'
import { FaClock, FaSearchengin } from 'react-icons/fa'
import { GrRadialSelected, GrShareOption } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import OnlyCopyright from '../../Components/Footers/OnlyCopyright'
import HireSalonStaffClientsReview from '../../Components/ForBusinesses/HireSalonStaffClientsReview'
import HireSalonStaffHomeFAQs from '../../Components/ForBusinesses/HireSalonStaffHomeFAQs'
import styles from './HireSalonStaffHome.module.css'

const HireSalonStaffHome = () => {
    const pageTitle = "Hire Salon Staff In Just 48 hours : Stylelink"
    const pageDescription = "Hire salon professional staff like beautician, hair-dresser, nail artist, make-up artist, salon helper in just 48 hours anywhere in India with stylelink."
    const [reviews, setReviews] = useState([
        {
            title: 'Very easy hiring process',
            content: "It's truly very easy to hire staff from stylelink. I have never thought that hiring could be this easier.",
            author: "Rihan - Jawed Habib Unisex Salon",
            rating: 5
        },
        {
            title: 'Expert resolutions by expert team',
            content: "The stylink team is very helpful, they help you every possible way to understand your salon requirements and share the shortlisted profile within committed time-frame.",
            author: "Kamlesh - Lotus Unisex Salon",
            rating: 5
        },
        {
            title: 'Quick services',
            content: "Important is to find the immediate replacement of candidates in our salon line, and stylelink is the best way to find talents.",
            author: "Diksha - Vinca Hair Clinic & Salon",
            rating: 5
        }
    ])

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const x = 150; // Adjust the number of pixels for visibility as needed
            const y = 100; // Adjust the number of pixels before reaching the bottom as needed

            const scrollPosition = window.scrollY;

            // Show the component if scrolled more than triggerScroll pixels
            if (scrollPosition > x && scrollPosition < documentHeight - windowHeight - y) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [faqs, setFaqs] = useState([
        {
            question: "How to hire salon staff quickly using Stylelink?",
            answer: "With Stylelink, hiring salon staff is a swift and efficient process. Simply create a hiring request on our platform, and we'll carefully select and present you with top-quality candidates within 48 hours, eliminating the lengthy and tedious traditional hiring process."
        },
        {
            question: "How does Stylelink ensure a 48-hour hiring process?",
            answer: "Stylelink optimizes the hiring process by pre-screening and carefully selecting candidates before presenting them to you. This proactive approach helps expedite the hiring timeline to 48 hours or less."
        },
        {
            question: "Can I specify the skills and experience I'm looking for in candidates?",
            answer: "Absolutely! Stylelink understands your desired skills, experience levels, and qualifications, ensuring we filter applications based on your specific requirements."
        },
        {
            question: "What sets Stylelink apart from other recruitment platforms?",
            answer: "Stylelink stands out by personally filtering and screening applications using our AI, saving you time and effort. We focus on presenting you with pre-vetted, top-tier candidates who align with the salon and beauty industry."
        },
        {
            question: "How can I trust the quality of candidates provided by Stylelink?",
            answer: "We take pride in our rigorous candidate screening process. Our dedicated team and algorithms ensure that every candidate is carefully vetted and matches your hiring needs, providing you with high-quality choices."
        },
        {
            question: "How do I get started with Stylelink's hiring process?",
            answer: "To begin, simply give us call us on +91-8383-05-4040 or reach out to us via our website. Our team will guide you through the process, understanding your requirements and initiating a smooth and efficient hiring journey."
        },
        {
            question: "What types of roles and positions can I hire for through StyleLink?",
            answer: "Stylelink caters to a wide range of positions in the salon and beauty industry. From hairstylists, beautician, make-up artist, mehandi artist, nail artist, estheticians to spa therapists and salon managers, we can help you find talent for various roles within your establishment."
        },
        {
            question: "Is there ongoing support during the hiring process?",
            answer: "Absolutely! We provide continuous support and guidance throughout the hiring process, addressing any queries or concerns you may have to ensure a successful hiring experience."
        }
    ])

    const handleComponentClick = () => {
        // Handle what happens when the component is clicked
        // For example, scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove("no-scroll")
    }, []);




    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription}></meta>
                <meta name="keywords" content="hire salon staff, need salon staff, recruit, salon job, salon vacany, parlour job, spa job, hire salon in 48 hours, stylelink, immediate salon hiring" ></meta>

                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp" />

                <meta name="whatsapp:url" content={`https://stylelink.in/hire-salon-staff/`} />
                <meta name="whatsapp:title" content={pageTitle} />
                <meta name="whatsapp:description" content={pageDescription} />
                <meta name="whatsapp:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp" />

                <meta name="telegram:url" content={`https://stylelink.in/hire-salon-staff`} />
                <meta name="telegram:title" content={pageTitle} />
                <meta name="telegram:description" content={pageDescription} />
                <meta name="telegram:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp" />

                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                                "@type": "Question",
                                "name": "${faqs[0]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[0].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[1]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[1].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[2]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[2].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[3]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[3].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[4]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[4].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[5]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[5].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[6]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[6].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[7]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[7].answer}"
                                }
                            }
                        ]
                        }
                    `}
                </script>
                <script type="application/ld+json">
                    {
                        `
                        {
                            "@context": "http://schema.org",
                            "@type": "Organization",
                            "name": "Stylelink",
                            "description": "A company providing hiring services for salons.",
                            "aggregateRating": {
                              "@type": "AggregateRating",
                              "ratingValue": "5",
                              "reviewCount": "1280"
                            },
                            "review": [
                                {
                                    "@type": "Review",
                                    "name": "${reviews[0]?.title}",
                                    "reviewRating": {
                                    "@type": "Rating",
                                    "ratingValue": "${reviews[0]?.rating}"
                                    },
                                    "author": {
                                    "@type": "Person",
                                    "name": "${reviews[0]?.author}"
                                    },
                                    "datePublished": "2023-10-17",
                                    "description": "${reviews[0]?.content}"
                                },
                                {
                                    "@type": "Review",
                                    "name": "${reviews[1]?.title}",
                                    "reviewRating": {
                                    "@type": "Rating",
                                    "ratingValue": "${reviews[1]?.rating}"
                                    },
                                    "author": {
                                    "@type": "Person",
                                    "name": "${reviews[1]?.author}"
                                    },
                                    "datePublished": "2023-10-17",
                                    "description": "${reviews[1]?.content}"
                                },
                                {
                                    "@type": "Review",
                                    "name": "${reviews[2]?.title}",
                                    "reviewRating": {
                                      "@type": "Rating",
                                      "ratingValue": "${reviews[2]?.rating}"
                                    },
                                    "author": {
                                      "@type": "Person",
                                      "name": "${reviews[2]?.author}"
                                    },
                                    "datePublished": "2023-10-17",
                                    "description": "${reviews[2]?.content}"
                                }
                            ]
                          }                                                                              
                        `
                    }
                </script>
            </Helmet>
            <div className={styles.firstPaintWrapper}>
                <div className={styles.firstPaintContent}>
                    <div className={styles.firstPaint}>
                        <div className={styles.header}>
                            <div className={styles.menu}>
                                <img src="https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/golden-color-stylelink-logo-main.png" />
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={styles.fpContent}>
                            <div className={styles.firstLineWeb}>
                                <h2>
                                    Are you facing problems with salon staff hiring?
                                </h2>
                            </div>
                            <div className={styles.firstLineMobile}>
                                <h2>
                                    Are you facing problems
                                </h2>
                                <h2>
                                    with salon staff hiring?
                                </h2>
                            </div>
                            <div className={styles.boxLineWrapper}>
                                <div className={styles.boxLineContainer}>
                                    <h1>Hire professional staff in just 48 hours</h1>
                                </div>
                            </div>
                            <div className={styles.howToHireWrapper}>
                                <div className={styles.howToHireHeader}>
                                    How to hire?
                                </div>
                                <div className={styles.howToHireSteps}>
                                    <div className={styles.eachStepWrapper}>
                                        <div className={styles.stepCount}>1</div>
                                        <div className={styles.stepTxt}>Fill your details</div>
                                    </div>
                                    <div className={styles.eachStepWrapper}>
                                        <div className={styles.stepCount}>2</div>
                                        <div className={styles.stepTxt}>Executive will call you</div>
                                    </div>
                                    <div className={styles.eachStepWrapper}>
                                        <div className={styles.stepCount}>3</div>
                                        <div className={styles.stepTxt}>Conduct trials & interviews</div>
                                    </div>
                                    <div className={styles.eachStepWrapper}>
                                        <div className={styles.stepCount}>4</div>
                                        <div className={styles.stepTxt}>Hire staff in 48 hours</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.hireNowBtnWrapper}>
                                <Link to={'quickly'}>
                                    <button>Hire Now</button>
                                </Link>
                            </div>

                            <div className={styles.benefitTitleWrapper}>
                                <div className={styles.benefitTitle}>
                                    <div className={styles.leftLine}></div>
                                    <div className={styles.benefitTitleTxt}>
                                        <h5>
                                            Benefits of Hiring from Stylelink
                                        </h5>
                                    </div>
                                    <div className={styles.rightLine}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.shapeWrapper}>
                    <div className={styles.shapeContainer}>
                        {/* <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/down-side-golder-layer-png.png" /> */}
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/down-side-golder-layer-png-no-shadow.png" />
                    </div>
                </div>
            </div>
            <div className={styles.secondPaintWrapper}>
                <div className={styles.uspWrapper}>
                    <div className={styles.eachUsp}>
                        <div>
                            <GrShareOption size={30} />
                        </div>
                        <div className={styles.caption}>
                            Handpicked candidates
                        </div>
                    </div>
                    <div className={styles.eachUsp}>
                        <div>
                            <FaSearchengin size={30} />
                        </div>
                        <div className={styles.caption}>
                            Pre-screened candidates
                        </div>
                    </div>
                    <div className={styles.eachUsp}>
                        <div>
                            <FaClock size={30} />
                        </div>
                        <div className={styles.caption}>
                            Immediate Hiring
                        </div>
                    </div>
                    <div className={styles.eachUsp}>
                        <div>
                            <BsTrophy size={30} />
                        </div>
                        <div className={styles.caption}>
                            Always Hire Best
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.ourPresence}>
                <div className={styles.opHeader}>
                    <h4>Your nationwide salon staffing partner</h4>
                </div>
                <div className={styles.opBody}>
                    <div className={styles.opbLeft}>
                    </div>
                    <div className={styles.opbRight}>
                        <p>
                            With a pan India presence in 40+ cities and growing,
                            StyleLink is the largest salon and beauty industry hiring platform in the country.
                            No matter where your salon or beauty parlor is located,
                            we're there to assist you in finding the ideal talent for your team.
                        </p>
                    </div>
                </div>
            </div>

            <HireSalonStaffClientsReview reviews={reviews} />
            <div className={styles.seePricingWrapper}>
                <Link to="/hire-salon-staff/pricing" >See Pricing</Link>
            </div>

            <HireSalonStaffHomeFAQs faqs={faqs} />
            <OnlyCopyright />


            <Link
                to="quickly"
                className={visible ? styles.stickyBtn : styles.stickyBtnHidden}
            >
                <div className={visible ? styles.hireNowSticky : styles.hireNowStickyHidden}>
                    Hire Now
                </div>
            </Link>

        </div >
    )
}

export default HireSalonStaffHome