import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './PLPEachFAQ.module.css'

const PLPEachFAQ = props => {
    const { item } = props;
    const [isOpen, setIsOpen] = useState(true)
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.questionWrapper} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.question}>
                    <h4>{item?.question}</h4>
                </div>
                <div className={styles.icon}>
                    {isOpen === true ? <FaChevronUp /> : <FaChevronDown />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.answer}>
                    <p>{item?.answer}</p>
                </div>
            }
        </div>
    )
}

export default PLPEachFAQ