import React, { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa';
import NavbarHandler from '../Helper/NavbarHandler';
import styles from "../Styles/Courses/SearchAmongCourses.module.css";


import ReactGA from 'react-ga';

const SearchAmongCourses = () => {
  const [popularSearches, setPopularSearches] = useState([
    {
      "title": "Air-brush",
      "icon": "https://cdn-icons-png.flaticon.com/512/4762/4762311.png"
    },
    {
      "title": "Indian Bridal Makeup",
      "icon": "https://cdn-icons-png.flaticon.com/512/4762/4762311.png"
    }
  ]);

  const [searchResults, setSearchResults] = useState(null)

  const searchHandler = (e) => {
    let searchKey = e.target.value;
    if (searchKey.length > 3) {
      setSearchResults([
        {
          "courseTitle": "Basic to Advance",
          "coverImage": "https://images.herzindagi.info/image/2022/Apr/makeup-long-stay.jpg",
        },
        {
          "courseTitle": "Advance Course",
          "coverImage": "https://images.herzindagi.info/image/2022/Apr/makeup-long-stay.jpg",
        },
        {
          "courseTitle": "Advance Course",
          "coverImage": "https://images.herzindagi.info/image/2022/Apr/makeup-long-stay.jpg",
        },
        {
          "courseTitle": "Advance Course",
          "coverImage": "https://images.herzindagi.info/image/2022/Apr/makeup-long-stay.jpg",
        },
        {
          "courseTitle": "Advance Course",
          "coverImage": "https://images.herzindagi.info/image/2022/Apr/makeup-long-stay.jpg",
        },
        {
          "courseTitle": "Advance Course",
          "coverImage": "https://images.herzindagi.info/image/2022/Apr/makeup-long-stay.jpg",
        },
      ])
    } else {
      setSearchResults(null);
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
}, [])

  return (
    <div className={styles.mainContainer}>
      <NavbarHandler  />
      <div className={styles.mainContentWrapper}>
        <div className={styles.seachBarContainer}>
          <input placeholder="Search for any course..." autoFocus={true}
            onChange={(e) => searchHandler(e)}
          />
          <span >
            <FaSearch color="#8a8888" />
          </span>
        </div>

        {searchResults === null ?
          <div className={styles.popularContainer}>
            <div className={styles.popularContainerTitle}>
              <h1>Popular Courses</h1>
            </div>
            <div className={styles.popularContainerBody}>
              {popularSearches.map((item, index) => (
                <div className={styles.eachPopularTag} key={index}>
                  <div className={styles.eachPopularIcon}>
                    <img src={item.icon} />
                  </div>
                  <div className={styles.eachPopularName}>
                    <h3>{item.title}</h3>
                  </div>
                </div>
              ))}

            </div>
          </div>
          :

          <div className={styles.searchResultsContainer}>
            <div className={styles.searchResultsTitle}>
              {searchResults !== null ? searchResults.length : "No"} results found!
            </div>
            {searchResults &&
              <div className={styles.searchResultsBody}>
                {searchResults.map((item, index) => (
                  <div className={styles.eachCourseContainer}>
                    <div className={styles.eachCourseCoverContainer}>
                      <div className={styles.eachCourseCoverImg}>
                        <img src={item.coverImage} />
                      </div>
                      <div className={styles.eachCourseCoverLayer}>
                        <div className={styles.eachCourseDetails}>
                          <div className={styles.courseTitle}>
                            <h3>{item.courseTitle}</h3>
                          </div>
                          <div className={styles.courseBenefitTagsContainer}>
                            <div className={styles.eachBenefitTag}>20 looks</div>
                            <div className={styles.eachBenefitTag}>20 looks</div>
                            <div className={styles.eachBenefitTag}>20 looks</div>
                          </div>

                        </div>
                        <div className={styles.langDurModContainer}>
                          <div>
                            Hindi / English
                          </div>
                          <div>
                            32 hours
                          </div>
                          <div>
                            36 modules
                          </div>
                        </div>
                        <div className={styles.certiNJobContainer}>
                          <div className={styles.certiContainer}>
                            Certificate
                          </div>
                          <div className={styles.jobContainer}>
                            Job Offer up to 45,000/- per month
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        }

      </div>
    </div>
  )
}

export default SearchAmongCourses