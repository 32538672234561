import React from 'react'
import styles from './OnlyCopyright.module.css'

const OnlyCopyright = () => {
    return (
        <div className={styles.mainWrapper}>
            © 2024 stylelink | All Rights Reserved
        </div>
    )
}

export default OnlyCopyright