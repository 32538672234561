import React from 'react'
import styles from '../Components/ApplyNowCard.module.css';

const ApplyNowCard = () => {


    return (
        <div className={styles.mainContainer}>
            <div className={styles.appPromoWrapper}>
                <div className={styles.apwC}>
                    <div className={styles.apwCTitle}>
                        Apply via Stylelink App
                    </div>
                    <div className={styles.apwcCaption}>
                        <div>
                            Applying via stylelink app gives you a smoother experience and
                            helps you track your applications.
                            Also it notifies and reminds you about your interviews and demos.
                        </div>
                        <div className={styles.aac}>
                            Above all, it's in your local language.
                        </div>
                    </div>
                    
                    <div className={styles.applyNowBtn}>
                        <a className={styles.btnApp} target="_blank" href="https://play.google.com/store/apps/details?id=in.stylelink" >
                            Download App
                        </a>
                        <a className={styles.btnDull} href="tel:+91-8287098287" >
                            Call Now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplyNowCard