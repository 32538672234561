import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import NavbarHandler from '../Helper/NavbarHandler'
import styles from '../Styles/Pages/Trainings.module.css';

import ReactGA from 'react-ga';

const Trainings = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])
    


    return (
        <div>
            <NavbarHandler type='home' />
            <div className={styles.mainContainer}>
                <div>
                    We will launching trainings soon..
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Trainings