import React from 'react'
import HireSalonStaffHomeEachFAQ from './HireSalonStaffHomeEachFAQ'
import styles from './HireSalonStaffHomeFAQs.module.css'

const HireSalonStaffHomeFAQs = props => {
    const { faqs } = props

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sectionWrapper}>
                <div className={styles.sectionTitle}>
                    <h3>Frequently Asked Questions (FAQs)</h3>
                </div>
                <div className={styles.questionsWrapper}>
                    {faqs.map((item, index) => (
                        <HireSalonStaffHomeEachFAQ item={item} key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HireSalonStaffHomeFAQs