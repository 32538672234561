import React, { useEffect, useState } from 'react'
import { BsPhoneFill } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { formatDate, getDayOfWeekFromDate, getMeridianFromTime, hoursFromTime, minutesFromTime } from '../../Helper/SupportingAlgos';
import { BASE_URL_API } from '../../References/urls';
import { useTranslation } from '../../store/TranslationContext';
import styles from './InterviewDetails.module.css'

const InterviewDetails = () => {
    const { translations, currentLanguage, changeLanguage } = useTranslation();
    const { identifier } = useParams()
    const [details, setDetails] = useState(null)
    const [hasNotFound, setHasNotFound] = useState(false)
    const [isFetchingDetails, setIsFetchingDetails] = useState(true)

    const getScheduledInterviewDetails = async () => {
        setIsFetchingDetails(true)
        const getScheduledInterviewDetailsResponse = await fetch(BASE_URL_API + "/getScheduledInterviewDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    identifier: identifier
                })
            });

        if (!getScheduledInterviewDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getScheduledInterviewDetailsRespo = await getScheduledInterviewDetailsResponse.json()
            if (getScheduledInterviewDetailsRespo.status === "success") {
                setDetails(getScheduledInterviewDetailsRespo.response)
                setHasNotFound(false)
            } else {
                if (getScheduledInterviewDetailsRespo.message === "no records found!") {
                    setHasNotFound(true)
                } else {

                }
                setDetails(null)
            }
            console.log(getScheduledInterviewDetailsRespo)
        }
        setIsFetchingDetails(false)
    }

    console.log(identifier, details)


    useEffect(() => {
        getScheduledInterviewDetails()
    }, [identifier])


    const getInterviewTimeSlotFromStatuses = (interviewData) => {
        const interviewItem = interviewData.find(item => item.applicationStatus === "INTERVIEW_SLOT_BOOKED");
        return interviewItem ? interviewItem.timeSlot : null;
    }



    if (isFetchingDetails) {
        return (
            <div className={styles.loaderWrapper}>
                <div className={styles.headerWrapper}>
                    <div className={styles.mainTitle}>
                        {translations.interviewDetails}
                    </div>
                    <div className={styles.idTagLine}>
                        {translations.idTagLine}
                    </div>
                </div>
                <div className={styles.iconWrapper}>
                    <div className={styles.iconContainer}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/loaders/loader-fast-trans.gif" />
                    </div>
                </div>
            </div>
        )
    }

    if (hasNotFound) {
        return (
            <div className={styles.mainWrapperInvalidUrl}>
                <div className={styles.iuIconWrapper}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/broken-link-monkeys.gif" />
                </div>
                <div className={styles.iuTxtWrapper}>
                    <div className={styles.iuTitle}>Uh-oh!</div>
                    <div className={styles.iuSubTitle}>Page not found!</div>
                </div>
                <div className={styles.findMoreJobsWrapper}>
                    <Link to="/jobs">Find More Jobs</Link>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.mainTitle}>
                    {translations.interviewDetails}
                </div>
                <div className={styles.idTagLine}>
                    {translations.idTagLine}
                </div>
            </div>
            <div className={styles.mainContainer}>


                <div className={styles.detailsWrapper}>
                    <div className={styles.username}>
                        {translations.hello} {details?.stylist_name},
                    </div>
                    <div className={styles.messageAndSalonDetails}>
                        <div className={styles.smallMsg} >
                            {translations?.youHaveAInterviewScheduledAT}
                        </div>
                    </div>
                    <div className={styles.timeSlotWrapper}>
                        <div className={styles.timeWrapper}>
                            <div className={styles.hour}>{hoursFromTime(getInterviewTimeSlotFromStatuses(details?.statusData))}</div>
                            <div className={styles.minute}>{minutesFromTime(getInterviewTimeSlotFromStatuses(details?.statusData))}</div>
                            <div className={styles.meridian}>{getMeridianFromTime(getInterviewTimeSlotFromStatuses(details?.statusData))}</div>
                        </div>
                        <div className={styles.daysWrapper}>
                            <div className={styles.day}>{getDayOfWeekFromDate(getInterviewTimeSlotFromStatuses(details?.statusData))}</div>
                            <div className={styles.month}>{formatDate(getInterviewTimeSlotFromStatuses(details?.statusData))}</div>
                        </div>
                    </div>
                    <div className={styles.storeDetailsWrapper}>
                        <div className={styles.storeDetailsContainer}>
                            <div className={styles.storeName}>
                                {details?.storeData?.storeName}
                            </div>
                            <div className={styles.storeAddress}>
                                {details?.storeData?.address}
                            </div>
                            <a target="_blank" href={`https://maps.google.com/?q=${details?.storeData?.latitude},${details?.storeData?.longitude}`} className={styles.mapIconWrapper}>
                                <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/api-google-maps.jpg" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className={styles.eachSectionWrapper}>
                    <div className={styles.eachSectionContainer}>
                        <div className={styles.sectionTitle}>
                            {translations?.storeContactDetails}
                        </div>

                        {details?.pocData?.map((item, index) => (
                            <div className={styles.contactDetailsWrapper} key={index} >
                                <div className={styles.userDetailsContainer}>
                                    <div className={styles.userIcon}>
                                        <FaUser size={34} color="#dfdfdf" />
                                    </div>
                                    <div className={styles.pocDetails}>
                                        <div className={styles.pocName}>
                                            {item?.personnelName}
                                        </div>
                                        <div className={styles.pocDesignation}>
                                            {item?.designation}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a href={`tel:+91-${item?.phoneNumber}`}><MdPhone size={23} /></a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.eachSectionWrapper}>
                    <div className={styles.eachSectionContainer}>
                        <div className={styles.sectionTitle}>
                            {translations?.jobDetails}
                        </div>

                        <div className={styles.jobDetailsWrapper}>
                            <div>
                                {details?.jobTitle}
                            </div>
                            <div className={styles.twoInARow}>
                                <div>
                                    {translations?.salaryRange}
                                </div>
                                <div>
                                    ₹{details?.jd?.startingSalary} - ₹{details?.jd?.maximumSalary}/-
                                </div>
                            </div>
                            <div className={styles.twoInARow}>
                                <div>
                                    {translations?.experienceRequired}
                                </div>
                                <div>
                                    {details?.jd?.experience}
                                </div>
                            </div>
                            <div className={styles.twoInARow}>
                                <div>
                                    {translations?.fna}
                                </div>
                                <div>
                                    {details?.jd?.accomodation}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.eachSectionWrapper}>
                    <div className={styles.eachSectionContainer}>
                        <div className={styles.sectionTitle}>
                            {translations?.skillsRequired}
                        </div>

                        <div className={styles.skillsWrapper}>
                            {details?.jd?.skill?.split(",")?.map((itemSkill, indexSkill) => (
                                <div className={styles.eachSkill}>
                                    {itemSkill}
                                </div>
                            ))}
                        </div>

                    </div>

                </div>

                <div className={styles.helpWrapper}>
                    <div className={styles.needHelpTitle}>{translations?.needHelp}</div>
                    <div className={styles.needHelpSubtitle}>
                        {translations?.contactUsImmediately}
                    </div>
                    <div className={styles.contactOptions}>
                        <a target="_blank" href={`tel:+91-${details?.customerSupport?.phone}`} className={styles.callNow}>{translations?.callNow}</a>
                        <a target="_blank" href={`https://wa.me/91${details?.customerSupport?.phone}`} className={styles.whatsApp}>{translations?.whatsApp}</a>
                    </div>
                </div>



                <div className={styles.footerWrapper}>
                    <div className={styles.languageWrapper}>
                        <select
                            onChange={(e) => changeLanguage(e.target.value)}
                            defaultValue={currentLanguage}
                        >
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                        </select>
                    </div>
                </div>
            </div>
            {/* <div className={styles.navWrapper}>
                <div className={styles.confirmTxt}>{translations?.areYouGoingForTheInterview}</div>
                <div className={styles.confirmationBtn}>
                    <button className={styles.disagreeBtn}>No</button>
                    <button className={styles.agreeBtn}>Yes</button>
                </div>
            </div> */}
        </div>
    )
}

export default InterviewDetails