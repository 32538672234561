import React, { useContext, useEffect, useState } from 'react'
import { BsShieldFillPlus } from 'react-icons/bs'
import { GrSecure } from 'react-icons/gr'
import { BASE_URL_API } from '../../References/urls'
import styles from './HireSalonStaffQuickly.module.css'

import { useLocation, useNavigate } from 'react-router';

const HireSalonStaffQuickly = () => {
    let navigate = useNavigate()
    const [companyName, setCompanyName] = useState('')
    const [storeAddress, setStoreAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [personName, setPersonName] = useState('')
    const [designation, setDesignation] = useState('')
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorAt, setErrorAt] = useState(null)

    const [hasRegistered, setHasRegistered] = useState(false)

    const searchParams = useLocation().search
    const selectedPlan = new URLSearchParams(searchParams).get("plan");

    const submitHandler = async () => {
        setIsLoading(true)
        const requestStaffLeadResponse = await fetch(BASE_URL_API + "/requestStaffLead",
            {
                method: "POST",
                body: JSON.stringify({
                    companyName: companyName,
                    storeAddress: storeAddress,
                    phoneNumber: phoneNumber,
                    personName: personName,
                    designation: designation,
                    selectedPlan : selectedPlan
                })
            });

        if (!requestStaffLeadResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const requestStaffLeadRespo = await requestStaffLeadResponse.json()
            if (requestStaffLeadRespo.status === "success") {
                setHasRegistered(true)
                setCompanyName('')
                setStoreAddress('')
                setPhoneNumber('')
                setPersonName('')
                setDesignation('')
                setTimeout(() => navigate('/hire-salon-staff'), 2000)
            } else {
                alert("Something went wrong, please try again");
                console.log(requestStaffLeadRespo)
            }

        }
        setIsLoading(false)
    }

    const formValidator = () => {
        if (companyName.length > 5) {
            setErrorAt(null)
            if (storeAddress.length > 10) {
                setErrorAt(null)
                if (phoneNumber.length === 10) {
                    setErrorAt(null)
                    if (personName.length > 2) {
                        setErrorAt(null)
                        if (designation !== null) {
                            setErrorAt(null)
                            setIsFormValidated(true)
                        } else {
                            setIsFormValidated(false)
                        }
                    } else {
                        setIsFormValidated(false)
                    }
                } else {
                    setIsFormValidated(false)
                }
            } else {
                setIsFormValidated(false)
            }
        } else {
            setIsFormValidated(false)
        }
    }

    const errReflector = () => {
        if (companyName.length <= 5) {
            setErrorAt('companyName')
        } else if (storeAddress.length <= 10) {
            setErrorAt('storeAddress')
        } else if (phoneNumber.length !== 10) {
            setErrorAt('phoneNumber')
        } else if (personName.length <= 2) {
            setErrorAt('personName')
        } else if (designation === null) {
            setErrorAt('designation')
        } else {
            setErrorAt(null)
        }
    }

    useEffect(() => {
        // console.log("Hey")
        formValidator()
    }, [companyName, storeAddress, phoneNumber, personName, designation])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.logoWrapper}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/golden-color-stylelink-logo-main.png" />
                </div>
                <div className={styles.hireTxtBigger}>
                    <h1>
                        Hire Salon Staff in <br></br> Just 48 hours
                    </h1>
                </div>
                <div className={styles.inJustFewHours}>
                    <div className={styles.leftLine}></div>
                    <div className={styles.hireTxt}><h2>Reinvent your staff hiring</h2></div>
                    <div className={styles.rightLine}></div>
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {isLoading &&
                    <div className={styles.rightLoader}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/loaders/loader-fast-trans.gif" />
                    </div>
                }
                <div className={styles.rightContainer}>
                    <div className={styles.statusTitle}>
                        {hasRegistered === true ?
                            <div className={styles.success}>Sucessfully Registered!</div>
                            :
                            <div className={styles.hireNowTxt}>Hire Now!</div>
                        }
                    </div>
                    <div>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldTitle}>
                                <h4>Salon Name</h4>
                                {errorAt === 'companyName' && <p>Please enter a valid name</p>}
                            </div>
                            <div className={styles.fieldInput}>
                                <input placeholder="Salon / store name..."
                                    value={companyName}
                                    autoFocus={true}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldTitle}>
                                <h4>Store Address</h4>
                                {errorAt === 'storeAddress' && <p>Please enter a valid address</p>}
                            </div>
                            <div className={styles.fieldInput}>
                                <input
                                    value={storeAddress}
                                    placeholder="Enter full salon/store address..."
                                    onChange={(e) => setStoreAddress(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldTitle}>
                                <h4>Phone number</h4>
                                {errorAt === 'phoneNumber' && <p>Please enter a 10 digit phone</p>}
                            </div>
                            <div className={styles.fieldInput}>
                                <input
                                    value={phoneNumber}
                                    placeholder="Please enter 10 digit contact number..." type="number"
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldTitle}>
                                <h4>Person Name</h4>
                                {errorAt === 'personName' && <p>Please enter a valid name</p>}
                            </div>
                            <div className={styles.fieldInput}>
                                <input
                                    value={personName}
                                    placeholder="Please enter your full name..."
                                    onChange={(e) => setPersonName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldTitle}>
                                <h4>Designation</h4>
                                {errorAt === 'designation' && <p>Please select a valid designation</p>}
                            </div>
                            <div className={styles.fieldInput}>
                                <select onChange={(e) => setDesignation(e.target.value)}>
                                    <option value={null}>Please Select</option>
                                    <option value="Owner">Owner Salon</option>
                                    <option value="Manager">Manager Salon</option>
                                    <option value="Staff">Staff</option>
                                    <option value="HR">HR</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={styles.yourDataIsSafe}>
                        <BsShieldFillPlus color="green" size={14} /><p>&nbsp;Your data is 100% safe</p>
                    </div>
                    <div>
                        <div className={styles.tncTxt}>By continuing you agree to terms of use & privacy policy</div>
                    </div>
                    <div className={styles.submitBtn}>
                        {isFormValidated ? <button className={styles.submitBtnActive} onClick={() => submitHandler()} >Submit</button> :
                            <button className={styles.submitBtnInactive} onClick={() => errReflector()} >Submit</button>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HireSalonStaffQuickly