import React, { useEffect, useRef, useState } from 'react'
import Footer from '../Components/Footer'
import NavbarHandler from '../Helper/NavbarHandler'
import styles from './Workshops.module.css'

import GlitchText from 'react-glitch-effect/core/GlitchText';
import { FaArrowDown, FaChevronDown } from 'react-icons/fa';
import { BsArrowDown } from 'react-icons/bs';

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
} from "react-shimmer-effects";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import EachPopularWorkshopCards from '../Components/EachPopularWorkshopCards';
import { BASE_URL_API } from '../References/urls';
import BookYourSeatNow from '../Components/BookYourSeatNow';
import EachPopularWorkshopCardsMobile from '../Components/EachPopularWorkshopCardsMobile';


import ReactGA from 'react-ga';
import PastWorkshopsSection from '../Components/PastWorkshopsSection';
import { Link } from 'react-router-dom';


const Workshops = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [allWorkshops, setAllWorkshops] = useState([]);
    const bookYourSeatRef = useRef();
    const twRef = useRef();

    const getAllWorkshops = async () => {
        setIsLoading(true);
        const getAllWorkshopsResponse = await fetch(BASE_URL_API + "/getAllWorkshops",
            {
                method: 'POST',
                body: JSON.stringify({

                })
            });

        if (!getAllWorkshopsResponse.ok) {
            console.log("Something went wrong, Server Error!");
        } else {
            const getAllWorkshopsRespo = await getAllWorkshopsResponse.json();
            if (getAllWorkshopsRespo.status == "success") {
                setAllWorkshops(getAllWorkshopsRespo.response);
            } else {
                setAllWorkshops(null)
                console.log(getAllWorkshopsRespo);
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getAllWorkshops();

        ReactGA.pageview(window.location.pathname);
    }, [])

    const scrollToBookYourSeat = () => bookYourSeatRef.current.scrollIntoView();
    const scrollToTw = () => twRef.current.scrollIntoView();

    // console.log("it's length of upcoming ",allWorkshops.filter((item) => new Date(item.startingTime).toISOString() > new Date().toISOString()).length)


    return (
        <div className={styles.mainWrapper}>
            <NavbarHandler type="home" />
            <div className={styles.mainBody}>
                <section className={styles.firstImpression}>
                    <div className={styles.fibg}>
                        <video className={styles.videoPlayer}
                            // ref={videoPlayerRef}
                            role="presentation" crossOrigin="anonymous" playsInline=""
                            preload="auto" muted={true}
                            tabIndex="-1" width="100%" height="100%" autoPlay={true}
                            loop="infinite"
                            src="https://stylelink.in/pic-assets/workshop-video.mp4" >
                        </video>
                    </div>
                    <div className={styles.heroTxtWrapper}>
                        <div className={styles.mainHeroTxtWrapper}>
                            <div className={styles.mainHeroTxt}>
                                <h2>
                                    Experience the essence
                                    <br></br>
                                    of Makeup
                                </h2>
                                <h4>
                                    <GlitchText
                                        component='span'
                                        duration={5}
                                    >
                                        with Live Workshops
                                    </GlitchText>
                                </h4>
                            </div>
                        </div>

                        <div className={styles.registerButtonWrapper}>
                            <div className={styles.registerButtonContainer}
                                onClick={() => scrollToBookYourSeat()}
                            >
                                <button className={styles.registerBtn}>Register Now</button>
                                <button className={styles.hiddenLine}></button>
                            </div>
                        </div>

                        <div className={styles.imageAutoSliderWrapper}>
                            slider will go here...
                        </div>

                        <div className={styles.scrollBtnWrapper}>
                            <div className={styles.scrollBtnContainer} onClick={() => scrollToTw()}>
                                <button className={styles.scrollArrowBtn}>
                                    <BsArrowDown fontSize={32} color="black" />
                                </button>
                                <div className={styles.scrollDteTxt}>
                                    Scroll Down to Explore
                                </div>
                            </div>
                        </div>


                        <div className={styles.mostPopularWrapper}>
                            <div className={styles.mostPopularHeader}>
                                <h2>The most popular / upcoming workshops</h2>
                            </div>

                            Workshops
                        </div>
                    </div>
                </section>

                {isLoading === true ?
                    <section className={styles.mpwWrapper} ref={twRef} >
                        <div className={styles.trendingWorkshopLoaderContainer}>
                            <div className={styles.twlcLeft}>
                                <ShimmerCircularImage size={40} center={0} className="m-15" />
                                <ShimmerThumbnail height={200} width="100" className="m-15" rounded />
                                <ShimmerTitle line={1} className="m-15" />
                                <ShimmerText line={3} className="m-15" />
                            </div>
                            <div className={styles.twlcCenter}>
                                <ShimmerCircularImage size={40} center={0} className="m-15" />
                                <ShimmerThumbnail height={200} width="300" className="m-15" rounded />
                                <ShimmerTitle line={1} className="m-15" />
                                <ShimmerText line={3} className="m-15" />
                            </div>
                            <div className={styles.twlcRight}>
                                <ShimmerCircularImage size={40} center={0} className="m-15" />
                                <ShimmerThumbnail height={200} width="100" className="m-15" rounded />
                                <ShimmerTitle line={1} className="m-15" />
                                <ShimmerText line={3} className="m-15" />

                            </div>
                        </div>
                    </section>
                    :
                    allWorkshops !== null ?
                        allWorkshops.filter((item) => new Date(item.startingTime).toISOString() > new Date().toISOString()).length > 0 ?
                            allWorkshops.filter((item) => new Date(item.startingTime).toISOString() > new Date().toISOString()).map((itemThis, index) => (
                                <section className={styles.featuredWorkshopWrapper}  >
                                    <div className={styles.featuredWorkshopContainer}>
                                        <div className={styles.eachCoverImage}>
                                            <img src={itemThis?.workshopBannerLand} />
                                            {/* {JSON.stringify(itemThis)} */}
                                        </div>
                                        <div className={styles.coverTxt}>
                                            <div className={styles.coverTxtContainer}>
                                                <div>
                                                    <span className={styles.startDate}>
                                                        {itemThis?.startDate}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className={styles.categoryTag}>
                                                        {itemThis?.workshopCategory}
                                                    </span>
                                                    <div className={styles.workshopTitle}>
                                                        <h3>{itemThis?.workshopTitle}</h3>
                                                    </div>
                                                    <div className={styles.workshopDescription}>
                                                        {itemThis?.workshopDescription.substring(0, 240)} ...
                                                    </div>

                                                    <div className={styles.viewDetailsContainer}>
                                                        <Link to={`/workshop/${itemThis?.workshopRoute}`} >
                                                            Book Your Seat
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ))
                            :
                            <div></div>
                        :
                        <div></div>

                }



                {/* 
                {!isLoading &&
                    <div ref={bookYourSeatRef}>
                        {allWorkshops.filter((item) => new Date(item.startingTime).toISOString() > new Date().toISOString()).length > 0 &&
                            <BookYourSeatNow data={allWorkshops} />
                        }
                    </div>
                } */}

                {/* <div>
                    <div className={styles.upComingTitle}>
                        Upcoming Workshops
                    </div>

                    <div>
                        {allWorkshops.filter((item) => new Date(item.startingTime).toISOString() > new Date().toISOString()).length > 0 ?
                            allWorkshops.filter((item) => new Date(item.startingTime).toISOString() > new Date().toISOString()).map((itemThis, index) => (
                                <div className={styles.eachUpcomingWorkshopWrapper}>
                                    <div>
                                        <div>

                                        </div>
                                    </div>
                                    <div>
                                        <div>

                                            {itemThis?.workshopTitle}
                                        </div>
                                        <div>
                                        </div>
                                    </div>

                                </div>
                            ))
                            :
                            <div>
                                This is
                            </div>
                        }

                    </div>
                </div> */}

                {!isLoading && <PastWorkshopsSection data={allWorkshops} />}


            </div>
            <Footer />
        </div>
    )
}

export default Workshops