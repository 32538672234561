import React, { useEffect, useState } from 'react'
import Footer from '../../Components/Footer'
import EachWorkshopGallery from '../../Components/Workshops/EachWorkshopGallery'
import EachWorkshopPageComponent from '../../Components/Workshops/EachWorkshopPageComponent'
import EachWorkshopTheory from '../../Components/Workshops/EachWorkshopTheory'
import NavbarHandler from '../../Helper/NavbarHandler'
import styles from '../Workshops/PremiumAssestsWorkshops.module.css'

import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/urls'
import GenerateWorkshopCertificate from '../../Components/Workshops/GenerateWorkshopCertificate'
import GCWCertificateFAQs from '../../Components/Workshops/GCWCertificateFAQs'
import GCWWorkshopFAQs from '../../Components/Workshops/GCWWorkshopFAQs'
import EachWorkshopFAQsWrapper from '../../Components/Workshops/EachWorkshopFAQsWrapper'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const PremiumAssestsWorkshops = () => {
    let params = useParams();
    let identifier = params.identifier;
    const [wd, setWd] = useState([]); // workshop data
    const [dfs, setDfs] = useState(true); // data fetching status

    const getIndividualWorkshopData = async () => {
        setDfs(true)
        const getIndividualWorkshopDataResponse = await fetch(BASE_URL_API + "/getIndividualWorkshopData",
            {
                method: 'POST',
                body: JSON.stringify({
                    route: identifier
                })
            });

        if (!getIndividualWorkshopDataResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getIndividualWorkshopDataRespo = await getIndividualWorkshopDataResponse.json();
            if (getIndividualWorkshopDataRespo.status === 'success') {
                setWd(getIndividualWorkshopDataRespo.response);
            } else {
                if (getIndividualWorkshopDataRespo.status === '404') {

                } else {

                }
                setWd([])
            }
            // console.log(getIndividualWorkshopDataRespo)
        }
        setDfs(false)
    }

    useEffect(() => {
        if (identifier !== '') {
            getIndividualWorkshopData()
        }
    }, [identifier])


    // console.log("Printing workshop data!", wd.length)

    return (
        <div className={styles.mainWrapper}>
            <NavbarHandler type="home" />
            <Helmet>
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            {dfs
                ?
                <div className={styles.mainBodyLoader}>
                    <div className={styles.firstPaintWrapper}>
                        <div className={styles.fplayerOne}>
                            <div className={styles.fplayerOneContent}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ff0066" fill-opacity="1" d="M0,32L48,53.3C96,75,192,117,288,138.7C384,160,480,160,576,160C672,160,768,160,864,165.3C960,171,1056,181,1152,202.7C1248,224,1344,256,1392,272L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                            </div>
                        </div>
                    </div>
                    <div className={styles.secondPaintWrapper}>
                    </div>
                    <EachWorkshopPageComponent />
                </div>
                :
                wd.length > 0 ?
                    wd.map((item, index) => (
                        <div className={styles.mainBody} key={index}>
                            <div className={styles.firstPaintWrapper}>
                                <div className={styles.fplayerOne}>
                                    <div className={styles.fplayerOneContent}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ff0066" fill-opacity="1" d="M0,32L48,53.3C96,75,192,117,288,138.7C384,160,480,160,576,160C672,160,768,160,864,165.3C960,171,1056,181,1152,202.7C1248,224,1344,256,1392,272L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                                    </div>
                                </div>
                                <div className={styles.fplayerTwo}>
                                    <div className={styles.fplayerTwoContent}>
                                        <div className={styles.fpltcLeft}>
                                            <div className={styles.workshopTitle}>
                                                <h1>
                                                    {item.workshopTitle}
                                                </h1>
                                                <h4>
                                                    {item.workshopSubTitle}
                                                </h4>
                                            </div>
                                            <div>

                                            </div>
                                            <div className={styles.buttonWrapper}>
                                                <Link to={`/workshop/${identifier}/register`} className={styles.secondaryBtn}>Register</Link>
                                                <button className={styles.primaryBtn}>Watch Now</button>
                                            </div>
                                        </div>
                                        <div className={styles.fpltcRight}>
                                            <div className={styles.videoWrapper}>
                                                <video autoPlay loop>
                                                    <source
                                                        src="https://stylelink.in/pic-assets/videos/sample-portrait-video.mp4"
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                            <div className={styles.frameWrapper}>
                                                <img src="https://stylelink.in/pic-assets/workshop/mockups/iphone14.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.secondPaintWrapper}>

                            </div>
                            <EachWorkshopPageComponent />
                            <EachWorkshopGallery

                            />
                            <EachWorkshopTheory
                                item={item}
                            />
                            <GenerateWorkshopCertificate
                                item={item}
                            />
                            <EachWorkshopFAQsWrapper />
                        </div>
                    ))
                    :
                    <div className={styles.mainBody}>
                        <div className={styles.firstPaintWrapper}>
                            <div className={styles.fplayerOne}>
                                <div className={styles.fplayerOneContent}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ff0066" fillOpacity="1" d="M0,32L48,53.3C96,75,192,117,288,138.7C384,160,480,160,576,160C672,160,768,160,864,165.3C960,171,1056,181,1152,202.7C1248,224,1344,256,1392,272L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                                </div>
                            </div>
                            <div className={styles.fplayerTwo}>
                                <div className={styles.fplayerTwoContent}>
                                    <div className={styles.fpltcLeft}>
                                        <div className={styles.workshopTitle}>
                                            <h1>
                                                Lost in Space!
                                            </h1>
                                            <h4>
                                                Invalid Workshop Link!
                                            </h4>
                                        </div>
                                        <div>

                                        </div>
                                        <div className={styles.buttonWrapper}>
                                            <button className={styles.secondaryBtn}>Help</button>
                                            <button className={styles.primaryBtn}>Go To Home</button>
                                        </div>
                                    </div>
                                    <div className={styles.fpltcRight}>
                                        <div className={styles.dumbLostInSpace}>
                                            <img src="https://stylelink.in/pic-assets/workshop/mockups/lost-in-space.gif" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <EachWorkshopPageComponent />
                    </div>
            }

            <Footer />

        </div>
    )
}

export default PremiumAssestsWorkshops