import React, { useState, useEffect } from 'react';

const Timer = ({ startingTime }) => {
    const calculateTimeLeft = () => {
        if (!startingTime || !Date.parse(startingTime.replace(' ', 'T'))) {
            return { error: true };
        }

        const startTime = new Date(startingTime.replace(' ', 'T'));
        const currentTime = new Date();
        const timeDifference = startTime - currentTime;

        if (timeDifference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const secondsLeft = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return { days: daysLeft, hours: hoursLeft, minutes: minutesLeft, seconds: secondsLeft };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [startingTime]);

    if (timeLeft.error) {
        return <div>Invalid starting time.</div>;
    }

    return (
        <div>
            {timeLeft.days} {timeLeft?.days>0 && `days, `}{timeLeft.hours} {timeLeft?.hours>0 && `hours, `}{timeLeft.minutes} {timeLeft?.minutes>0 && `minutes, and `}{timeLeft.seconds} seconds.
        </div>
    );
};

export default Timer;
