import React from 'react'
import { FaBookmark } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import CalendarAI from '../../Icons/Animated/CalendarAI'
import FnAAI from '../../Icons/Animated/FnAAI'
import LocationAI from '../../Icons/Animated/LocationAI'
import SalaryAI from '../../Icons/Animated/SalaryAI'
import { humanReadableTimePassed } from '../../References/Helpers/jarvis-jr'
import styles from './EachTopJob.module.css'

const EachTopJob = (props) => {
    const { item } = props
    return (
        <Link to={`/job/${item.jobRoute}`} className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.storeWrapper}>
                    <div>
                        <div>

                        </div>
                        <div className={styles.storeName}>
                            {item?.storeName}
                        </div>
                    </div>
                    <div className={styles.bookmarkIconWrapper}>
                        <div className={styles.bookmarkIconContainer}>
                            <FaBookmark size={20} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.jobProfile}>{item?.jobTitle}</div>
                    <div className={styles.jobFeatureWrapper}>
                        <div className={styles.eachJobFeature}>
                            <div className={styles.ejfIcon}>
                                <SalaryAI />
                            </div>
                            <div className={styles.ejfTxt}>{item?.maxSalary} / month</div>
                        </div>
                        <div className={styles.eachJobFeature}>
                            <div className={styles.ejfIcon}>
                                <FnAAI /></div>
                            <div className={styles.ejfTxt}>{item?.fna}</div>
                        </div>
                        <div className={styles.eachJobFeature}>
                            <div className={styles.ejfIcon}>
                                <LocationAI /></div>
                            <div className={styles.ejfTxt}>{item?.shortenAddress}</div>
                        </div>
                        <div className={styles.eachJobFeature}>
                            <div className={styles.ejfIconCal}>
                                <CalendarAI /></div>
                            <div className={styles.ejfTxt}>Immediate Joining</div>
                        </div>
                    </div>
                </div>
                <div className={styles.applyNowWrapper}>
                    <Link to={`/job/${item?.jobRoute}`}>
                        <div className={styles.applyNowBtn}>
                            Apply Now
                        </div>
                    </Link>
                </div>

            </div>
            <div className={styles.mainFooter}>
                Posted {humanReadableTimePassed(item?.postedOn)}
            </div>
        </Link>
    )
}

export default EachTopJob