import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaChevronCircleLeft, FaChevronCircleRight, FaChevronLeft, FaChevronRight, FaHeart, FaQuoteLeft, FaQuoteRight, FaRegStar, FaStar, FaStarAndCrescent } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Checkbox from '../Components/Checkbox';
import styles from '../Styles/Pages/Login.module.css';

import OtpInput from 'react-otp-input';

import ReactGA from 'react-ga';


const Login = () => {
    const [isTncChecked, setIsTncChecked] = useState(true);
    const [contact, setContact] = useState('');
    const [isContactValid, setIsContactValid] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [enteredOtp, setEnteredOtp] = useState({ otp: '' });
    const [resendOtpIn, setResendOtpIn] = useState(45)

    const contactNumberHandler = (p) => {
        if (p.length < 11) {
            validateContact(p)
            setContact(p)
        }
    }

    const validateContact = (v) => {
        var phoneno = /^\d{10}$/g;
        if (v.toString().match(phoneno)) {
            setIsContactValid(true)
        } else {
            setIsContactValid(false)
        }
    }


    const sendOtpHandler = () => {
        setResendOtpIn(45);
        setIsOtpSent(true);
    }

    const otpHandler = (otp) => {
        setEnteredOtp({ otp })
    }

    useEffect(() => {
        if (isOtpSent) {
            let crot = resendOtpIn;
            const resendOtpTimer = setInterval(() => {
                if (crot > 0) {
                    setResendOtpIn(crot--);
                }
            }, 1000);

            return () => clearInterval(resendOtpTimer);
        }
    }, [isOtpSent, resendOtpIn])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])


    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.lc}>
                    <div className={styles.loginContainer}>
                        <div className={styles.lch}>
                            <div className={styles.lchl}>
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657715908/stylelink-logo1---just-lokaci-707x91_gmm3f3.png" />
                            </div>
                            <div className={styles.lchr}>
                                Connect, Learn, Earn & Grow
                            </div>
                        </div>

                        <div className={styles.lcb}>
                            <div className={styles.lcTitle}>
                                <h1>
                                    Over 450+ jobs
                                    <br></br>
                                    are available for you!
                                </h1>
                                <h2>
                                    Available across India!
                                </h2>
                            </div>

                            {isOtpSent == false ?
                                <div>
                                    <div className={styles.instruct}>
                                        Enter your phone no. to login into your account!
                                    </div>

                                    <div className={styles.fieldsContainer}>
                                        <div className={styles.eachField}>
                                            <div className={styles.eachFieldTitle}>Phone No.</div>
                                            <div className={styles.eachFieldInput}>
                                                <input
                                                    value={contact}
                                                    type="number"
                                                    onChange={(e) => contactNumberHandler(e.target.value)}
                                                    placeholder="Please enter 10 digit phone number. 99XXX2X00X"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className={styles.instruct}>
                                        <span>
                                            An OTP has been sent to {contact}
                                        </span>
                                        <button onClick={() => setIsOtpSent(false)}>
                                            Change Phone
                                        </button>
                                    </div>

                                    <div className={styles.fieldsContainer}>
                                        <div className={styles.eachFieldInputOtp}>
                                            <OtpInput
                                                value={enteredOtp.otp}
                                                onChange={(otp) => otpHandler(otp)}
                                                numInputs={4}
                                                separator={<span>-</span>}
                                                containerStyle={{
                                                    padding: "8px 8px"
                                                }}
                                                inputStyle={{
                                                    border: "1px solid #dfdfdf",
                                                    width: "30px",
                                                    borderRadius: "4px",
                                                    margin: "0px 6px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {isOtpSent == true ?
                                <div className={styles.twoBtns}>
                                    {resendOtpIn > 1 ?
                                        <button className={styles.resendOtp}>
                                            Resend Otp In {resendOtpIn}s
                                        </button>
                                        :
                                        <button className={styles.resendOtp}
                                            onClick={() => sendOtpHandler()}
                                        >
                                            Resend Otp
                                        </button>

                                    }
                                    <button className={styles.submitOtp}>
                                        Submit
                                    </button>
                                </div>

                                :
                                <>

                                    <div className={styles.masterBtn}>
                                        {isContactValid ?
                                            <button className={styles.activeBtn} onClick={() => sendOtpHandler()}>
                                                Send OTP
                                            </button>
                                            :
                                            <button className={styles.disabledBtn}>
                                                Send OTP
                                            </button>
                                        }
                                    </div>
                                </>

                            }

                            <div className={styles.trouble}>
                                <div className={styles.troubleTitle}>
                                    Having trouble signing in?
                                </div>
                                <div>
                                    <a href="https://api.whatsapp.com/send/?phone=918929460376&text=I+am+having+trouble+logging+into+the+Stylelink+Website&type=phone_number&app_absent=1"
                                        target="_blank"
                                        className={styles.chatUsNow}
                                    >
                                        Chat us Now
                                    </a> -
                                    <a href="tel:+91-8929460356"
                                        target="_blank"
                                        className={styles.callNow}
                                    >&nbsp;
                                        call us
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={styles.tnc}>
                            <Checkbox isChecked={isTncChecked} onChange={() => setIsTncChecked(!isTncChecked)} name="I agreeing to the Stylelink platform terms of use." />
                        </div>
                        <div className={styles.lcf}>
                            <div className={styles.smallCaption}>
                                The Stylelink uses industry-standard encryption to protect the confidentiality of the information you submit. Learn more about our Security Policy.
                            </div>
                        </div>


                    </div>
                </div>
                <div className={styles.rc}>
                    <div className={styles.vpContainer}>
                        <video className={styles.videoPlayer}
                            // ref={videoPlayerRef}
                            role="presentation" crossOrigin="anonymous" playsInline=""
                            preload="auto" muted={true}
                            tabIndex="-1" width="100%" height="100%" autoPlay={true}
                            loop
                            // onEnded={() => changeVideo()}
                            src="https://res.cloudinary.com/drotwjbzs/video/upload/v1655538472/MVI_3991_2_nc98mw.mp4" >
                        </video>
                    </div>
                    <Link to="/" className={styles.rh}>
                        <FaChevronCircleLeft />
                        <span>Back to home</span>
                    </Link>
                    <div className={styles.rb}>
                        <div className={styles.eachContentWrapper}>
                            <div className={styles.eachContentContainer}>
                                <div className={styles.eachContentFeedback}>
                                    <h2>
                                        "I'm very thankful
                                        that stylelink helped
                                        me find the right job for me"
                                    </h2>
                                </div>

                                <div className={styles.eachContentFooter}>
                                    <div className={styles.eachContentFooterOne}>
                                        <div className={styles.personName}>
                                            Neeraj Rajput
                                        </div>
                                        <div>
                                            <FaStar color="white" />
                                            <FaStar color="white" />
                                            <FaStar color="white" />
                                            <FaStar color="white" />
                                            <FaStar color="white" />
                                        </div>
                                    </div>
                                    <div className={styles.eachContentFooterTwo}>
                                        <div className={styles.profileName}>
                                            <div className={styles.profile}>
                                                Beautician
                                            </div>
                                            <div className={styles.profileDetails}>
                                                8+ years Experience
                                            </div>
                                        </div>
                                        <div className={styles.actionBtn}>
                                            <button>
                                                <FaChevronLeft />
                                            </button>
                                            <button>
                                                <FaChevronRight />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.mainContainerMobile}>
                <section className={styles.first}>
                    <div className={styles.navigationHeader}>
                        <Link to="/" className={styles.backBtnContainer}>
                            <FaArrowLeft /> &nbsp; Back To Home
                        </Link>
                    </div>
                    <div className={styles.topContainer}>
                        <div className={styles.tcWrapper}>
                            <video
                                // className={styles.videoPlayer}
                                // ref={videoPlayerRef}
                                role="presentation" crossOrigin="anonymous" playsInline=""
                                preload="auto" muted={true}
                                tabIndex="-1" width="100%" height="100%" autoPlay={true}
                                // onEnded={() => changeVideo()}
                                src="https://res.cloudinary.com/drotwjbzs/video/upload/v1655538472/MVI_3991_2_nc98mw.mp4" >
                            </video>
                            <div className={styles.heroTxt}>
                                <div className={styles.heroTxtContainer}>
                                    <div className={styles.reviewContainer}>
                                        <FaQuoteLeft size="10px" />&nbsp;
                                        Stylelink helped me finding the right job for me!
                                        &nbsp;<FaQuoteRight size="10px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomContainer}>
                        <div className={styles.bcheader}>
                            <h2>
                                Find your <br></br>
                                dream job here.
                            </h2>

                            <h5>
                                Over 450+ jobs are available for you!
                            </h5>
                        </div>

                        <div className={styles.bcbody}>
                            {isOtpSent == false ?
                                <div className={styles.bcActions}>
                                    <div className={styles.bcInput}>
                                        <input
                                            value={contact}
                                            type="number"
                                            onChange={(e) => contactNumberHandler(e.target.value)}
                                            placeholder="Please enter phone number 99XXX2X00X"
                                        />
                                    </div>
                                    <div className={styles.sendOtp}>
                                        {isContactValid ?
                                            <button className={styles.activeBtn} onClick={() => sendOtpHandler()}>
                                                Send OTP
                                            </button>
                                            :
                                            <button className={styles.disabledBtn}>
                                                Send OTP
                                            </button>
                                        }
                                    </div>
                                </div>
                                :
                                <div className={styles.bcActions}>
                                    <div className={styles.smsSentMessage}>
                                        <div>
                                            An OTP has been sent to {contact}
                                        </div>
                                        <button onClick={() => setIsOtpSent(false)}>
                                            Change Phone
                                        </button>
                                    </div>
                                    <div className={styles.otpContainer}>
                                        <OtpInput
                                            value={enteredOtp.otp}
                                            onChange={(otp) => otpHandler(otp)}
                                            numInputs={4}
                                            separator={<span>-</span>}
                                            containerStyle={{
                                                padding: "8px 8px"
                                            }}
                                            inputStyle={{
                                                border: "1px solid #dfdfdf",
                                                width: "30px",
                                                borderRadius: "4px",
                                                margin: "0px 6px",
                                            }}
                                        />
                                    </div>
                                    <div className={styles.actionBtns}>
                                        <button className={styles.resendOtpBtn}>Resend Otp {resendOtpIn} </button>
                                        <button className={styles.submitOtpBtn}>Submit</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.bcfooter}>
                            Made with&nbsp; <FaHeart color="red" /> &nbsp;in India
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Login