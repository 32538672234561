import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer'
import ReadMoreBtn from '../../Components/ReadMoreBtn'
import NavbarHandler from '../../Helper/NavbarHandler'
import styles from './Home.module.css'

import ReactGA from 'react-ga';
import StickyBottomNavAtHome from '../../Components/UI/StickyBottomNavAtHome'

const Home = () => {
    // const v1 = "https://res.cloudinary.com/drotwjbzs/video/upload/v1661792265/MVI_7254_2_wq5hrj.mp4";
    // const v1 = "https://res.cloudinary.com/drotwjbzs/video/upload/v1661834288/MVI_7254_3_1_he6jbp.mp4";
    // const v2 = "https://res.cloudinary.com/drotwjbzs/video/upload/v1655538472/MVI_3991_2_nc98mw.mp4";
    // const v3 = "https://res.cloudinary.com/drotwjbzs/video/upload/v1656493567/O_-03_vofqrx.mp4";

    // const v1 = "https://cors-anywhere.herokuapp.com/https://stylelink.in/pic-assets/videos/learn.mp4";
    // const v2 = "https://cors-anywhere.herokuapp.com/https://stylelink.in/pic-assets/videos/connect.mp4";
    // const v3 = "https://cors-anywhere.herokuapp.com/https://stylelink.in/pic-assets/videos/grow.mp4";

    const v1 = "https://stylelink.in/pic-assets/videos/learn.mp4";
    const v2 = "https://stylelink.in/pic-assets/videos/connect.mp4";
    const v3 = "https://stylelink.in/pic-assets/videos/grow.mp4";


    const changeVideo = () => {
        if (videoPlayerRef.current.src == v1) {
            videoPlayerRef.current.src = v2;
        } else if (videoPlayerRef.current.src == v2) {
            videoPlayerRef.current.src = v3;
        } else if (videoPlayerRef.current.src == v3) {
            videoPlayerRef.current.src = v1;
        }
        changeHighlight()
    }

    const videoPlayerRef = useRef(null);
    const connectBtnRef = useRef(null);
    const learnBtnRef = useRef(null);
    const earnBtnRef = useRef(null);
    const growBtnRef = useRef(null);

    const connectRef = useRef(null);
    const scrollToConnect = () => connectRef.current.scrollIntoView();

    const learnRef = useRef(null);
    const scrollToLearn = () => learnRef.current.scrollIntoView();

    const earnRef = useRef(null);
    const scrollToEarn = () => earnRef.current.scrollIntoView();

    const growRef = useRef(null);
    const scrollToGrow = () => growRef.current.scrollIntoView();


    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        document.body.style.overflowY = "visible"
    }, [])

    const changeHighlight = () => {
        if (connectBtnRef.current.style.borderBottom != '') {
            connectBtnRef.current.style.borderBottom = '';
            learnBtnRef.current.style.borderBottom = '2px solid #da1884';
        } else {
            if (learnBtnRef.current.style.borderBottom != '') {
                learnBtnRef.current.style.borderBottom = '';
                earnBtnRef.current.style.borderBottom = '2px solid #da1884';
            } else {
                if (earnBtnRef.current.style.borderBottom != '') {
                    earnBtnRef.current.style.borderBottom = '';
                    growBtnRef.current.style.borderBottom = '2px solid #da1884';
                } else {
                    if (growBtnRef.current.style.borderBottom != '') {
                        growBtnRef.current.style.borderBottom = '';
                        connectBtnRef.current.style.borderBottom = '2px solid #da1884';
                    } else {
                        connectBtnRef.current.style.borderBottom = '2px solid #da1884';
                    }
                }
            }
        }
    }

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ffffff");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#fffff";
            document.head.appendChild(meta);
        }
    }, []);


    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Stylelink - India's best app for salon/makeup courses &amp; jobs</title>
                <meta name="description" content="India's best app for salon/makeup courses &amp; jobs"></meta>
            </Helmet>
            <NavbarHandler type="home" />
            {/* first paint at landing  page  */}
            <section className={styles.heroContainer} id="fpal"> 
                <div className={styles.mainTxt}>
                    <div>
                        <div className={styles.mainTxtContainer}>
                            <h2>
                                <div>
                                    The future of career in
                                </div>
                                <div>
                                    Beauty Industry Is Here
                                </div>
                            </h2>
                        </div>
                        <div className={styles.subTxtContainer}>
                            <h4>Start your career with the best possible solutions at Stylelink</h4>
                        </div>
                    </div>
                    <div className={styles.uspContainer}>
                        <button ref={connectBtnRef} onClick={() => scrollToConnect()}>Connect</button>
                        <button ref={learnBtnRef} onClick={() => scrollToLearn()}>Learn </button>
                        <button ref={earnBtnRef} onClick={() => scrollToEarn()}>Earn</button>
                        <button ref={growBtnRef} onClick={() => scrollToGrow()}>Grow</button>
                    </div>
                </div>
                <div className={styles.mainTxtMobile}>
                    <div className={styles.mainTxtMobileContainer}>
                        <h2>
                            <div>
                                THE FUTURE OF CAREERS
                            </div>
                            <div>
                                IN BEAUTY AND STYLING
                            </div>
                            <div>
                                INDUSTRY IS HERE!
                            </div>
                        </h2>
                        <h4>Start your career with the best possible solutions at Stylelink</h4>
                    </div>
                    <div className={styles.uspMobileWrapper}>
                        <div className={styles.uspMobileContainer}>
                            <span className={styles.eachUspContainer}>Connect</span>
                            <span className={styles.eachUspContainer}>Learn</span>
                            <span className={styles.eachUspContainer}>Earn</span>
                            <span className={styles.eachUspContainer}>Grow</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.visionSection} >
                <div className={styles.leftBorder}></div>
                <div className={styles.visionContent}>
                    <div className={styles.visionContentContainer}>
                        <div className={styles.sectionalTitle}>
                            Our Vision
                        </div>
                        <div className={styles.sectionalSubTitle}>
                            To change the way people think about careers in the Beauty &amp; Styling Industry.
                        </div>
                        <div className={styles.sectionalDescription}>
                            Our goal is to assist aspiring beauticians and hair stylists in establishing a successful career in
                            the
                            industry through our services.
                        </div>
                    </div>
                    <div className={styles.visionImageContainer}>
                       
                    </div>
                </div>
                <div className={styles.visionImageContainerCollapsible}>
                    
                </div>
            </section>
            <section className={styles.servicesSection} id="servicesSectionHome">
                <div className={styles.leftBorderAlt}></div>
                <div className={styles.servicesSectionHeader}>
                    <div className={styles.servicesHeaderContent}>
                        <div className={styles.servicesContentWrap}>
                            <div className={styles.sectionalTitle}>
                                Yes, We are making it possible!
                            </div>
                            <div className={styles.sectionalSubTitle}>
                                With our principles of connect, learn, earn, and grow
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.servicesContent}>
                    <div className={styles.servicesContentWrap}>
                        <div className={styles.ServicesSectionalDescription}>
                            <div className={styles.eachSectionalDescription}>
                                <div className={styles.eachCol}>
                                    <div className={styles.eachSectionIconConnect}>
                                        {/* <img src="https://res.cloudinary.com/iamironman/image/upload/v1649074599/web-icons/connected-people_nasfsy.png" alt="" /> */}
                                        {/* <img src="https://res.cloudinary.com/iamironman/image/upload/v1661877899/social-network-3--unscreen_rqywpo.gif" alt="" /> */}
                                        <img src="https://res.cloudinary.com/iamironman/image/upload/v1661877899/social-network-3--unscreen_rqywpo.gif" alt="" />
                                    </div>
                                </div>
                                <div className={styles.eachCol} ref={connectRef}>
                                    <div>
                                        <div className={styles.eachSectionalDescriTitle}>
                                            Connect
                                        </div>
                                        <div className={styles.eachSectionalDescriDetails}>
                                            {/* Join the community of 1M+ people and feel at home. People all around the globe are
                                            discussing
                                            ideas on this platform and who knows, that one idea might shape your career. */}
                                            Connect with Beauty Professionals, learn from them and share your knowledge. From the best beauty hacks to awesome career advice you’ll get it all.
                                        </div>
                                        <Link to='/community' className={styles.readMoreContainer}>
                                            <div className={styles.readMoreBtn}>
                                                <div className={styles.readMoreTxt}>
                                                    Read more
                                                </div>
                                                <div className={styles.rmIcon}>
                                                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1649160157/web-icons/img_418607_lxiv4r.png" alt="" />
                                                    {/* <img src="https://res.cloudinary.com/iamironman/image/upload/v1661877899/social-network-3--unscreen_rqywpo.gif" alt="" /> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.eachSectionalDescription}>
                                <div className={styles.eachCol} ref={learnRef}>
                                    <div>
                                        <div className={styles.eachSectionalDescriTitle}>
                                            Learn
                                        </div>
                                        <div className={styles.eachSectionalDescriDetails}>
                                            Take Off your journey with us! Starting from basic to transforming you into a veteran. We offer a blend of hand-curated
                                            courses of the Industry.
                                        </div>
                                        <Link to='/courses' className={styles.readMoreContainer}>
                                            <div className={styles.readMoreBtn}>
                                                <div className={styles.readMoreTxt}>
                                                    Read more
                                                </div>
                                                <div className={styles.rmIcon}>
                                                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1649160157/web-icons/img_418607_lxiv4r.png" alt="" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={styles.eachCol}>
                                    <div className={styles.eachSectionIcon}>
                                        <img src="https://res.cloudinary.com/iamironman/image/upload/v1661878908/9d54927279f6dbd2e0ecd04e00c79e-unscreen_eongqb.gif" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachSectionalDescription}>
                                <div className={styles.eachCol}>
                                    <div className={styles.eachSectionIconEarn}>
                                        {/* <img src="https://res.cloudinary.com/iamironman/image/upload/v1649123928/web-icons/career-icon-png-8_rc4gty.png" alt="" /> */}
                                        <img src="https://res.cloudinary.com/iamironman/image/upload/v1661880196/grow_mpkunw.gif" alt="" />
                                    </div>
                                </div>
                                <div className={styles.eachCol} ref={earnRef}>
                                    <div>
                                        <div className={styles.eachSectionalDescriTitle}>
                                            Earn
                                        </div>
                                        <div className={styles.eachSectionalDescriDetails}>
                                            Find the best jobs in the Beauty Industry as per your skills and requirements, From building your profile to getting your dream job, we got your back.
                                        </div>
                                        <Link to='/jobs' className={styles.readMoreContainer}>
                                            <div className={styles.readMoreBtn}>
                                                <div className={styles.readMoreTxt}>
                                                    Read more
                                                </div>
                                                <div className={styles.rmIcon}>
                                                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1649160157/web-icons/img_418607_lxiv4r.png" alt="" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.servicesContentCollipsible}>
                    <div className={styles.servicesContentWrap}>
                        <div className={styles.ServicesSectionalDescription}>
                            <div className={styles.eachSectionalDescription}>
                                <div className={styles.eachCol}>
                                    <div>
                                        <div className={styles.eachSectionalDescriTitle}>
                                            Connect
                                        </div>
                                        <div className={styles.eachSectionIconConnect}>
                                            {/* <img src="https://res.cloudinary.com/iamironman/image/upload/v1649074599/web-icons/connected-people_nasfsy.png" alt="" /> */}
                                            <img src="https://res.cloudinary.com/iamironman/image/upload/v1661877899/social-network-3--unscreen_rqywpo.gif" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.eachCol}>
                                    <div>
                                        <div className={styles.eachSectionalDescriDetails}>
                                            Join the community of industyr people and feel at home. People all around the globe are
                                            discussing
                                            ideas on this platform and who knows, that one idea might shape your career.
                                        </div>
                                        <Link to="/community">
                                            <ReadMoreBtn />
                                        </Link>

                                    </div>
                                </div>
                            </div>
                            <div className={styles.eachSectionalDescription}>
                                <div className={styles.eachCol}>
                                    <div>
                                        <div className={styles.eachSectionalDescriTitle}>
                                            Learn
                                        </div>
                                        <div className={styles.eachSectionIcon}>
                                            {/* <img src="https://res.cloudinary.com/iamironman/image/upload/v1649075872/web-icons/learn-icon-bg_lxdyyk.png" alt="" /> */}
                                            <img src="https://res.cloudinary.com/iamironman/image/upload/v1661878908/9d54927279f6dbd2e0ecd04e00c79e-unscreen_eongqb.gif" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.eachCol}>
                                    <div>
                                        <div className={styles.eachSectionalDescriDetails}>
                                            Take Off your journey with us! We offer a blend of hand-curated
                                            courses which increases your value as a human resource.
                                        </div>
                                        <Link to="/courses">
                                            <ReadMoreBtn />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachSectionalDescription}>
                                <div className={styles.eachCol}>
                                    <div>
                                        <div className={styles.eachSectionalDescriTitle}>
                                            Earn
                                        </div>
                                        <div className={styles.eachSectionIcon}>
                                            {/* <img src="https://res.cloudinary.com/iamironman/image/upload/v1649123928/web-icons/career-icon-png-8_rc4gty.png" alt="" /> */}
                                            <img src="https://res.cloudinary.com/iamironman/image/upload/v1661880196/grow_mpkunw.gif" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.eachCol}>
                                    <div>
                                        <div className={styles.eachSectionalDescriDetails}>
                                            It gives you a platform to connect with a variety of salons and find work with a
                                            single
                                            click.
                                            Users can choose from a variety of job prospects and land the job they want.
                                        </div>
                                        <Link to="/jobs">
                                            <ReadMoreBtn />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.appPromoSection} id="ourIdeaHome">
                <div className={styles.floatingContainer}>
                    <div className={styles.floatingContent}>
                        <div className={styles.leftBorderInFloating}></div>
                        <div className={styles.floatingContentWrapper}>
                            <div className={styles.floatingTitle}>
                                OUR IDEAS
                            </div>
                            <div className={styles.floatingDescription}>
                                <p>
                                    We at Stylelink are revolutionising the way people think about careers in the make-up and hair-styling industry.
                                </p>
                                <p>
                                    Our goal is to assist aspiring beauticians and hair stylists in establishing a successful career in the industry by connecting them with various salons.
                                </p>
                            </div>

                            <ReadMoreBtn color="black" webW="75%" mobW="100%" />
                        </div>

                    </div>
                </div>
            </section>

            {/* <section className={styles.ourIdea}>
                OUR ASSISTANCE
                <div>
                    Our services are divided into three categories.
                </div>
                1 LEARN People who are interested in learning cosmetics or seeking to become a
                hair stylist can enrol in our classes and receive certification through Style Link.
                BUT WHY OUR COURSES? Reasonable pricing are available. Courses are self-paced.
                The chance to learn directly from the professionals Obtain accreditation.
                <div>
                    2 GROW -Grow as an artist, develop your skills.Style Link is an ecosystem that allows you to learn
                    from your peers by having access to their beauty industry styles and trends.
                    It's a gathering place for diverse stylists from all around the country.
                    -Grow as a Salon Style link connects salons with certified makeup artists and
                    hair stylists so that they can view their work on their profile and make offers based on it.
                </div>
                3 EARN It gives you a platform to connect with a variety of salons and find work with a single click.
                Users can choose from a variety of job prospects and land the job they want.

            </section> */}


            <StickyBottomNavAtHome />

            <Footer />
        </div>
    )
}

export default Home