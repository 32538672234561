import React from 'react'
import { GrCertificate, GrGallery, GrNotes } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import styles from '../Components/EachPastWorkshopCard.module.css'


const EachPastWorkshopCard = props => {
    let data = props.data;

    const getTimeMessage = (k) => {
        let curTime = new Date();
        curTime = curTime.toISOString();
        let recTime = new Date(k).toISOString();
        let diff = new Date(curTime) - new Date(recTime);

        let days = Math.floor(diff/(24*60*60*1000));
        let hours = Math.floor( (diff - days * (24*60*60*1000)) / (60*60*1000));

        let returningItem = '';
        if(days<1) {
            if(hours<5) {
                returningItem = hours+" hours ago";
            } else if(hours<12) {
                returningItem = " today";
            } else {
                returningItem = " yesterday";
            }
        } else if(days<30) {
            if(days<7) {
                returningItem = days+" days ago";
            } else if(days>6) {
                returningItem = " around a week ago";
            } else if(days<15) {
                returningItem = " 2 weeks ago";
            } else if(days>15) {
                returningItem = " 3 weeks ago";
            } else {
                returningItem = " around a month ago";
            }
        } else if(days>29) {
            if(days<45) {
                returningItem = " around a month ago";
            } else if(days<60) {
                returningItem = " one & half month ago";
            } else if(days<75) {
                returningItem = " 2 months ago";
            } else if(days<90) {
                returningItem = " around 3 months ago";
            } else if(days<115) {
                returningItem = " 3 months ago";
            } else if(days<135) {
                returningItem = " 4 months ago";
            } else if(days<165) {
                returningItem = " 5 months ago";
            } else if(days<195) {
                returningItem = " 6 months ago";
            } else if(days<225) {
                returningItem = " 7 months ago";
            } else if(days<255) {
                returningItem = " 8 months ago";
            } else if(days<285) {
                returningItem = " 9 months ago";
            } else if(days<315) {
                returningItem = " 10 months ago";
            } else if(days<345) {
                returningItem = " 11 months ago";
            } else if(days<361) {
                returningItem = " last year";
            }
        } else if(days>360) {
            returningItem = " last year";
        } else if(days>720) {
            returningItem = " 1 and half year ago";
        } else {
            returningItem = " 2 years ago";
        }

        return returningItem;
    }

    getTimeMessage(data.startingTime);
    return (
        <Link to={`/workshop/`+data.workshopRoute} className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.categoryIcon}>
                    <img
                        src="https://stylelink.in/pic-assets/workshop-category-icon.png"
                    />
                </div>
                <div className={styles.workshopTitle}>
                    {data.workshopTitle.slice(0, 36)}...
                </div>
            </div>
            <div className={styles.bannerDescription}>
                <div className={styles.bigBanner}>
                    <img src={data.workshopBannerLand} />

                </div>
                <div className={styles.descriptionWrapper}>
                    <div className={styles.durationWrapper}>
                        <span className={styles.duration}>
                            {data.workshopDuration} minutes
                        </span>
                        <div className={styles.dateWrapper}>
                            Streamed { getTimeMessage(data.startingTime)}
                            {/* {data.modeOfConduction} */}
                        </div>
                    </div>
                    <div>
                        <p>
                            {data.workshopDescription}
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.smallGallery}>
                <img className={styles.smallImg} src="https://i.pinimg.com/736x/e3/bf/d7/e3bfd7a850740b556d0f51aa4c06e84d.jpg" />
                <img className={styles.smallImg} src="https://i.pinimg.com/736x/e3/bf/d7/e3bfd7a850740b556d0f51aa4c06e84d.jpg" />
                <img className={styles.smallImg} src="https://i.pinimg.com/736x/e3/bf/d7/e3bfd7a850740b556d0f51aa4c06e84d.jpg" />
                <img className={styles.smallImg} src="https://i.pinimg.com/736x/e3/bf/d7/e3bfd7a850740b556d0f51aa4c06e84d.jpg" />
                <img className={styles.smallImg} src="https://i.pinimg.com/736x/e3/bf/d7/e3bfd7a850740b556d0f51aa4c06e84d.jpg" />
            </div>

            <div className={styles.actionWrapper}>
                <button className={styles.eachActionWrapper}><GrNotes size={11} />&nbsp; Get Notes</button>
                <button className={styles.eachActionWrapperGallery}><GrGallery size={11} />&nbsp; View Gallery</button>
                <button className={styles.eachActionWrapperCerti}><GrCertificate size={11} color="white" />&nbsp; Get Certificate</button>
            </div>

        </Link>
    )
}

export default EachPastWorkshopCard