import React from 'react'
import { FaEye, FaFire, FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { BASE_URL_API } from '../References/urls';
import styles from '../Styles/Components/EachCourseInList.module.css'

let width = window.innerWidth;

const EachCourseInList = props => {
    const item = props.item;
    const selectCourseHandler = props.selectCourseHandler;
    const selectedCourse = props.selectedCourse;
    // console.log(width, "I'm priting screen width")
    console.log(item)

    // const getCourseDetails = async (k) => {
    //     const getCourseDetailsResponse = await fetch(BASE_URL_API + "/getCourseDetails",
    //     {
    //         method : "POST",
    //         body : JSON.stringify({
    //             courseId : item.courseId
    //         })
    //     });

    //     if(!getCourseDetailsResponse.ok) {
    //         console.log("Something went wrong : Server Error!")
    //     } else {
    //         const getCourseDetailsRespo = await getCourseDetailsResponse.json();
    //         console.log(getCourseDetailsRespo)
    //     }
    // }


    if (width < 600) {
        return (
            <Link to={"/course/makeup/" + item.courseRoute} >
                <div className={styles.eachCourseWrapper}>
                    <div className={styles.courseBannerContainer}>
                        <div className={styles.courseBannerImg}>
                            <img src="https://www.bhimakeupacademy.com/images/course-sec-2(1).jpg" />
                        </div>
                        <div className={styles.courseBannerLayer}>
                            <div className={styles.courseLevel}>
                                {item.courseLevel} Level course
                            </div>
                        </div>
                    </div>
                    <div className={styles.courseTitleMobile}>
                        <h2>{item.courseTitle}</h2>
                    </div>
                    <div className={styles.courseDurationNLecMobile}>
                        <div className={styles.courseDuraMobile}>
                            {item.moduleDurationInfo.duration} hours
                        </div>
                        <div className={styles.courseLecMobile}>
                            {item.moduleDurationInfo.totalModules} modules
                        </div>
                    </div>
                    <div className={styles.ratingsNPriceContainer}>
                        <div>
                            <FaStar color="#ffcb08" />
                            <FaStar color="#ffcb08" />
                            <FaStar color="#ffcb08" />
                            <FaStar color="#ffcb08" />
                            <FaStar color="#ffcb08" />
                        </div>
                        <div className={styles.priceContainer}>
                            <div className={styles.basePrice}>
                                &#8377;{item.coursePricing.basePrice}/-
                            </div>
                            <div className={styles.disPrice}>
                                &#8377;{item.coursePricing.disPrice}/-
                            </div>
                        </div>
                    </div>
                    <div className={styles.descriptionContainer}>
                        {item.courseShortDescription.length > 110 ? item.courseShortDescription.substring(0, 120) + " ..." : item.courseShortDescription}
                    </div>
                </div>
            </Link>
        )
    }

    return (
        <div
            className={selectedCourse === item ? styles.eachItemWrapperSelected : styles.eachItemWrapper}
            onClick={() => selectCourseHandler(item)}
        >
            <div className={styles.courseSmallTitle}>
                <h4>{item.courseTitle}</h4>
            </div>
            <div className={styles.courseShortDescription}>
                {item.courseShortDescription}
            </div>
            <div className={styles.tagsContainer}>
                <div className={styles.tagsContainerEachRow}>
                    <div className={styles.eachTag}>
                        12+ makeup Looks
                    </div>
                    <div className={styles.eachTag}>
                        Detailed theory
                    </div>
                </div>
                <div className={styles.tagsContainerEachRow}>
                    <div className={styles.eachTag}>
                        Certification
                    </div>
                    <div className={styles.eachTag}>
                        Guaranteed Placement
                    </div>

                </div>
            </div>
            <div className={styles.courseSmallCardFooter}>
                <div className={styles.ratingContainerSmall}>
                    <FaStar color="#ffcb08" size={12} />
                    <FaStar color="#ffcb08" size={12} />
                    <FaStar color="#ffcb08" size={12} />
                    <FaStar color="#ffcb08" size={12} />
                    <FaStar color="#ffcb08" size={12} />
                </div>
                <div className={styles.trendingNStats}>
                    <div className={styles.isTrendingSmall}>
                        <FaFire color="#da1884" size={10} /> trending among freshers
                    </div>
                    <div>
                        <FaEye color="#dfdfdf" size={10} /> 30k
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachCourseInList