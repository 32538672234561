import React, { useEffect, useRef } from 'react';
import styles from './SlideInContent.module.css';

const SlideInContent = ({ children }) => {
    const contentRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.inView);
                } else {
                    entry.target.classList.remove(styles.inView);
                }
            });
        });

        if (contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                observer.unobserve(contentRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.content} ref={contentRef}>
            {children}
        </div>
    );
};

export default SlideInContent;
