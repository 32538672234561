import React, { useEffect, useRef, useState } from 'react'
import Collapsible from 'react-collapsible';
import { FaCheck, FaChevronDown, FaPlayCircle } from 'react-icons/fa';
import { useParams } from 'react-router';
import FAQsEachCourse from '../Components/FAQsEachCourse';
import Footer from '../Components/Footer';
import NavbarHandler from '../Helper/NavbarHandler';
import styles from '../Styles/Courses/PremiumAsset.module.css';

import { BASE_URL_API } from '../References/urls';
import EachSubModules from './EachSubModules';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga';

const width = window.innerWidth;

const PremiumAsset = () => {
    let params = useParams();
    const [scrollFromTop, setScrollFromTop] = useState(0);
    const [offsetTopRc, setOffsetTopRc] = useState()
    const rightContentColRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [courseData, setCourseData] = useState('');

    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [isPreRegistered, setIsPreRegistered] = useState(false)
    const [beingRegistered, setBeingRegistered] = useState(false)

    const getAssetDetails = async () => {
        setIsLoading(true)
        const getAssetDetailsResponse = await fetch(BASE_URL_API + "/getAssetDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    route: params.identifier
                })
            });

        if (!getAssetDetailsResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getAssetDetailsRespo = await getAssetDetailsResponse.json();
            setCourseData(getAssetDetailsRespo.response);
        }
        setIsLoading(false);
    }

    const handleScroll = () => {
        let p = rightContentColRef.current;
        let footerPos = document.getElementById("footerContainerWr").offsetTop;
        setScrollFromTop(-1 * document.body.getBoundingClientRect().top);
    }

    useEffect(() => {
        getAssetDetails()
    }, [])

    useEffect(() => {
        let p = rightContentColRef.current;
        setOffsetTopRc(p.offsetTop);
    }, [rightContentColRef.current])


    window.addEventListener("scroll", handleScroll);

    const phoneHandler = (k) => {
        setPhone(k)
    }

    const fullNameHandler = (k) => {
        setFullName(k)
    }

    const preRegisterNow = async () => {
        if (phone.length == 10) {
            if (fullName.length > 2) {
                setBeingRegistered(true)
                const preRegisterForCourse = await fetch(BASE_URL_API + "/preRegisterForCourse",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            fullName: fullName,
                            phone: phone,
                            courseId: courseData.courseId
                        })
                    });

                if (!preRegisterForCourse.ok) {
                    console.log("Something went wrong : Server Error")
                } else {
                    const preRegisterForCourseRespo = await preRegisterForCourse.json();
                    if (preRegisterForCourseRespo.status == "success") {
                        setIsPreRegistered(true)
                    } else {
                        alert("Could not registered! Please try again or whatsapp us on 8929460356")
                    }
                }
                setBeingRegistered(false)
            } else {
                alert("Please enter your full name!")
            }
        } else {
            alert("Please enter 10 digit number!");
        }
    }



    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])


    return (
        <div >
            <Helmet>
                {!isLoading &&
                    <title>{courseData.courseTitle} - Stylelink Course</title>}
                <meta name="description" content="Get exceptional knowledge in beauty, hair, and makeup with stylelink courses."></meta>
            </Helmet>
            <NavbarHandler type="home" />
            <section className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    {!isLoading ?
                        <div className={styles.courseBanner}>
                            <div className={styles.courseBannerImg}>
                                {/* <img src="https://static.vecteezy.com/system/resources/previews/002/294/862/non_2x/digital-courses-web-banner-design-student-watching-online-courses-online-education-digital-classroom-e-learning-concept-header-or-footer-banner-free-vector.jpg" /> */}
                            </div>
                            <div className={styles.courseBannerLayer}>
                                <h2>Start Learning</h2>
                            </div>
                        </div>
                        :
                        <div className={styles.courseBanner}>
                            <div className={styles.courseBannerImg}>
                                {/* <img src="https://static.vecteezy.com/system/resources/previews/002/294/862/non_2x/digital-courses-web-banner-design-student-watching-online-courses-online-education-digital-classroom-e-learning-concept-header-or-footer-banner-free-vector.jpg" /> */}
                            </div>
                            <div className={styles.courseBannerLayer}>
                                <h2>Start Learning</h2>
                            </div>
                        </div>

                    }
                    {!isLoading ?
                        <div className={styles.mainBody}>
                            <div className={styles.leftContentCol}>
                                <div className={styles.courseTitle}>
                                    <h1>{courseData.courseTitle}</h1>
                                </div>
                                {scrollFromTop > (offsetTopRc - 80) &&
                                    <div className={styles.courseTitleFixed}>
                                        <h1>{courseData.courseTitle}</h1>
                                    </div>
                                }

                                {courseData['wyl'] != null &&
                                    <section className={styles.eachSection}>
                                        <div className={styles.whatYoullLearnContainer}>
                                            <div className={styles.sectionTitle}>
                                                <h3>What you'll learn in this course!</h3>
                                            </div>
                                            <div className={styles.sectionBody}>
                                                <div className={styles.wylValContainer}>
                                                    {courseData['wyl'].map((itemWyl, index) => (
                                                        <div className={styles.eachWhatYoullDo}>
                                                            <FaCheck size={10} /><span>{itemWyl.learning}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }


                                {courseData['courseModules'] != null ?
                                    <section className={styles.eachSection}>
                                        <Collapsible trigger={
                                            <div className={styles.eachSectionHeader}>
                                                <div className={styles.sectionTitle}>
                                                    <h3>Course Modules!</h3>
                                                    <span className={styles.chevDown}><FaChevronDown /> </span>
                                                </div>
                                                <div className={styles.sectionSubtitle}>
                                                    <span className={styles.eachTagInSection}> {courseData['courseModules'].length} modules </span>
                                                </div>
                                            </div>}
                                            open={true}
                                        >
                                            <div className={styles.sectionBodyModules}>
                                                {courseData['courseModules'].map((item, index) => (
                                                    <div className={styles.eachModuleContainer} key={"R" + index}>
                                                        <Collapsible trigger={
                                                            <div className={styles.eachModuleHeader}>
                                                                <div className={styles.moduleHeaderTitle}>
                                                                    <h3>Module - {index + 1}. {item.moduleTitle}</h3>
                                                                </div>
                                                                <div className={styles.moduleHeaderStats}>
                                                                    {/* 4 submodules . 24 minutes */}
                                                                </div>
                                                            </div>}
                                                        >
                                                            <div>
                                                                <EachSubModules moduleId={item.moduleId} />
                                                            </div>
                                                        </Collapsible>
                                                    </div>
                                                ))}
                                            </div>
                                        </Collapsible>
                                    </section>
                                    :
                                    <div>
                                        <center>
                                            No modules found!
                                        </center>
                                    </div>
                                }

                                {/* <section className={styles.dummy}>
                                    {params.category}
                                    PremiumAsset
                                </section> */}
                            </div>
                            <div className={scrollFromTop > (offsetTopRc - 80) ? (scrollFromTop > (document.getElementById("footerContainerWr").offsetTop - 400 - window.innerHeight) ? styles.rightContentCol : styles.rightContentColFixed) : styles.rightContentCol}
                                ref={rightContentColRef}
                            >
                                {isPreRegistered == false &&
                                    <div className={styles.coursePriceBox}>
                                        <span className={styles.courseOrgPrice}>
                                            &#8377;{courseData.coursePricing.basePrice}/-
                                        </span>
                                        &nbsp; &nbsp;
                                        <span className={styles.courseDisPrice}>
                                            &#8377;{courseData.coursePricing.disPrice}/-
                                        </span>
                                    </div>}
                                {isPreRegistered == false ?
                                    <>
                                        <div className={styles.preRegistrationFormContainer}>
                                            <div className={styles.prfcFieldContainer}>
                                                <input type="number" placeholder="Please enter your phone..."
                                                    onChange={(e) => phoneHandler(e.target.value)}
                                                    value={phone}
                                                />
                                            </div>
                                            <div className={styles.prfcFieldContainer}>
                                                <input placeholder="Enter your name..."
                                                    onChange={(e) => fullNameHandler(e.target.value)}
                                                    value={fullName}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.courseBuyNow}>
                                            {beingRegistered == false && <button onClick={() => preRegisterNow()}>Pre-register Now</button>}
                                            <p>100% placement guarantee</p>
                                        </div>
                                    </>
                                    :
                                    <div className={styles.registeredMsg}>
                                        Congratulations, you have successfully registered!
                                        <br>
                                        </br>
                                        You'll get the additional 10% discount!
                                    </div>
                                }


                                <div className={styles.thisCourseIncludes}>
                                    <h4>This course includes</h4>
                                    <div className={styles.eachItemIncluding}>
                                        <span><FaCheck size={10} /></span>&nbsp;
                                        <span>Completion Certificate</span>
                                    </div>
                                    <div className={styles.eachItemIncluding}>
                                        <span><FaCheck size={10} /></span>&nbsp;
                                        <span>100% Job guarantee</span>
                                    </div>
                                    <div className={styles.eachItemIncluding}>
                                        <span><FaCheck size={10} /></span>&nbsp;
                                        <span>6 months training</span>
                                    </div>
                                    <div className={styles.eachItemIncluding}>
                                        <span><FaCheck size={10} /></span>&nbsp;
                                        <span>Live doubt solving session</span>
                                    </div>

                                </div>
                            </div>


                            <div>

                            </div>
                        </div>
                        :
                        <div className={styles.mainBody}>
                            <div className={styles.leftContentCol}>
                            </div>

                            <div className={styles.rightContentCol}
                                ref={rightContentColRef}
                            >
                            </div>
                        </div>

                    }

                </div>
            </section>

            <section className={styles.faqSection}>
                <FAQsEachCourse />
            </section>

            <section id="footerContainerWr">
                <Footer />
            </section>
        </div>
    )
}

export default PremiumAsset