import React, { useEffect, useState } from 'react'
import Footer from '../Components/Footer';
import { BASE_URL_API } from '../References/urls'
import styles from './OurPresence.module.css'

const OurPresence = () => {

    const [value, setValue] = useState(0);

    const [ourOperatingAreas, setOurOperatingAreas] = useState(["Delhi", "Noida", "Ghaziabad", "Lucknow", "Gurugram"])
    const getOperatingAreas = async () => {
        const getOperatingAreasResponse = await fetch(BASE_URL_API + "/getOperatingAreas",
            {
                method: "POST",
                body: JSON.stringify({

                })
            });

        if (!getOperatingAreasResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getOperatingAreasRespo = await getOperatingAreasResponse.json()
            if (getOperatingAreasRespo.status === "success") {
                setOurOperatingAreas(getOperatingAreasRespo?.response)
            } else {
                setOurOperatingAreas([])
            }
            console.log(getOperatingAreasRespo)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        getOperatingAreas()
    }, [])



    useEffect(() => {
        let startTimestamp = null;

        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / 1500, 1);
            setValue(Math.floor(progress * (ourOperatingAreas.length - 0) + 0));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };

        window.requestAnimationFrame(step);
    }, [ourOperatingAreas]);


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.firstPaintWrapper}>
                <div className={styles.fpwLeftWrapper}>
                    <div className={styles.fpwLeftContainer}>
                        <h1>
                            A platform that is build on the India's largest salon professional's networks.
                        </h1>
                    </div>
                </div>
                <div className={styles.fpwRightWrapper}>
                    <div className={styles.fpwRightContainer}>
                        <div>
                            1 Nation
                        </div>
                        <div className={styles.citiesCount}>
                            {value} cities
                        </div>
                        <div className={styles.growing}>
                            & growing
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.secondPaintWrapper}>
                <div className={styles.secondPaintContainer}>
                    <div className={styles.spcTitle}>
                        <h2>Cities we provide salon staff</h2>
                    </div>
                    <div className={styles.citiesWrapper}>
                        {ourOperatingAreas?.map((item, index) => (
                            <div key={index} className={styles.eachCityWrapper}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <div className={styles.thirdPaintWrapper}>

            </div>

            <Footer />
        </div>
    )
}

export default OurPresence