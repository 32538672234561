import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CitySelector from '../../Components/Jobs/CitySelector';
import ProfileSelector from '../../Components/Jobs/ProfileSelector';
import SalonJobsFooter from '../../Components/Jobs/SalonJobsFooter';
import TopJobs from '../../Components/Jobs/TopJobs';
import NavbarHandler from '../../Helper/NavbarHandler'
import styles from './FindSalonJobs.module.css'

const FindSalonJobs = () => {

    const [coordinates, setCoordinates] = useState(null);
    const [isCitySelectorActive, setIsCitySelectorActive] = useState(false)
    const [selectedCity, setSelectedCity] = useState('')
    const [forwardingUrl, setForwardingUrl] = useState('')
    const [isProfileSelectorActive, setIsProfileSelectorActive] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState('')

    const citySelectorToggler = () => {
        setIsCitySelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    const selectCityHandler = (k) => {
        setSelectedCity(k)
        setIsCitySelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }
    const profileSelectorToggler = () => {
        setIsProfileSelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    const selectProfileHandler = (k) => {
        setSelectedProfile(k)
        setIsProfileSelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCoordinates({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    alert('Error getting geolocation:', error);
                }
            );
        } else {
            console.error('Geolocation not supported by this browser.');
            alert('Geolocation not supported by this browser.');
        }
    }, []);

    const generateLandingUrl = () => {
        let fu = (selectedProfile?.label === 'Any') ? 'salon-jobs' : `${selectedProfile?.label || 'salon'}-jobs`;
    
        if (selectedProfile?.label === 'Any' && (!selectedCity || selectedCity.toLowerCase() === 'near-me')) {
            fu += '-near-me';
        } else if (selectedProfile?.label !== 'Any') {
            if (!selectedCity || selectedCity.toLowerCase() === 'near-me') {
                fu += '-near-me';
            } else if (selectedCity.toLowerCase() !== 'anywhere') {
                fu += `-in-${selectedCity}`;
            }
        }
    
        fu = fu.toLowerCase().trim()
            .replace(/[^a-z0-9\s-]/g, '')  // Remove special characters except spaces and hyphens
            .replace(/\s+/g, '-')          // Replace spaces with hyphens
            .replace(/-$/, '');            // Remove trailing hyphen if any
    
        setForwardingUrl(fu);
    };
    
    
    




    useEffect(() => {
        generateLandingUrl()
    }, [selectedCity, selectedProfile])


    console.log(forwardingUrl)

    return (
        <div className={styles.mainWrapper}>
            <NavbarHandler type="home" />
            {isCitySelectorActive && <CitySelector
                isOpen={isCitySelectorActive}
                onClose={() => citySelectorToggler()}
                selectCityHandler={selectCityHandler}
                selectedCity={selectedCity}
                idToRef="masterSearchJobWrapper"
            />}
            {isProfileSelectorActive && <ProfileSelector
                isOpen={isProfileSelectorActive}
                onClose={() => profileSelectorToggler()}
                selectProfileHandler={selectProfileHandler}
                selectedProfile={selectedProfile}
                idToRef="masterSearchJobWrapper"
            />}
            <div className={styles.mainContainer}>
                <div className={styles.firstPaintWrapper}>
                    <div className={styles.upperHeader}>
                        <div className={styles.topTitle}>
                            <h1>
                                Find nearby salon jobs
                            </h1>
                        </div>
                        <div className={styles.subTitle}>
                            <p>More than 2000 salons are looking to hire you across India. Apply now!</p>
                        </div>
                    </div>
                    <div className={styles.searchWrapper} >
                        <div className={styles.searchContainer} id="masterSearchJobWrapper">
                            <div className={styles.profileInputContainer}>
                                <input placeholder="Search for job profile..."
                                    onFocus={() => setIsProfileSelectorActive(true)}
                                    value={selectedProfile?.label}
                                />
                            </div>
                            <div className={styles.cityInputContainer}>
                                <input placeholder="Search for city..."
                                    onFocus={() => setIsCitySelectorActive(true)}
                                    value={selectedCity}
                                />
                            </div>
                            <div className={styles.searchBtnContainer}>
                                <Link to={`/${forwardingUrl}`}>Find Jobs</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {coordinates ? (
                    <div>
                        Latitude: {coordinates.latitude}<br />
                        Longitude: {coordinates.longitude}
                    </div>
                ) : (
                    <div>.</div>
                )} */}

                <TopJobs />

                <SalonJobsFooter />
            </div>
        </div>
    )
}

export default FindSalonJobs