import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from '../Workshops/GCWCertificateAssessmentCover.module.css'

import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/urls';

const GCWCertificateAssessmentCover = () => {
    let params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [hasRegistered, setHasRegistered] = useState(false);
    const [isAssessment, setIsAssessment] = useState(false);
    const identifier = params.identifier;

    const validateCertificateRequirements = async () => {
        setIsLoading(true)
        const validateCertificateRequirementsResponse = await fetch(BASE_URL_API+"/validateCertificateRequirements",
        {
            method : 'POST',
            body : JSON.stringify({
                styIdentifier : '1245',
                workshopIdentifier : '14553',
                token : 'token'
            })
        });

        if(!validateCertificateRequirementsResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const validateCertificateRequirementsRespo = await validateCertificateRequirementsResponse.json();
            console.log(validateCertificateRequirementsRespo)
        }
        setIsLoading(false)
    }


    useEffect(() => {
        validateCertificateRequirements()
    }, [identifier])
    



    if (isLoading) {
        return (
            <div className={styles.mainWrapper}>
                Hold On!
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            {hasRegistered === false ?
                <div className={styles.hnrContainer}>
                    <div className={styles.bigTitle}>
                        Alas!
                    </div>
                    <div className={styles.captionsContainer}>
                        <div className={styles.subTitle}>
                            <h3>
                                You hadn't pre-registered for this workshop!
                            </h3>
                            <h6>
                                All the candidates are required to pre-register for the workshop in-order to get the certificate!
                            </h6>
                        </div>
                        <div>
                            <Link to={``}>
                                <div className={styles.readAboutEC}>
                                    Read about the certification eligiblity criteria here!
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.actionWrapper}>
                        <Link to={`/workshops`}>
                            <div className={styles.priBtn}>
                                See Upcoming Workshops!
                            </div>
                        </Link>
                    </div>
                </div>
                :
                isAssessment === false ?
                    <div className={styles.gcContainer}>
                        <div className={styles.gcContent}>
                            <div className={styles.gcBigTitle}>
                                Great,
                            </div>
                            <div>
                                <h3>
                                    that you have pre-registered for the workshop.
                                </h3>
                                <h6>
                                    You can claim your certificate by clicking on the button below.
                                </h6>

                            </div>

                            <div>
                                <Link to={`/workshop/certificate/sdfkyel`}>
                                    <div className={styles.priBtn}>
                                        Generate Certificate
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.gaContainer}>
                        <div className="timer" style={{ position: 'absolute', top: '100svh', width: '100%' }}>
                            <div className={styles.gaContent}>
                                <div className={styles.gaMessage}>
                                    <div className={styles.lineOne}>
                                        Thank you for attending the live workshop.
                                    </div>
                                    <div className={styles.lineTwo}>
                                        We hope you found it informative and engaging.
                                    </div>
                                    <div className={styles.lineThree}>
                                        To claim your certificate, please complete the brief exam/questionnaire on the following page.
                                    </div>
                                    <div className={styles.lineFour}>
                                        Your participation and feedback are greatly appreciated.
                                    </div>
                                </div>
                                <div>
                                    <Link to={`/workshop/certificate/assessment/` + identifier + `/exam`}>
                                        <div className={styles.priBtn}>
                                            Start assessment
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default GCWCertificateAssessmentCover