import React from 'react'
import styles from '../Styles/Components/UserLoginCard.module.css'

const UserLoginCard = props => {
    return (
        <div className={styles.userLoginCard}>
            <div className={styles.personProfilePic}>
                <img src="https://cdn.dribbble.com/users/4208985/avatars/normal/292934aa4c32bea2a74f4b1c6693ba65.png" />
            </div>
            <div className={styles.personDetails}>
                <h4>Stylelink user</h4>
                <p>stylelink.in</p>
            </div>
            <div className={styles.editProfileBtn}>
                <button>Login</button>
            </div>
        </div>
    )
}

export default UserLoginCard