import logo from './logo.svg';
import './App.css';
import MainNavigator from './Navigator/MainNavigator';



import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";
import { TranslationProvider } from './store/TranslationContext';




// import ReactGA from 'react-ga';
// const TRACKING_ID = "G-P1ECRQGQWX"; // OUR_TRACKING_ID
// const TRACKING_ID = "G-X4SCR8LC2G"; // OUR_TRACKING_ID
// const TRACKING_ID = "G-C719XGNZCR"; // OUR_TRACKING_ID
// const TRACKING_ID = "UA-223734346-1"; // OUR_TRACKING_ID  // older active

const TRACKING_ID = "G-QH842Q832S" // newly added for GA4

ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  // gtmId: 'AW-10973187642',
  gtmId : 'GTM-MN8J4FJC'
}


TagManager.initialize(tagManagerArgs)


document.addEventListener('click', function (event) {
  var text = event.target.innerText.trim()
  if (text.indexOf('SUBMIT') != -1) {
    ReactGA.event({
      action: "conversion",
      category: "Conversions",
      send_to: "AW-761375362/ZkzACN_HtvQYEILVhusC",
    });
    console.log("test event : successful")

  }

}, { capture: true });



function App() {
  return (
    <TranslationProvider>
      <MainNavigator />
    </TranslationProvider>
  );
}

export default App;
