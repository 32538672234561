import React from 'react'
import styles from '../Workshops/GenerateWorkshopCertificate.module.css'
import GCWCountdownTime from './GCWCountdownTime'
import GCWValidateWorkshopRegistration from './GCWValidateWorkshopRegistration'

const GenerateWorkshopCertificate = props => {
    const {item} = props
    // console.log(props.item.startingTime, "this is gwc")

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftSection}>
                    <div className={styles.mainTitle}>
                        <h3>
                            Collect,
                        </h3>
                        <h4>
                            that matters!
                        </h4>
                    </div>
                    <div className={styles.trustedWrapper}>
                        <div className={styles.trustedContainer}>
                            India's most trusted/valuable brand in salon education!
                        </div>

                    </div>
                    <div className={styles.solidfyingSection}>
                        Solidying your skills...
                    </div>

                </div>
                <div className={styles.rightSection}>
                    <GCWCountdownTime
                        item={item}
                    />
                </div>
            </div>
            
        </div>
    )
}

export default GenerateWorkshopCertificate