import React from 'react'
import { useLottie } from 'lottie-react';
import qrAnimation from '../Json/qrAnimation.json'

const QrAI = () => {
    const options = {
        animationData: qrAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default QrAI