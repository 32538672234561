import React, { useState } from 'react'
import { FaStar } from 'react-icons/fa'
import styles from './HireSalonStaffClientsReview.module.css'

const HireSalonStaffClientsReview = props => {
    const { reviews } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainTitle}>
                <h4>
                    Our partners loves us
                </h4>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.reviewsWrapper}>
                    {reviews.map((item, index) => (
                        <div className={styles.eachCardWrapper} key={index}>
                            <div className={styles.content}>
                                {item.content}
                            </div>
                            <div className={styles.titlesCaption}>
                                <div className={styles.author} >{item.author}</div>
                                <div className={styles.stars}>
                                    <FaStar color="#E3CEA6" size={13} className={styles.eachStar} />
                                    <FaStar color="#E3CEA6" size={13} className={styles.eachStar} />
                                    <FaStar color="#E3CEA6" size={13} className={styles.eachStar} />
                                    <FaStar color="#E3CEA6" size={13} className={styles.eachStar} />
                                    <FaStar color="#E3CEA6" size={13} className={styles.eachStar} />
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HireSalonStaffClientsReview