import React from 'react'
import { FaRegBookmark, FaRupeeSign } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styles from '../Styles/Components/EachJobContainer.module.css'

const EachJobContainer = props => {
    const jobData = props.data;
    const skills = jobData.skill.split(",");


    return (
        <Link to={`/job/` + jobData.jobRoute} style={{ textDecoration: "none", color: "black" }}>
            <div className={styles.mainContainer}>
                <div className={styles.header}>
                    <div className={styles.headerLeft}>
                        <div className={styles.jobCategoryIcon}>
                            <div>B</div>
                        </div>
                        <div className={styles.profileStoreDetails}>
                            <div className={styles.profileName}>
                                <h2>{jobData.title}</h2>
                            </div>
                            <div className={styles.storeAddress}>
                                {jobData?.address?.substring(0, 70)}...
                            </div>
                        </div>

                    </div>
                    <div className={styles.headerRight}>
                        <button className={styles.saveJobContainer}>
                            Save Job &nbsp; <FaRegBookmark />
                        </button>
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.jobDescription} dangerouslySetInnerHTML={{ __html: jobData.description.substring(0, 200) }}></div>
                    <span className={styles.readMore}>...read more</span>
                    {jobData.skill != "" &&
                        <div className={styles.skillContainer}>
                            {skills.map((item, index) => (
                                <div className={styles.eachSkillContainer} key={"sl"+index+item} >{item}</div>
                            ))}
                        </div>
                    }
                    <div className={styles.foodAccomoWrapper}>
                        <div className={styles.foodAccomoContainer}>
                            <div className={styles.fnaTitle}>
                                FOOD & ACCOMODATION
                            </div>
                            <div className={styles.fnaValue}>
                                {jobData.accomodation}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.eachFooterField}>
                        <FaRupeeSign color="#DA1884" /> {jobData.salary_min} - {jobData.salary_max}/-

                    </div>
                    <div className={styles.eachFooterField}>
                        <button>Apply Now</button>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default EachJobContainer