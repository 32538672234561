import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaShieldAlt, FaUserShield } from 'react-icons/fa'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { sanitizePhoneNumber } from '../../Helper/SupportingAlgos'
import { BASE_URL_API } from '../../References/urls'
import styles from './CHCourseEnquiryForm.module.css'

const CHCourseEnquiryForm = () => {
    const { categoryIdentifier } = useParams()
    // console.log(categoryIdentifier)

    const [fullName, setFullName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [courseLevel, setCourseLevel] = useState('')
    const [formValidated, setFormValidated] = useState(true)
    const [errorAt, setErrorAt] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const submitFormHandler = async () => {
        setIsLoading(true)
        const captureCourseEnquiryLeadsResponse = await fetch(BASE_URL_API + "/captureCourseEnquiryLeads",
            {
                method: "POST",
                body: JSON.stringify({
                    fullName: fullName,
                    phoneNumber: phoneNumber,
                    address: address,
                    courseLevel: courseLevel,
                    categoryIdentifier: categoryIdentifier
                })
            });
        if (!captureCourseEnquiryLeadsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const captureCourseEnquiryLeadsRespo = await captureCourseEnquiryLeadsResponse.json();
            if (captureCourseEnquiryLeadsRespo.status === "success") {
                // alert("Successfully recorded your response.")
                setHasSubmitted(true)
            } else {
                console.log(captureCourseEnquiryLeadsRespo)
            }
        }
        setIsLoading(false)
        // console.log(fullName, phoneNumber, address, courseLevel, categoryIdentifier)
    }

    const formValidator = () => {
        if (fullName.length > 1) {
            if (phoneNumber.length === 10) {
                if (address.length > 2) {
                    if (courseLevel !== '') {
                        setErrorAt('')
                        setFormValidated(true)
                    } else {
                        setErrorAt("COURSELEVEL")
                        setFormValidated(false)
                    }
                } else {
                    setErrorAt("ADDRESS")
                    setFormValidated(false)
                }
            } else {
                setErrorAt('PHONE')
                setFormValidated(false)
            }
        } else {
            setErrorAt('FULLNAME')
            setFormValidated(false)
        }
    }

    useEffect(() => {
        formValidator()
    }, [fullName, phoneNumber, address, courseLevel])

    useEffect(() => {
        setPhoneNumber(sanitizePhoneNumber(phoneNumber))
    }, [phoneNumber])
    


    return (
        <div className={styles.mainWrapper}>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
            </Helmet>
            {isLoading &&
                <div className={styles.loader}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/loaders/loader-fast-trans.gif" />
                </div>
            }

            {hasSubmitted === true ?
                <div className={styles.mainContainer}>
                    <div className={styles.headerContainer}>
                        <span className={styles.successMsg}>
                            Successfully Registered!
                        </span>
                    </div>
                    <div className={styles.bodyContainer}>
                        <div className={styles.thanksTxt}>
                            Thanks for showing interest!!
                        </div>
                        <div className={styles.actionTxtWrapper}>
                            <div className={styles.actionTxtPri}>
                                We will contact you shortly!
                            </div>
                            <div className={styles.actionTxtSec}>
                                Meanwhile you can explore our courses.
                            </div>
                            <Link to="/classroom-courses">
                                <button className={styles.exploreBtn}>Explore Courses</button>
                            </Link>
                        </div>
                    </div>
                </div>
                :
                <div className={styles.mainContainer}>
                    <div className={styles.headerContainer}>
                        Enroll Now!
                    </div>
                    <div className={styles.bodyContainer}>
                        <div className={styles.eachInputWrapper}>
                            <div className={styles.captionWrapper}>
                                <div className={styles.caption}>
                                    Full Name
                                </div>
                                {errorAt === "FULLNAME" &&
                                    <div className={styles.errorMsg}>
                                        Invalid name!
                                    </div>
                                }
                            </div>
                            <div className={styles.inputWrapper}>
                                <input
                                    placeholder="Please enter full name..."
                                    onChange={(e) => setFullName(e.target.value)}
                                    value={fullName}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                        <div className={styles.eachInputWrapper}>
                            <div className={styles.captionWrapper}>
                                <div className={styles.caption}>
                                    Phone number
                                </div>
                                {errorAt === "PHONE" &&
                                    <div className={styles.errorMsg}>
                                        Invalid phone
                                    </div>
                                }
                            </div>
                            <div className={styles.inputWrapper}>
                                <input
                                    placeholder="Please enter 10 digits phone number..."
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                    type="tel"
                                />
                            </div>
                        </div>
                        <div className={styles.eachInputWrapper}>
                            <div className={styles.captionWrapper}>
                                <div className={styles.caption}>Location / Address</div>
                                {errorAt === "ADDRESS" &&
                                    <div className={styles.errorMsg}>
                                        Invalid address
                                    </div>
                                }
                            </div>
                            <div className={styles.inputWrapper}>
                                <input
                                    placeholder="Please enter your address / location..."
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                />
                            </div>
                        </div>
                        <div className={styles.eachInputWrapper}>
                            <div className={styles.captionWrapper}>
                                <div className={styles.caption}>Level of course</div>
                                {errorAt === "COURSELEVEL" &&
                                    <div className={styles.errorMsg}>
                                        Please select course level
                                    </div>
                                }
                            </div>
                            <div className={styles.btnsWrapper}>
                                <button className={courseLevel === "BASIC" ? styles.selectedBtn : styles.normalBtn} onClick={() => setCourseLevel('BASIC')} >Basic Level</button>
                                <button className={courseLevel === "ADVANCE" ? styles.selectedBtn : styles.normalBtn} onClick={() => setCourseLevel('ADVANCE')} >Advance Level</button>
                            </div>
                        </div>
                        <div className={styles.dataSafeLine}>
                            <FaShieldAlt color="green" size={12} />&nbsp;Your data is 100% safe
                        </div>
                        <div className={styles.submitBtnWrapper}>
                            {formValidated === true ?
                                <button className={styles.activeBtn} onClick={() => submitFormHandler()}>Submit</button>
                                :
                                <button className={styles.defaultBtn} onClick={() => alert("Please fill all the details")}>Submit</button>
                            }
                        </div>

                    </div>
                </div>
            }

        </div>
    )
}

export default CHCourseEnquiryForm