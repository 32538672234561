import React from 'react'
import styles from './FBHeader.module.css'

const FBHeader = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.logoWrapper}>
                <img src="https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/master-file-hire-salon-staff-whatsapp.png" />
            </div>
            <div className={styles.helpWrapper}>
                <a href="tel:+91-8383054040">Help</a>
            </div>
        </div>
    )
}

export default FBHeader