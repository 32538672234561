import React from 'react'
import styles from './AAVHome.module.css'

const AAVHome = () => {

    // document.addEventListener("scroll", function () {
    //     const scrollTop = window.scrollY;
    //     const video = document.querySelector("video");
    //     video.style.top = -scrollTop * 0.5 + "px"; // Adjust the speed by changing the multiplier (0.5 in this example).
    // });

    

    return (
        <div>
            <div className={styles.parallaxContainer}>
                <div className={styles.videoOverlay}>
                    <h3>Stylelink Academy Makeup Centre</h3>
                    <p>Glimpse of stylelink makeup academy at Sector - 117, Noida</p>
                </div>
                <video autoPlay loop muted>
                    <source src="https://stylelink.s3.ap-south-1.amazonaws.com/academy-visuals/makeupstudio.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            {/* <div className={styles.content}>
                <p>This is some content that scrolls over the video.</p>
            </div> */}
        </div>
    )
}

export default AAVHome