import React, { useEffect } from 'react'
import { FaQrcode } from 'react-icons/fa'
import NavbarHandler from '../../Helper/NavbarHandler'
import QrAI from '../../Icons/Animated/QrAI'
import ResumeAI from '../../Icons/Animated/ResumeAI'
import ResumeMobileAI from '../../Icons/Animated/ResumeMobileAI'
import styles from './Resume.module.css'

const Resume = () => {

    useEffect(() => {
        const bodyEl = document.getElementsByTagName("body")[0]
        if(bodyEl) {
            // console.log(bodyEl)
            bodyEl.classList.remove("no-scroll")
        }
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#000000");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#000000";
            document.head.appendChild(meta);
        }
    }, []);


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.firstPaintWrapper}>
                    <div className={styles.firstPaintContainer}>
                        <div className={styles.fpcLeft}>
                            <div className={styles.iceBreaker}>
                                <h2>Upgrade your salon resume.</h2>
                            </div>
                            <div className={styles.subIceBreaker}>
                                <h3>
                                    Get jobs in one click.
                                </h3>
                            </div>
                        </div>
                        <div className={styles.fpcRight}>
                            <div className={styles.fpcRightContainer}>
                                <div className={styles.fpcrBox}>
                                    <div className={styles.fpcrHeader}>
                                        <div className={styles.qrIcon}>
                                            <QrAI />
                                        </div>
                                        <div className={styles.scanTxt}>
                                            SCAN & SHARE
                                        </div>
                                    </div>
                                    <div className={styles.fpcrBody}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.videoWrapper}>
                    <div className={styles.videoContainer}>
                        <ResumeAI />
                    </div>
                </div>

                <div className={styles.videoWrapperMobile}>
                    <div className={styles.videoContainer}>
                        <ResumeMobileAI />
                    </div>
                </div>

                <div className={styles.buildNowWrapper}>
                    <div className={styles.buildNowContainer}>
                        <div className={styles.buildNowHeader}>
                            <h3>Build Your Resume</h3>
                            <h4>Super Easily</h4>
                        </div>
                        <div className={styles.buildNowBody}>
                            <div className={styles.giveUsTxt}>
                                <p>Give us a missed call!</p>
                            </div>
                            <a href="tel:+918287098287" >
                                <div className={styles.callNowBtn}>
                                    Call Now
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume