import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router';

const GCWCertificateAssessmentExam = () => {
    let params = useParams();
    const identifier = params.identifier;


    return (
        <div>GCWCertificateAssessmentExam
            <Link to={`/workshop/certificate/assessment/`+identifier+`/result`}>Check Results</Link>
        </div>
    )
}

export default GCWCertificateAssessmentExam