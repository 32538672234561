import React, { useState } from 'react'
import { FaBook, FaBriefcase, FaChalkboardTeacher, FaChevronRight, FaCross, FaHome, FaNetworkWired, FaRegWindowClose, FaStream, FaWindowClose } from 'react-icons/fa';
import { GrClose, GrUserWorker, GrWorkshop } from 'react-icons/gr';
import { SiBigcommerce, SiGoogleclassroom } from 'react-icons/si'
import { IoMdBriefcase } from "react-icons/io";

import { Link } from 'react-router-dom';
import styles from '../Styles/Components/MobileNavOptions.module.css';

const MobileNavOptions = props => {
    const { isVisible, mobileNavVisibleHandler } = props
    
    


    return (
        <div className={isVisible == true ? styles.mainContainerVisible : styles.hide}>
            <div className={styles.header}>
                <button onClick={() => mobileNavVisibleHandler(false)}>
                    <GrClose fontSize={24} color="white" fill="white" />
                </button>
            </div>
            <div className={styles.body}>
                <div>
                    <div className={styles.headerSectionTitle}>
                        Website
                    </div>
                    <Link className={styles.eachNavOptions} to="/" >
                        <div className={styles.navOptionsTitle}>
                            <FaHome />
                            <div className={styles.navOptionTxt}>Home</div>
                        </div>
                        <FaChevronRight />
                    </Link>
                    <Link className={styles.eachNavOptions} to="/courses" >
                        <div className={styles.navOptionsTitle}>
                            <FaBook />
                            <div className={styles.navOptionTxt}>Courses</div>
                        </div>
                        <FaChevronRight />
                    </Link>
                    <Link className={styles.eachNavOptions} to="/workshops" >
                        <div className={styles.navOptionsTitle}>
                            <SiGoogleclassroom />
                            <div className={styles.navOptionTxt}>Workshops</div>
                        </div>
                        <FaChevronRight />
                    </Link>
                    <Link className={styles.eachNavOptions} to="/jobs" >
                        <div className={styles.navOptionsTitle}>
                            <FaBriefcase />
                            <div className={styles.navOptionTxt}>Jobs</div>
                        </div>
                        <FaChevronRight />
                    </Link>
                    <Link className={styles.eachNavOptions} to="/get-my-salon-resume" >
                        <div className={styles.navOptionsTitle}>
                            <FaChalkboardTeacher />
                            <div className={styles.navOptionTxt}>Get Resume</div>
                        </div>
                        <FaChevronRight />
                    </Link>

                </div>
                <div>
                    <div className={styles.headerSectionTitle}>
                        Useful Links
                    </div>
                    <Link className={styles.eachNavOptions} to="/hire-salon-staff" >
                        <div className={styles.navOptionsTitle}>
                        <IoMdBriefcase />
                            <div className={styles.navOptionTxt}>Hire Salon Staff</div>
                        </div>
                        <FaChevronRight />
                    </Link>
                </div>

            </div>
            <div className={styles.footer}>
                <Link to="/login" className={styles.loginWrapper}>
                    Login
                </Link>
            </div>
        </div>
    )
}

export default MobileNavOptions