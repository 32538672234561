// TranslationContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const TranslationContext = createContext();

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }) => {
    const [translations, setTranslations] = useState({});
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        // Retrieve the language preference from localStorage, or default to 'en' (English)
        return localStorage.getItem('language') || 'en';
    });

    const loadTranslations = async (language) => {
        try {
            const translationModule = await import(`../References/Translations/strings_${language}.json`);
            setTranslations(translationModule.default);
        } catch (error) {
            console.error(`Failed to load translations for ${language}:`, error);
        }
    };

    useEffect(() => {
        loadTranslations(currentLanguage);
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        setCurrentLanguage(language);
        localStorage.setItem('language', language); // Save language preference to localStorage
    };

    return (
        <TranslationContext.Provider value={{ translations, currentLanguage, changeLanguage }}>
            {children}
        </TranslationContext.Provider>
    );
};
