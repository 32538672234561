import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import OnlyCopyright from '../../Components/Footers/OnlyCopyright'
import styles from './BPProducts.module.css'

const BPProducts = () => {
    const { category } = useParams()
    const pageTitle =  "Our makeup brand partners : Stylelink"
    const pageDescription = "Makeup brands we use at stylelink. As we believe in creating masters in their domain, hence we have partnered with global brands for our students."
    const pageKeywords = "brand partners, makeup brands, stylelink, luxurious makeup brands, international makeup brands, makeup brands used in academies"

    const [allies, setAllies] = useState([
        {
            brandName: "Armani Beauty",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Armani-Beauty.png"
        },
        {
            brandName: "Bobbi Brown",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Bobby-Brown.png"
        },
        {
            brandName: "Charlotte-Tilbury",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Charlotte-Tilbury.png"
        },
        {
            brandName: "Christian-DIOR",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Christian-DIOR.png"
        },
        {
            brandName: "CLE-DE-PEAU-BEAUTE",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/CLE-DE-PEAU-BEAUTE.png"
        },
        {
            brandName: "Clinique",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Clinique.png"
        },
        {
            brandName: "Elizabeth Arden",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Elizabeth-Arden.png"
        },
        {
            brandName: "Estee Lauder",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Estee-Lauder-Emblem.png"
        },
        {
            brandName: "Giorgio Armani",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Giorgio-Armani.png"
        },
        {
            brandName: "Givenchy",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Givenchy.png"
        },
        {
            brandName: "Hermes",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Hermes.png"
        },
        {
            brandName: "Guerlain",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/guerlain.png"
        },
        {
            brandName: "La-Prairie-Makeup",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/La-Prairie-Makeup.png"
        },
        {
            brandName: "Lancome",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Lancome.png"
        },
        {
            brandName: "Lar Mer",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Lar-Mer.png"
        },
        {
            brandName: "MAC Cosmetics",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/MAC-Cosmetics.png"
        },
        {
            brandName: "Maybelline",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Maybelline.png"
        },
        {
            brandName: "Oriflame",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Oriflame-Emblem.png"
        },
        {
            brandName: "Pat McGrath LABS",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Pat-McGrath-LABSd.png"
        },
        {
            brandName: "Shiseido",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Shiseido.png"
        },
        {
            brandName: "Sisley-Paris",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Sisley-Paris.png"
        },
        {
            brandName: "Urban Decay",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Urban-Decay.png"
        },
        {
            brandName: "Westmen Atelier",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Westmen-Atelier.png"
        },
        {
            brandName: "YSL",
            logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/YSL-Beauty.png"
        }
    ])


    return (
        <div className={styles.mainWrapper}>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription}></meta>
                <meta name="keywords" content={pageKeywords} ></meta>

                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="whatsapp:url" content={`https://stylelink.in/classroom-courses/make-up-courses`} />
                <meta name="whatsapp:title" content={pageTitle} />
                <meta name="whatsapp:description" content={pageDescription} />
                <meta name="whatsapp:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="telegram:url" content={`https://stylelink.in/classroom-courses/make-up-courses`} />
                <meta name="telegram:title" content={pageTitle} />
                <meta name="telegram:description" content={pageDescription} />
                <meta name="telegram:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

            </Helmet>
            <div className={styles.firstPaintWrapper}>
                <div className={styles.mainHeader}>
                    <h2>Our {category} brand partners</h2>
                    <p>We believe in creating masters in their domain, hence we have partnered with global brands for our students.</p>
                </div>
                <div className={styles.mainBody}>
                    <div className={styles.logoHolder}>
                        {allies?.map((item, index) => (
                            <div key={index} className={styles.eachCard}>
                                <div className={styles.brandLogo}>
                                    <img src={item?.logo} />
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

            <OnlyCopyright />

        </div>
    )
}

export default BPProducts