import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Workshops/GCWCountdownTime.module.css';

const GCWCountdownTime = ({ item }) => {
    const [secondsLeft, setSecondsLeft] = useState(0);

    useEffect(() => {
        if (item?.startingTime) {
            const getDifference = (k) => {
                const currentTime = new Date();
                const startTimeDate = new Date(k);
                let timeDifferenceInSeconds = Math.floor((startTimeDate - currentTime) / 1000);
                return timeDifferenceInSeconds > 0 ? timeDifferenceInSeconds : 0;
            };

            const updateCountdown = () => {
                setSecondsLeft(getDifference(item.startingTime));
            };

            updateCountdown(); // Initialize the countdown

            const interval = setInterval(() => {
                setSecondsLeft(prev => {
                    const newTime = prev - 1;
                    return newTime > 0 ? newTime : 0; // Avoid negative countdown
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [item]);

    const getTimeUnits = () => {
        const minutes = Math.floor(secondsLeft / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        return {
            days,
            hours: hours % 24,
            minutes: minutes % 60,
            seconds: secondsLeft % 60,
        };
    };

    const { hours, minutes, seconds } = getTimeUnits();

    const addLeadingZero = (t) => t < 10 ? `0${t}` : t;

    return (
        <div className={styles.mainWrapper}>
            {secondsLeft > 0 ? (
                <div>
                    <div>Claim your certificate within</div>
                    <div className={styles.timerWrapper}>
                        <div className={styles.hrsMinWrapper}>
                            <div className={styles.timeUnit}>{addLeadingZero(hours)}:{addLeadingZero(minutes)}</div>
                        </div>
                        <div className={styles.secondsWrapper}>
                            <div className={styles.timeUnit}>{addLeadingZero(seconds)}&nbsp;seconds</div>
                        </div>
                    </div>
                    <div className={styles.getNowBtn}>
                        {/* Uncomment and update the link if necessary */}
                        {/* <Link to="/workshop/certificate/admittance/355553" className={styles.getNowTxt}>Get Now</Link> */}
                    </div>
                </div>
            ) : (
                <div>
                    <div>The Workshop has ended!</div>
                    <div className={styles.soonBeAble}>You'll soon be able to download your certificate!</div>
                    <div className={styles.getNowBtn}>
                        {/* Uncomment and update the link if necessary */}
                        {/* <Link to="/workshop/certificate/admittance/355553" className={styles.getNowTxt}>Get Now</Link> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GCWCountdownTime;
