import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CgMenuRight } from "react-icons/cg";
import styles from './Navbar.module.css'
import MobileNavOptions from '../MobileNavOptions';
import AuthContext from '../../store/AuthContext';

const Navbar = ({ type }) => {
    const [isVisibleMobile, setIsVisibleMobile] = useState(false);
    const authCtx = useContext(AuthContext)

    const mobileNavVisibleHandler = (s) => {
        setIsVisibleMobile(s);
    };

    const isHome = type === "home";
    const navOptionClass = isHome ? styles.eachNavOptionWhite : styles.eachNavOptionBlack;


    useEffect(() => {
        if (isVisibleMobile) {
            document.body.classList.add("no-scroll")
        } else {
            document.body.classList.remove("no-scroll")
        }

    }, [isVisibleMobile])



    return (
        <div className={styles.mainHeaderWrapper}>
            <div className={styles.leftHeaderWrapper}>
                <Link to="/">
                    <img
                        src="https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/stylelink-small-logo.png"
                        className={styles.logo}
                        alt="stylelink logo"
                    />
                </Link>
            </div>
            <div className={styles.centerHeaderWrapper}>
                <div className={styles.navOptionsContainer}>
                    <Link to="/courses" className={styles.navOptionClass}>Courses</Link>
                    <Link to="/workshops" className={styles.navOptionClass}>Workshops</Link>
                    <Link to="/get-my-salon-resume" className={styles.navOptionClass}>Get Resume</Link>
                    <Link to="/jobs" className={styles.navOptionClass}>Find Jobs</Link>
                </div>
            </div>
            <div className={styles.rightHeaderWrapper}>
                <div className={styles.navOptionsContainer}>
                    {/* <Link to="/community" className={navOptionClass}>Community</Link> */}
                    {/* <Link to="/training" className={navOptionClass}>Training</Link> */}
                    {authCtx?.isLoggedIn === false ?
                        <Link to={'/solutions-for-businesses'} className={styles.forSalonBtn} >For Salons</Link>
                        :
                        <button onClick={() => authCtx.logout()} className={`${navOptionClass} ${isHome ? styles.signUpBtnHome : styles.signUpBtn}`}>Logout</button>
                    }
                </div>
                <div className={styles.navOptionsContainerCollapsible}>
                    {authCtx?.isLoggedIn === false ?
                        <Link to={'/solutions-for-businesses'} className={styles.forSalonBtn} >For Salons</Link>
                        :
                        <button onClick={() => authCtx?.logout()} className={`${styles.eachNavOption} ${styles.signUpBtn}`}>Logout</button>
                    }
                    <CgMenuRight color="black" size={24} className={styles.burgerMenu} onClick={() => mobileNavVisibleHandler(true)} />
                </div>
                {isVisibleMobile && <MobileNavOptions isVisible={isVisibleMobile} mobileNavVisibleHandler={mobileNavVisibleHandler} />}
            </div>
        </div>
    );
};

export default Navbar;
