export const capitalizeEachWord = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
        return '';
    }

    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}


export const sanitizePhoneNumber = (input) => {
    // Remove all non-digit characters (except '+' for the country code)
    const sanitizedInput = input.replace(/[^\d+]/g, '');

    // Check for empty or invalid input
    if (!sanitizedInput || sanitizedInput.length < 10) {
        return sanitizedInput;
    }

    // Check if the input starts with '+91' or '0' and remove the prefix
    const prefix = sanitizedInput.startsWith('+91') ? '+91' : sanitizedInput.startsWith('0') ? '0' : '';

    // Extract the number part and check if it's valid
    const numberPart = sanitizedInput.slice(prefix.length);
    if (/^[6-9]\d{9}$/.test(numberPart)) {
        return numberPart;
    }

    return sanitizedInput; // Return input as it is if not a valid number
}


export const hoursFromTime = (timeString) => {
    // If timeString is null, undefined, or an empty string, return null
    if (!timeString || typeof timeString !== "string" || timeString.trim() === "") {
        return null;
    }

    // Split the timeString by space to separate date and time
    const parts = timeString.split(" ");

    // If the timeString doesn't contain a time part, return null
    if (!parts[1]) {
        return null;
    }

    // Split the time part by colon to get hours, minutes, and seconds
    const timeParts = parts[1].split(":");

    // Extract hours part and convert it to a number
    let hours = parseInt(timeParts[0], 10); // ParseInt with radix 10

    // If hours is not a valid number, return null
    if (isNaN(hours)) {
        return null;
    }

    // If hours is less than 10 and has leading zero(s), remove leading zero(s)
    if (hours < 10 && timeParts[0].startsWith("0")) {
        return parseInt(timeParts[0], 10); // Convert back to integer without leading zero(s)
    }

    // Adjust hours if it's greater than 12
    if (hours > 12) {
        return hours - 12;
    } else {
        return hours;
    }
};




export const minutesFromTime = (timeString) => {
    // If timeString is null, undefined, or an empty string, return null
    if (!timeString || typeof timeString !== "string" || timeString.trim() === "") {
        return null;
    }

    // Split the timeString by space to separate date and time
    const parts = timeString.split(" ");

    // If the timeString doesn't contain a time part, return null
    if (!parts[1]) {
        return null;
    }

    // Split the time part by colon to get hours, minutes, and seconds
    const timeParts = parts[1].split(":");

    // Extract minutes part and convert it to a number
    let minutes = parseInt(timeParts[1], 10); // ParseInt with radix 10

    // If minutes is not a valid number, return null
    if (isNaN(minutes)) {
        return null;
    }

    // Add leading zero if minutes is less than 10
    return minutes < 10 ? "0" + minutes : String(minutes);
};



export const getMeridianFromTime = (timeString) => {
    // If timeString is null, undefined, or an empty string, return null
    if (!timeString || typeof timeString !== "string" || timeString.trim() === "") {
        return null;
    }

    // Split the timeString by space to separate date and time
    const parts = timeString.split(" ");

    // If the timeString doesn't contain a time part, return null
    if (!parts[1]) {
        return null;
    }

    // Split the time part by colon to get hours, minutes, and seconds
    const timeParts = parts[1].split(":");

    // Extract hours part and convert it to a number
    const hours = parseInt(timeParts[0], 10); // ParseInt with radix 10

    // If hours is not a valid number, return null
    if (isNaN(hours)) {
        return null;
    }

    // Determine whether it's AM or PM based on hours
    const meridian = hours < 12 ? "AM" : "PM";

    // Return the meridian
    return meridian;
};




export const formatDate = (timestamp) => {
    // If timestamp is null, undefined, or an empty string, return null
    if (!timestamp || typeof timestamp !== "string" || timestamp.trim() === "") {
        return null;
    }

    // Split the timestamp by space to separate date and time
    const parts = timestamp.split(" ");

    // If the timestamp doesn't contain a date part, return null
    if (!parts[0]) {
        return null;
    }

    // Split the date part by dash to get year, month, and day
    const dateParts = parts[0].split("-");

    // Extract year, month, and day parts
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    // Define month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Get month name from month number
    const monthName = monthNames[parseInt(month, 10) - 1]; // ParseInt with radix 10

    // Return formatted date string
    return `${monthName} ${day}, ${year}`;
};




export const getDayOfWeekFromDate = (timestamp) => {
    // If timestamp is null, undefined, or an empty string, return null
    if (!timestamp || typeof timestamp !== "string" || timestamp.trim() === "") {
        return null;
    }

    // Create a new Date object from the timestamp string
    const date = new Date(timestamp);

    // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const dayOfWeek = date.getDay();

    // Define an array of day names
    const dayNames = [
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ];

    // Return the corresponding day name
    return dayNames[dayOfWeek];
};



export const getTimeLeft = (startingTime) => {
    const startTime = new Date(startingTime);
    const currentTime = new Date();

    const timeDifference = startTime - currentTime;

    if (timeDifference <= 0) {
        return "The starting time has already passed.";
    }

    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const secondsLeft = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${daysLeft} days, ${hoursLeft} hours, ${minutesLeft} minutes, and ${secondsLeft} seconds`;
}

