import React from 'react'
import styles from '../Styles/Components/Checkbox.module.css'

const Checkbox = props => {
    let name = props.name;

    return (
        <label className={styles.container}>{name}
            <input type="checkbox" checked={props.isChecked} onChange={(e) => props.onChange(e, props.value)} value={props.value} />
            <span className={styles.checkmark}></span>
        </label>
    )
}

export default Checkbox