import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AAVHome from '../../../Components/AcademicAreaVisuals/AAVHome';
import PLPFAQWrapper from '../../../Components/Classroom/PLPFAQWrapper';
import OnlyCopyright from '../../../Components/Footers/OnlyCopyright';
import OurAlliesPLPClassroomCourses from '../../../Components/OurAllies/OurAlliesPLPClassroomCourses';
import styles from './MakeupPromotionalLandingPage.module.css'

const MakeupPromotionalLandingPage = () => {
    const titles = ['Be a celebrity make-up artist', 'Build a successful careers', 'Follow your passion'];
    const [visibleTitleIndex, setVisibleTitleIndex] = useState(0);

    const pageTitle = "Best make-up artist course in India : Stylelink"
    const pageDescription = "Best make-up course academy in Delhi NCR, India are being offered by Stylelink Academy in Noida with 100% job guarantee."
    const pageKeywords = "makeup artist courses, professional makeup artist class, courses makeup artist, makeup academy, make up artistry course, mua courses near me, makeup artist training near me, makeup academies in delhi, bridal makeup specialist"
    const faqs = [
        {
            question: "What makeup courses do you offer at Stylelink?",
            answer: "At Stylelink, we offer a wide range of makeup courses, including professional makeup artistry, bridal makeup, special effects makeup, and more."
        },
        {
            question: "Why should I choose Stylelink Academy for my makeup artist training?",
            answer: "There are many reasons why you should choose Stylelink Academy for your makeup artist training. We offer - A highly educated team of trainers/educators/teachers - An internationally recognized curriculum - Guaranteed job placement - A luxurious and state-of-the-art academy - A strong reputation in the beauty industry"
        },
        {
            question: "Where is Stylelink Academy located?",
            answer: "Stylelink Academy is situated in Noida, Sector 117, one of the most luxurious academy in the city."
        },
        {
            question: "What sets your makeup courses apart from others in the market?",
            answer: "Our makeup courses are designed to meet international standards, making our students job/industry-ready on an international level. Our curriculum is tailored to ensure excellence in makeup artistry."
        },
        {
            question: "Do you offer job placement assistance for your students?",
            answer: "We don't just offer assistance; we guarantee job placement for our graduates. With a track record of six years in the industry, we have strong relationships with leading brands in salon and beauty industry."
        },
        {
            question: "Can international students enroll in your makeup courses?",
            answer: "Absolutely! Our courses are open to both local and international students who seek to master the art of makeup and build a successful career in the beauty industry."
        },
        {
            question: "How long are your makeup courses, and what is the class schedule?",
            answer: "The duration and class schedule of our courses vary, and you can find the details for each course on our website or by contacting our admissions team."
        },
        {
            question: "Are there any prerequisites for enrolling in your makeup courses?",
            answer: "No, there are no specific prerequisites. Our courses are suitable for beginners as well as those with some prior makeup experience."
        },
        {
            question: "What materials and tools do I need for the makeup courses?",
            answer: "We provide a list of required materials and tools for each course. Our team can also assist you in acquiring them at the best prices."
        },
        {
            question: "How can I apply for a course at Stylelink Academy?",
            answer: "You can apply for our makeup courses by visiting our website and filling out the online application form. Alternatively, you can contact our admissions team for guidance on the application process."
        },
        {
            question: "What is the cost of your makeup courses, and do you offer any financial assistance?",
            answer: "The cost of our courses varies depending on the program. We do offer financial assistance and scholarships to eligible students. You can find more details on our website or by speaking with our admissions team."
        }
    ]

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisibleTitleIndex((prevIndex) =>
                prevIndex < titles.length - 1 ? prevIndex + 1 : 0
            );
        }, 3000); // Adjust the delay between titles as needed

        return () => {
            clearTimeout(timer);
        };
    }, [visibleTitleIndex]);

    const [ourAllies, setOurAllies] = useState({
        title: "Our makeup allies",
        subTitle: "Over 50+ International & luxurious brands",
        rows: [
            [
                {
                    brandName: "Estee Lauder 1",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Armani-Beauty.png"
                },
                {
                    brandName: "Estee Lauder 2",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Bobby-Brown.png"
                },
                {
                    brandName: "Estee - 0 Lauder 3",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Charlotte-Tilbury.png"
                },
                {
                    brandName: "Estee Lauder 4",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Christian-DIOR.png"
                },
                {
                    brandName: "Estee Lauder 5",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/CLE-DE-PEAU-BEAUTE.png"
                },
                {
                    brandName: "Estee Lauder 6",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Clinique.png" 
                },
                {
                    brandName: "Elizabeth Arden",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Elizabeth-Arden.png"
                },
                {
                    brandName: "Estee Lauder",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Estee-Lauder-Emblem.png"
                },
                {
                    brandName: "Giorgio Armani",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Giorgio-Armani.png"
                },
                {
                    brandName: "Givenchy",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Givenchy.png"
                },
                {
                    brandName : "Hermes",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Hermes.png"
                }
            ],
            [
                {
                    brandName: "Guerlain",
                    logo: "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/guerlain.png"
                },
                {
                    brandName : "",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/La-Prairie-Makeup.png"
                },
                {
                    brandName : "Lancome",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Lancome.png"
                },
                {
                    brandName : "Lar Mer",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Lar-Mer.png"
                },
                {
                    brandName : "MAC Cosmetics",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/MAC-Cosmetics.png"
                },
                {
                    brandName : "Maybelline",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Maybelline.png"
                },
                {
                    brandName : "Oriflame",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Oriflame-Emblem.png"
                },
                {
                    brandName : "Pat McGrath LABS",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Pat-McGrath-LABSd.png"
                },
                {
                    brandName : "",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Shiseido.png"
                },
                {
                    brandName : "",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Sisley-Paris.png"
                },
                {
                    brandName : "Urban Decay",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Urban-Decay.png"
                },
                {
                    brandName : "Westmen Atelier",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/Westmen-Atelier.png"
                },
                {
                    brandName : "YSL",
                    logo : "https://stylelink.s3.ap-south-1.amazonaws.com/makeup-brands/YSL-Beauty.png"
                }
            ]
        ]
    }
    )

    return (
        <div className={styles.mainWrapper}>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription}></meta>
                <meta name="keywords" content={pageKeywords} ></meta>

                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="whatsapp:url" content={`https://stylelink.in/classroom-courses/make-up-courses`} />
                <meta name="whatsapp:title" content={pageTitle} />
                <meta name="whatsapp:description" content={pageDescription} />
                <meta name="whatsapp:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="telegram:url" content={`https://stylelink.in/classroom-courses/make-up-courses`} />
                <meta name="telegram:title" content={pageTitle} />
                <meta name="telegram:description" content={pageDescription} />
                <meta name="telegram:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />


                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                                "@type": "Question",
                                "name": "${faqs[0]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[0].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[1]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[1].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[2]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[2].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[3]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[3].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[4]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[4].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[5]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[5].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[6]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[6].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[7]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[7].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[8]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[8].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[9]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[9].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[10]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[10].answer}"
                                }
                            }
                        ]
                        }
                    `}
                </script>



            </Helmet>
            <div className={styles.firstPaintWrapper}>
                <div className={styles.backgroundImageWrapper}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/lips-pigmentation.png" />
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.headerWrapper}>
                        <Link to="/classroom-courses">
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/stylelink-high-logo.png" />
                        </Link>
                    </div>
                    <div className={styles.bodyWrapper}>
                        <div className={styles.titleContainer}>
                            <div className={styles.titles}>
                                {titles.map((title, index) => (
                                    <div
                                        key={index}
                                        className={visibleTitleIndex === index ? styles.titleVisible : styles.title}
                                    >
                                        <h1>{title}</h1>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={styles.subTitleContainer}>
                            <h2>
                                With India's most disciplined make-up academy
                            </h2>
                        </div>
                        <div className={styles.uspWrapper}>
                            <div className={styles.eachUsp}><h3><FaChevronRight size={14} />&nbsp;100% placement guaranteed</h3></div>
                            <div className={styles.eachUsp}><h3><FaChevronRight size={14} />&nbsp;Absolute 0% interest rate loan facility</h3></div>
                            <div className={styles.eachUsp}><h3><FaChevronRight size={14} />&nbsp;Internationally designed curriculum</h3></div>
                            <div className={styles.eachUsp}><h3><FaChevronRight size={14} />&nbsp;In-salon trainings / internships</h3></div>
                        </div>

                        <div className={styles.ctaWrapper}>
                            <Link to="/classroom-courses/make-up-courses/enroll-now">
                                <div className={styles.enrollNowBtn}>
                                    Enroll Now
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>

            <AAVHome />

            <OurAlliesPLPClassroomCourses ourAllies={ourAllies} />

            <PLPFAQWrapper faqs={faqs} />
            <OnlyCopyright />
        </div>
    )
}

export default MakeupPromotionalLandingPage