import React from 'react'
import styles from '../Styles/Components/Footer.module.css'

import { FaFacebookSquare, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={styles.mainContainer} id="homeFooter">
            <div className={styles.logoContainer}>
                <img
                    // src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657715908/stylelink-logo1-black-just-lokaci-707x91_pp5jdn.png" 
                    src="https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/stylelink-small-logo.png"
                />
            </div>
            <div className={styles.containersWrapper}>
                <div className={styles.leftContainer}>
                    <div className={styles.colFooterContainer}>
                        <div className={styles.eachColFooter}>
                            <div className={styles.eachColFTitle}>
                                Stylelink
                            </div>
                            <div className={styles.eachColFContent}>
                                <Link to="/about" className={styles.eachCFCR}>
                                    About Us
                                </Link>
                                {/* <Link to="/sitemap" className={styles.eachCFCR}>
                                    Sitemap
                                </Link> */}
                                <Link to="/privacy" className={styles.eachCFCR}>
                                    Privacy Policy
                                </Link>
                                <Link to="/terms-of-use" className={styles.eachCFCR}>
                                    Terms of Use
                                </Link>
                                <Link to="/refund-policy" className={styles.eachCFCR}>
                                    Refund Policy
                                </Link>
                                <Link to="" className={styles.eachCFCR}>
                                    Careers
                                </Link>
                                <Link to="" className={styles.eachCFCR}>
                                    FAQs
                                </Link>
                            </div>
                        </div>
                        <div className={styles.eachColFooter}>
                            <div className={styles.eachColFTitle}>
                                App
                            </div>
                            <div className={styles.eachColFContent}>
                                <div className={styles.eachCFCR}>
                                    Download App
                                </div>
                                <div className={styles.eachCFCR}>
                                    Trouble Downloading App
                                </div>
                                <div className={styles.eachCFCR}>
                                    Trouble Signing Into App
                                </div>
                                <div className={styles.eachCFCR}>
                                    Request Usage Demo
                                </div>
                                <div className={styles.eachCFCR}>
                                    Report Issues
                                </div>
                                <div className={styles.eachCFCR}>
                                    Contact Support
                                </div>
                            </div>
                        </div>
                        <div className={styles.eachColFooter}>
                            <div className={styles.eachColFTitle}>
                                For Businesses
                            </div>
                            <div className={styles.eachColFContent}>
                                <Link to="/hire-salon-staff" className={styles.eachCFCR}>
                                    Hire Salon Staff
                                </Link>
                                {/* <div className={styles.eachCFCR}>
                                    SLCs
                                </div> */}
                                <Link to="/train-your-salon-staff" className={styles.eachCFCR}>
                                    Train Your Staff
                                </Link>
                                <Link to="/our-presence" className={styles.eachCFCR}>
                                    Our Presence
                                </Link>
                                <Link to="/hire-salon-staff/pricing" className={styles.eachCFCR}>
                                    Pricing
                                </Link>
                                <a href="https://fast-track.stylelink.in" target="_blank" className={styles.eachCFCR}>
                                    FastTrack
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.eachColFTitle}>SUBSCRIBE</div>
                    <div className={styles.eachColFContent}>
                        <div className={styles.eachCFCR}>
                            Sign Up to receive stylelink news & updates
                        </div>
                        <div className={styles.eachCFCR}>
                            E-mail
                        </div>
                        <div className={styles.eachCFCR}>
                            <div className={styles.eachCFCRInputContainer}>
                                <input className={styles.eachCFCRInput} />
                                <button className={styles.eachCFCRBtn} >Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.socialRow}>
                <a href="https://instagram.com/stylelink.in" target="_blank">
                    <FaInstagram size={30} className={styles.eachSocial} />
                </a>
                <a href="https://www.linkedin.com/company/stylelink/" target="_blank" >
                    <FaLinkedinIn size={28} className={styles.eachSocial} />
                </a>
                <a href="https://www.facebook.com/stylelink.in" target="_blank">
                    <FaFacebookSquare size={28} className={styles.eachSocial} />
                </a>
            </div>
            <div className={styles.copyrightContainer}>
                © 2022 stylelink | All Rights Reserved
            </div>

        </div>
    )
}

export default Footer