import React, { useState } from 'react'
import { CgChevronDown, CgChevronUp } from 'react-icons/cg'
import styles from './HireSalonStaffHomeEachFAQ.module.css'

const HireSalonStaffHomeEachFAQ = props => {
    const { item } = props
    const [isOpen, setIsOpen] = useState(true)
    return (
        <div className={styles.eachWrapper} onClick={() => setIsOpen(!isOpen)}>
            <div className={styles.questionRow} >
                <h4>{item?.question}</h4>
                {isOpen ? <CgChevronUp size={26} /> : <CgChevronDown size={26} />}
            </div>
            {isOpen &&
                <div className={styles.answer}>
                    {item?.answer}
                </div>
            }
        </div>
    )
}

export default HireSalonStaffHomeEachFAQ