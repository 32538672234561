import React, { useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import EachTopJob from './EachTopJob'
import styles from './TopJobs.module.css'

const TopJobs = () => {
    const [topJobs, setTopJobs] = useState([1, 2, 3])

    const getTopJobs = async () => {
        const getTopJobsResponse = await fetch(BASE_URL_API+"/getTopJobs",
        {
            method : "POST",
            body : JSON.stringify({
                
            })
        });

        if(!getTopJobsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getTopJobsRespo = await getTopJobsResponse.json()
            if(getTopJobsRespo.status==="success") {
                setTopJobs(getTopJobsRespo?.response)
            } else {
                setTopJobs([])
            }
            console.log(getTopJobsRespo)
        }
    }


    useEffect(() => {
        getTopJobs()
    }, [])
    

    return (
        <div className={styles.topJobsWrapper}>
            <div className={styles.topJobTitle}>
                <h2>Top Jobs on Stylelink</h2>
            </div>
            <div className={styles.topJobsContainer}>
                {topJobs?.map((item, index) => (
                    <EachTopJob key={index} item={item} />
                ))}
            </div>
        </div>
    )
}

export default TopJobs