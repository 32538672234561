import React, { useEffect, useState } from 'react'
import Checkbox from '../Components/Checkbox'
import EachJobContainer from '../Components/EachJobContainer'
import JobSearchSection from '../Components/JobSearchSection'
import UserLoginCard from '../Components/UserLoginCard'
import NavbarHandler from '../Helper/NavbarHandler'
import { BASE_URL_API } from '../References/urls'
import styles from '../Jobs/JobsListing.module.css'

import Loader from "react-js-loader";


import { ShimmerThumbnail } from "react-shimmer-effects";
import { FaFilter } from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga';
import MissedCallCard from '../Components/MissedCallCard'

const width = window.innerWidth;
const JobsListing = () => {
    const [jobsData, setJobsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingJobCategories, setLoadingJobCategories] = useState(false);

    const [jobTypeWiseOpenings, setJobTypeWiseOpenings] = useState(null);
    const [categoryWiseOpenings, setcategoryWiseOpenings] = useState(null);
    const [salaryWiseOpenings, setSalaryWiseOpenings] = useState(null)

    const [showFilter, setShowFilter] = useState(false)

    const [jobCategories, setJobCategories] = useState([
        {
            "label": "Beautician",
            "categoryId": 1
        },
        {
            "label": "Hair-dresser",
            "categoryId": 2
        },
        {
            "label": "Mehandi Artist",
            "categoryId": 3
        },
        {
            "label": "Nail Artist",
            "categoryId": 4
        }
    ])
    // const [salaryRange, setSalaryRange] = useState(['Below 10000', '10000 - 15000', '15001 - 20000', '20001 - 25000', '25001 - 35000', 'Above 35000'])
    const salaryRange = ['Below 10000', '10000 - 15000', '15001 - 20000', '20001 - 25000', '25001 - 35000', 'Above 35000']



    const [selectedJobTypes, setSelectedJobTypes] = useState([])
    const [selectedJobCategory, setSelectedJobCategory] = useState([])
    const [selectedSalaryRange, setSelectedSalaryRange] = useState([])
    const [selectedSorting, setSelectedSorting] = useState('Newest');

    const checkBoxHandler = (e, v, t) => {
        t === 'jobType' && e ? setSelectedJobTypes([...selectedJobTypes, v]) : setSelectedJobTypes(selectedJobTypes.filter((ps) => ps !== v))
        t === 'jobCategory' && e ? setSelectedJobCategory([...selectedJobCategory, v]) : setSelectedJobCategory(selectedJobCategory.filter((ps) => ps !== v))
        t === 'salaryRange' && e ? setSelectedSalaryRange([...selectedSalaryRange, v]) : setSelectedSalaryRange(selectedSalaryRange.filter((ps) => ps !== v))

    }

    const clearAllFiltersHandler = () => {
        setSelectedJobTypes([]);
        setSelectedJobCategory([]);
        setSelectedSalaryRange([]);
    }

    const sortingHandler = (v) => {
        setSelectedSorting(v);
    }
    useEffect(() => {
        getJobCategories();

        ReactGA.pageview(window.location.pathname);
    }, [])

    useEffect(() => {
        getAllJobs();
    }, [selectedSalaryRange, selectedSorting, selectedJobTypes, selectedJobCategory])


    // console.log(selectedJobTypes)
    // console.log(selectedJobCategory)
    // console.log(selectedSalaryRange)

    // const findMinMax = () => {
    //     let salaryArr = [];
    //     let min = '';
    //     let max = '';
    //     selectedSalaryRange.map((item, index) => {
    //         if(item.includes("Below")) {
    //             let p = item.split(" ");
    //             let valMin = 0;
    //             let valMax = p[1].trim(); 
    //             salaryArr.push(parseInt(valMin));
    //             salaryArr.push(parseInt(valMax));

    //         } else if(item.includes("Above")) {
    //             let p = item.split(" ");
    //             let valMin = p[1].trim();
    //             let valMax = 900000;
    //             salaryArr.push(parseInt(valMin));
    //             salaryArr.push(parseInt(valMax));

    //         } else {
    //             let p = item.split("-");
    //             let valMin = p[0].trim();
    //             let valMax = p[1].trim(); 
    //             salaryArr.push(parseInt(valMin));
    //             salaryArr.push(parseInt(valMax));
    //         }
    //     }) 
    //     salaryArr = salaryArr.sort();
    //     console.log(salaryArr);

    //     console.log("Min : ",salaryArr[0], "Max : ",salaryArr[salaryArr.length -1]);
    // }

    const getJobCategories = async () => {
        setLoadingJobCategories(true);
        const getJobCategoriesResponse = await fetch(BASE_URL_API + "/getJobCategories",
            {
                method: "POST",
                body: JSON.stringify({

                })
            });

        if (!getJobCategoriesResponse.ok) {
            alert("Something went wrong : Server Error, please try again later!");
        } else {
            const getJobCategoriesRespo = await getJobCategoriesResponse.json();
            if (getJobCategoriesRespo.status === "success") {
                setJobCategories(getJobCategoriesRespo.response)
            } else {
                console.log(getJobCategoriesRespo.response);
            }
        }
        setLoadingJobCategories(false);
    }

    const getAllJobs = async () => {
        setIsLoading(true);
        const getAllJobsResponse = await fetch(BASE_URL_API + "/getAllJobsNew",
            {
                method: "POST",
                body: JSON.stringify({
                    openingType: selectedJobTypes,
                    jobCategoryId: selectedJobCategory,
                    salaryRange: selectedSalaryRange,
                    sorting: selectedSorting
                })
            });

        if (!getAllJobsResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const getAllJobsRespo = await getAllJobsResponse.json();
            setJobsData(getAllJobsRespo.response);
            if (getAllJobsRespo.response !== null) {
                setcategoryWiseOpenings(getAllJobsRespo.message.categoryWise);
                setJobTypeWiseOpenings(getAllJobsRespo.message.jobTypeWise);
                setSalaryWiseOpenings(getAllJobsRespo.message.salaryWise);
            } else {
                setcategoryWiseOpenings(null);
                setJobTypeWiseOpenings(null);
                setSalaryWiseOpenings(null);
                console.log(getAllJobsRespo.response);
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if(showFilter){
            document.body.style.overflowY = "hidden"
        } else {
            document.body.style.overflowY = "visible"
        }
    }, [showFilter])
    


    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Get Salon jobs from all over India with Stylelink</title>
                <meta name="description" content="India's first and only platform dedicated to salon professionals."></meta>
            </Helmet>
            <NavbarHandler type={width < 600 ? "home" : ''} />
            <div className={styles.mainBodyWrapper}>
                <div className={styles.mainBody}>
                    <section className={styles.filterSectionWrapper}>
                        <div className={styles.filterSection}>
                            <div className={styles.jobFilterTitleBox}>
                                <div className={styles.jobFilterTitle}>
                                    Job Filters
                                </div>
                                <div className={styles.clearAllBtn}>
                                    <button onClick={() => clearAllFiltersHandler()}>Clear All</button>
                                </div>
                            </div>

                            <div className={styles.eachFilterBoxContainer}>
                                <div className={styles.eachFilterBoxHeader}>
                                    <div className={styles.filterBoxTitle}>
                                        Job Type
                                    </div>
                                    <div className={styles.filterBoxAction}>
                                        <button onClick={() => setSelectedJobTypes([])}>Clear</button>
                                    </div>
                                </div>

                                <div className={styles.eachFilterBoxBody}>
                                    <div className={styles.checkBoxContainer}>
                                        <Checkbox name="Full-Time" category="jobType" isChecked={selectedJobTypes.includes("Full-Time")} value="Full-Time" onChange={(e, v) => checkBoxHandler(e.target.checked, v, "jobType")} />
                                        <div className={styles.filterStats}>
                                            {jobTypeWiseOpenings != null &&
                                                jobTypeWiseOpenings.map((itemCo, indexCo) => (
                                                    itemCo.openingType === 'Full-Time' && itemCo.openings
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.checkBoxContainer}>
                                        <Checkbox name="Internship" category="jobType" isChecked={selectedJobTypes.includes("Internship")} value="Internship" onChange={(e, v) => checkBoxHandler(e.target.checked, v, "jobType")} />
                                        <div className={styles.filterStats}>
                                            {jobTypeWiseOpenings != null &&
                                                jobTypeWiseOpenings.map((itemCo, indexCo) => (
                                                    itemCo.openingType === 'Internship' && itemCo.openings
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.checkBoxContainer}>
                                        <Checkbox name="Commission" category="jobType" isChecked={selectedJobTypes.includes("Commission")} value="Commission" onChange={(e, v) => checkBoxHandler(e.target.checked, v, "jobType")} />
                                        <div className={styles.filterStats}>
                                            {jobTypeWiseOpenings != null &&
                                                jobTypeWiseOpenings.map((itemCo, indexCo) => (
                                                    itemCo.openingType === 'Commission' && itemCo.openings
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className={styles.eachFilterBoxContainer}>
                                <div className={styles.eachFilterBoxHeader}>
                                    <div className={styles.filterBoxTitle}>
                                        Job Profile
                                    </div>
                                    <div className={styles.filterBoxAction}>
                                        <button onClick={() => setSelectedJobCategory([])}>Clear</button>
                                    </div>
                                </div>
                                {loadingJobCategories === true ?
                                    <div>
                                        <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                                    </div>
                                    :
                                    <>
                                        {jobCategories !== null &&
                                            <div className={styles.eachFilterBoxBody}>
                                                {jobCategories.map((item, index) => (
                                                    <div className={styles.checkBoxContainer} key={index}>
                                                        <Checkbox name={item.label} category="jobCategory" isChecked={selectedJobCategory.includes(item.categoryId)} value={item.categoryId} onChange={(e, v) => checkBoxHandler(e.target.checked, v, 'jobCategory')} />
                                                        <div className={styles.filterStats}>
                                                            {categoryWiseOpenings !== null &&
                                                                categoryWiseOpenings.map((itemCo, indexCo) => (
                                                                    itemCo.categoryId == item.categoryId && itemCo.openings
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </>
                                }
                            </div>

                            <div className={styles.eachFilterBoxContainer}>
                                <div className={styles.eachFilterBoxHeader}>
                                    <div className={styles.filterBoxTitle}>
                                        Salary Range
                                    </div>
                                    <div className={styles.filterBoxAction}>
                                        <button onClick={() => setSelectedSalaryRange([])}>Clear</button>
                                    </div>
                                </div>
                                {salaryRange !== null &&
                                    <div className={styles.eachFilterBoxBody}>
                                        {salaryRange.map((item, index) => (
                                            <div className={styles.checkBoxContainer} key={index}>
                                                <Checkbox name={item} category="salaryRange" isChecked={selectedSalaryRange.includes(item)} value={item} onChange={(e, v) => checkBoxHandler(e.target.checked, v, 'salaryRange')} />
                                                <div className={styles.filterStats}>
                                                    {salaryWiseOpenings !== null &&
                                                        salaryWiseOpenings.map((itemCo, indexCo) => (
                                                            itemCo.salaryRange === item && itemCo.openings
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>


                        </div>
                    </section>
                    <section className={styles.mainSection}>
                        <JobSearchSection
                            resultCount={jobsData !== null && jobsData.length} isLoading={isLoading}
                            sortingHandler={sortingHandler}
                        />
                        {isLoading === true ?
                            <div>
                                <div className={styles.eachJobLoader}>
                                    <ShimmerThumbnail height={300} width="100%" className="m-0" rounded />
                                </div>
                                <div className={styles.eachJobLoader}>
                                    <ShimmerThumbnail height={300} width="100%" className="m-0" rounded />
                                </div>
                            </div>
                            :
                            <>
                                {jobsData !== null ?
                                    jobsData.map((item, index) => (
                                        <EachJobContainer data={item} key={index} />
                                    ))
                                    :
                                    <div>No results found</div>
                                }
                            </>
                        }


                    </section>

                    <section className={styles.rightSection}>
                        <UserLoginCard />

                        <MissedCallCard />
                    </section>
                </div>
            </div>

            <div className={styles.mainBodyWrapperMobile}>
                <div className={styles.mh}>
                    <div className={styles.sb}>
                        <input placeholder="Search for jobs..." />
                    </div>
                    <div className={styles.sf}>
                        <FaFilter onClick={() => setShowFilter(true)} />
                    </div>
                </div>
                <div className={styles.mbmWrap}>
                    <div className={styles.mbmC}>
                        {isLoading === true ?
                            <div>
                                <div className={styles.eachJobLoader}>
                                    <ShimmerThumbnail height={300} width="100%" className="m-0" rounded />
                                </div>
                                <div className={styles.eachJobLoader}>
                                    <ShimmerThumbnail height={300} width="100%" className="m-0" rounded />
                                </div>
                            </div>
                            :
                            <>
                                {jobsData !== null ?
                                    jobsData.map((item, index) => (
                                        <EachJobContainer data={item} key={index} />
                                    ))
                                    :
                                    <div className={styles.noResultsFound}>
                                        <p>No results found</p>
                                        <p>Try removing some filters, or try again later!</p>
                                    </div>
                                }
                            </>
                        }

                        <div>
                            <MissedCallCard />
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className={showFilter === true ? styles.showFilter : styles.filterWrapper}>
                    <div className={styles.filterContainer}>
                        <div className={styles.fhW}>
                            <div className={styles.jobFilterTitleBoxM}>
                                <div className={styles.jobFilterTitle}>
                                    Job Filters
                                </div>
                                <div className={styles.sortingContainer}>
                                    <select onChange={(e) => sortingHandler(e.target.value)}>
                                        <option value="Newest">Newest</option>
                                        <option value="Salary : Lowest To Highest">Salary : Lowest To Highest</option>
                                        <option value="Salary : Highest To Lowest">Salary : Highest To Lowest</option>
                                        <option value="Earliest Joining Date">Earliest Joining Date</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={styles.fbW}>
                            <div className={styles.fbWContainer}>
                                <div className={styles.eachFilterBoxContainer}>
                                    <div className={styles.eachFilterBoxHeader}>
                                        <div className={styles.filterBoxTitle}>
                                            Job Type
                                        </div>
                                        <div className={styles.filterBoxAction}>
                                            <button onClick={() => setSelectedJobTypes([])}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>

                                    <div className={styles.eachFilterBoxBody}>
                                        <div className={styles.checkBoxContainer}>
                                            <Checkbox name="Full-Time" isChecked={selectedJobTypes.includes("Full-Time")} category="jobType" value="Full-Time" onChange={(e, v) => checkBoxHandler(e.target.checked, v, "jobType")} />
                                            <div className={styles.filterStats}>
                                                {jobTypeWiseOpenings !== null &&
                                                    jobTypeWiseOpenings.map((itemCo, indexCo) => (
                                                        itemCo.openingType === 'Full-Time' && itemCo.openings
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.checkBoxContainer}>
                                            <Checkbox name="Internship" isChecked={selectedJobTypes.includes("Internship")} category="jobType" value="Internship" onChange={(e, v) => checkBoxHandler(e.target.checked, v, "jobType")} />
                                            <div className={styles.filterStats}>
                                                {jobTypeWiseOpenings !== null &&
                                                    jobTypeWiseOpenings.map((itemCo, indexCo) => (
                                                        itemCo.openingType === 'Internship' && itemCo.openings
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.checkBoxContainer}>
                                            <Checkbox name="Commission" isChecked={selectedJobTypes.includes("Commission")} category="jobType" value="Commission" onChange={(e, v) => checkBoxHandler(e.target.checked, v, "jobType")} />
                                            <div className={styles.filterStats}>
                                                {jobTypeWiseOpenings !== null &&
                                                    jobTypeWiseOpenings.map((itemCo, indexCo) => (
                                                        itemCo.openingType === 'Commission' && itemCo.openings
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={styles.eachFilterBoxContainer}>
                                    <div className={styles.eachFilterBoxHeader}>
                                        <div className={styles.filterBoxTitle}>
                                            Job Profile
                                        </div>
                                        <div className={styles.filterBoxAction}>
                                            <button onClick={() => setSelectedJobCategory([])}>Clear</button>
                                        </div>
                                    </div>
                                    {loadingJobCategories === true ?
                                        <div>
                                            <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                                        </div>
                                        :
                                        <>
                                            {jobCategories !== null &&
                                                <div className={styles.eachFilterBoxBody}>
                                                    {jobCategories.map((item, index) => (
                                                        <div className={styles.checkBoxContainer} key={index}>
                                                            <Checkbox name={item.label} isChecked={selectedJobCategory.includes(item.categoryId)} category="jobCategory" value={item.categoryId} onChange={(e, v) => checkBoxHandler(e.target.checked, v, 'jobCategory')} />
                                                            <div className={styles.filterStats}>
                                                                {categoryWiseOpenings !== null &&
                                                                    categoryWiseOpenings.map((itemCo, indexCo) => (
                                                                        itemCo.categoryId == item.categoryId && itemCo.openings
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>

                                <div className={styles.eachFilterBoxContainer}>
                                    <div className={styles.eachFilterBoxHeader}>
                                        <div className={styles.filterBoxTitle}>
                                            Salary Range
                                        </div>
                                        <div className={styles.filterBoxAction}>
                                            <button onClick={() => setSelectedSalaryRange([])}>Clear</button>
                                        </div>
                                    </div>
                                    {salaryRange !== null &&
                                        <div className={styles.eachFilterBoxBody}>
                                            {salaryRange.map((item, index) => (
                                                <div className={styles.checkBoxContainer} key={index}>
                                                    <Checkbox name={item} category="salaryRange" isChecked={selectedSalaryRange.includes(item)} value={item} onChange={(e, v) => checkBoxHandler(e.target.checked, v, 'salaryRange')} />
                                                    <div className={styles.filterStats}>
                                                        {salaryWiseOpenings !== null &&
                                                            salaryWiseOpenings.map((itemCo, indexCo) => (
                                                                itemCo.salaryRange === item && itemCo.openings
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            {isLoading === true &&
                                <div className={styles.fmlW}>
                                    <div className={styles.fmlC}>
                                        <Loader type="spinner-default" bgColor={"#FFFFFF"} title={"Loading jobs..."} color={'#FFFFFF'} size={100} />
                                    </div>
                                </div>
                            }


                        </div>
                        {/* <div className={styles.spacer}></div> */}
                        <div className={styles.ffW}>
                            <div className={styles.ffC}>
                                <button className={styles.cfb} onClick={() => setShowFilter(false)}>Close</button>
                                <button className={styles.afb} onClick={() => setShowFilter(false)}>View {jobsData != null && jobsData.length} Jobs</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default JobsListing