import React from 'react'
import styles from '../Workshops/GCWWorkshopFAQs.module.css'

const GCWWorkshopFAQs = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainTitle}>
                    Workshop Related FAQs
                </div>
                <div className={styles.eachQuery}>
                    <div className={styles.queryQuestion}>
                        <h2>
                            How can I attend this workshop?
                        </h2>
                    </div>
                    <div className={styles.queryAnswer}>
                        <p>
                            Please download the stylelink app to attend the workshop.
                        </p>
                    </div>
                </div>

                <div className={styles.eachQuery}>
                    <div className={styles.queryQuestion}>
                        <h2>
                            Is pre-registration mandatory for this workshop to attend?
                        </h2>
                    </div>
                    <div className={styles.queryAnswer}>
                        <p>
                            Yes, pre-registration is mandatory.
                        </p>
                    </div>
                </div>

                <div className={styles.eachQuery}>
                    <div className={styles.queryQuestion}>
                        <h2>
                            Am I eligible to get the certificate?
                        </h2>
                    </div>
                    <div className={styles.queryAnswer}>
                        <p>
                            Yes, if you have pre-registered for this workshop.
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default GCWWorkshopFAQs