import React, { useState } from 'react'
import styles from '../Workshops/EachWorkshopGallery.module.css'

const EachWorkshopGallery = props => {
    const [content, setContent] = useState([])
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.galleryHeader}>
                    <h2>Workshop BTS</h2>
                    <p>Watch the after workshop pics/videos of final look.</p>
                </div>
                {content.length < 1 ?
                    <div className={styles.eachRow}>
                        <div>Photos and videos of this workshop will be released soon!</div>
                    </div>
                    :
                    <div className={styles.eachRow}>
                        <div className={styles.squareWrapper}>
                            <img
                                src="https://cdn0.weddingwire.in/vendor/8200/original/1920/jpg/bridal-makeup-rifas-hairdo-makeup-bridal-makeup-11_15_368200-162254122642611.webp"
                            />
                        </div>
                        <div className={styles.landscapeHolder}>
                            <div className={styles.landscapeWrapper}>
                                <img src="https://r1imghtlak.mmtcdn.com/fd83c85ca8c311eb8a1a0242ac110003.jpg" />
                            </div>
                            <div className={styles.landscapeWrapper}>
                                <img src="https://r1imghtlak.mmtcdn.com/346e5760f69311e7bb02025f77df004f.jpg" />
                            </div>
                        </div>
                        <div className={styles.portraitWrapper}>
                            <img
                                src="https://i.pinimg.com/736x/45/fe/f4/45fef43d564f8debeeb6a316e17f2244.jpg"
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default EachWorkshopGallery