import React from 'react'
import { useLottie } from 'lottie-react';
import ResumeAnimation from '../Json/ResumeAnimation.json'

const ResumeAI = () => {
    const options = {
        animationData: ResumeAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default ResumeAI