import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../Workshops/GCWCertificateAssessmentResult.module.css'

const GCWCertificateAssessmentResult = () => {

    return (
        <div>GCWCertificateAssessmentResult



            <div>
                <Link to={`/workshop/certificate/sdfkyel`}>
                    Generate Certificate 
                </Link>
            </div>
        </div>
    )
}

export default GCWCertificateAssessmentResult