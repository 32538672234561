import React, { useState, useEffect } from "react";
import Navbar from "../Components/UI/Navbar";
import styles from './NavbarHandler.module.css'

const NavbarHandler = ({ type }) => {
  const [show, setShow] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const newScrollPos = document.body.getBoundingClientRect().top;
      setShow(newScrollPos > scrollPos);
      setScrollPos(newScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPos]);

  return (
    <>
      <nav className={
        show ? (scrollPos < -200 ? styles.activeBg : type === "home" ? styles.activeHome : styles.active) : styles.hidden
      }>
        <Navbar type={type} />
      </nav>
    </>
  );
};

export default NavbarHandler;