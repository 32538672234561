import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaCertificate, FaCheckCircle, FaCheckDouble, FaPhotoVideo, FaProductHunt, FaQuestion, FaStickyNote, FaUserCircle } from 'react-icons/fa'
import { GrCertificate } from 'react-icons/gr'
import { useLocation, useNavigate } from 'react-router'
import { ShimmerBadge, ShimmerButton, ShimmerCircularImage, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'
import { toast } from 'react-toastify'
import NavbarHandler from '../../Helper/NavbarHandler'
import { getTimeLeft } from '../../Helper/SupportingAlgos'
import Timer from '../../Helper/Timer'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/AuthContext'
import styles from './RegisterForWorkshopIndividual.module.css'

const RegisterForWorkshopIndividual = (props) => {
    const nav = useNavigate()
    const locate = useLocation()
    const { workshopIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingUserData, setIsLoadingUserData] = useState(false)

    const [hasAlreadyRegistered, setHasAlreadyRegistered] = useState(false)
    const [isPaid, setIsPaid] = useState(true)
    const [workshopData, setWorkshopData] = useState(null)
    const [userData, setUserData] = useState(null)
    const [isRegistering, setIsRegistering] = useState(false)
    const [initiatingPayment, setInitiatingPayment] = useState(false)


    const getWorkshopDetailsForRegistration = async () => {
        const getWorkshopDetailsForRegistrationResponse = await fetch(BASE_URL_API + "/getWorkshopDetailsForRegistration",
            {
                method: "POST",
                body: JSON.stringify({
                    route: workshopIdentifier
                })
            });

        if (!getWorkshopDetailsForRegistrationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkshopDetailsForRegistrationRespo = await getWorkshopDetailsForRegistrationResponse.json()
            if (getWorkshopDetailsForRegistrationRespo.status === "success") {
                setWorkshopData(getWorkshopDetailsForRegistrationRespo?.response)
                setIsPaid(getWorkshopDetailsForRegistrationRespo?.response?.feeType === "PAID" ? true : false)
            } else {
                setWorkshopData(null)
            }

            console.log(getWorkshopDetailsForRegistrationRespo)
        }
    }

    const getUserDetailsForWorkshopRegistration = async () => {
        setIsLoadingUserData(true)
        const getUserDetailsForWorkshopRegistrationResponse = await fetch(BASE_URL_API + "/getUserDetailsForWorkshopRegistration",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    workshopIdentifier: workshopData?.workshopID,
                    userIdentifier: authCtx?.userIdentifier
                })
            });

        if (!getUserDetailsForWorkshopRegistrationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getUserDetailsForWorkshopRegistrationRespo = await getUserDetailsForWorkshopRegistrationResponse.json()
            if (getUserDetailsForWorkshopRegistrationRespo.status === "success") {
                setUserData(getUserDetailsForWorkshopRegistrationRespo?.response)
                setHasAlreadyRegistered(getUserDetailsForWorkshopRegistrationRespo?.response?.registrationID === null ? false : true)
            } else {
                if (getUserDetailsForWorkshopRegistrationRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                }

            }
            console.log(getUserDetailsForWorkshopRegistrationRespo)
        }
        setIsLoadingUserData(false)
    }

    useEffect(() => {
        setIsLoading(true)
        getWorkshopDetailsForRegistration()
        setIsLoading(false)
    }, [workshopIdentifier])

    useEffect(() => {
        getUserDetailsForWorkshopRegistration()
    }, [workshopData])



    const registerForWorkshopIndividual = async () => {
        setIsRegistering(true)
        const registerForWorkshopIndividualResponse = await fetch(BASE_URL_API + "/registerForWorkshopIndividual",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    userIdentifier: authCtx?.userIdentifier,
                    workshopIdentifier: workshopData?.workshopID
                })
            });

        if (!registerForWorkshopIndividualResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const registerForWorkshopIndividualRespo = await registerForWorkshopIndividualResponse.json();
            if (registerForWorkshopIndividualRespo.status === "success") {
                toast.success('You have successfully registered for the workshop!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            } else {

            }
            console.log(registerForWorkshopIndividualRespo)
        }
        setIsRegistering(false)
    }


    const initiatePgTxnForWorkshop = async () => {
        setInitiatingPayment(true)
        const initiatePgTxnForWorkshopResponse = await fetch(BASE_URL_API + "/initiatePgTxnForWorkshop",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: authCtx?.userIdentifier,
                    workshopIdentifier: workshopData?.workshopID,
                    workshopRoute: workshopIdentifier
                })
            });

        if (!initiatePgTxnForWorkshopResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const initiatePgTxnForWorkshopRespo = await initiatePgTxnForWorkshopResponse.json()
            if (initiatePgTxnForWorkshopRespo.status === "success") {
                // console.log(initiatePgTxnForWorkshopRespo?.response?.data?.instrumentResponse?.redirectInfo?.url)
                window.location.href = initiatePgTxnForWorkshopRespo?.response?.data?.instrumentResponse?.redirectInfo?.url
            } else {
                if (initiatePgTxnForWorkshopRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(initiatePgTxnForWorkshopRespo)
        }
        setInitiatingPayment(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <NavbarHandler type="home" />
            <Helmet>
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            {isLoading === false ?
                <div className={styles.mainContainer}>
                    <div className={styles.topWrapper}>
                        {isLoadingUserData === true ?
                            <div className={styles.topContainer}>
                                <div>
                                    <ShimmerCircularImage />
                                </div>
                                <div>
                                    <ShimmerTitle />
                                </div>
                            </div>
                            :
                            <div className={styles.topContainer}>
                                <div>
                                    <FaUserCircle size={70} color="#6A6A6A" />
                                </div>
                                <div>
                                    <div>
                                        {userData?.stylist_name}
                                    </div>

                                    <div>
                                        {userData?.stylist_gender}
                                    </div>

                                    <div>
                                        {userData?.contact_number}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.centerWrapper}>
                        <div className={styles.centerContainer}>
                            <div className={styles.workshopTitle}>
                                {workshopData?.workshopTitle}
                            </div>
                            {/* <div className={styles.workshopDescription}>
                            {workshopData?.workshopDescription}
                        </div> */}


                            <div>
                                <div className={styles.startingInWrapper}>
                                    <div className={styles.startingInTitle}>
                                        Starting Workshop In
                                    </div>
                                    <div className={styles.startingInVal}>
                                        <Timer startingTime={workshopData?.startingTime} />
                                    </div>
                                </div>
                                <div>
                                    Workshop Duration : {workshopData?.workshopDuration} minutes
                                </div>
                            </div>


                            <div className={styles.twoInARowWrapper}>
                                <div className={styles.workshopCategory}>
                                    {workshopData?.workshopCategory}
                                </div>
                                <div className={styles.platform}>
                                    {workshopData?.platform}
                                </div>
                            </div>


                            <div className={styles.benefitsWrapper}>
                                <div className={styles.eachBenefitWrapper}>
                                    <div className={styles.eachBenefitIcon}>
                                        <GrCertificate size={16} />
                                    </div>
                                    <div className={styles.eachBenefitTxt}>
                                        Workshop Certificate
                                    </div>
                                </div>

                                <div className={styles.eachBenefitWrapper}>
                                    <div className={styles.eachBenefitIcon}>
                                        <FaStickyNote size={16} />
                                    </div>
                                    <div className={styles.eachBenefitTxt}>
                                        Product Theory
                                    </div>
                                </div>

                                <div className={styles.eachBenefitWrapper}>
                                    <div className={styles.eachBenefitIcon}>
                                        <FaPhotoVideo size={16} />
                                    </div>
                                    <div className={styles.eachBenefitTxt}>
                                        Online Recording
                                    </div>
                                </div>


                                <div className={styles.eachBenefitWrapper}>
                                    <div className={styles.eachBenefitIcon}>
                                        <FaQuestion size={16} />
                                    </div>
                                    <div className={styles.eachBenefitTxt}>
                                        After Workshop QnA
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                    <div className={styles.bottomWrapper}>
                        {hasAlreadyRegistered === true ?
                            <div className={styles.bottomContainer}>
                                <div>
                                    <div className={styles.smallArTxt}>You have already registered!</div>
                                    <div className={styles.tinyArTxt}>
                                        A link to join the workshop will be shared on your WhatsApp soon!
                                    </div>
                                </div>
                            </div>
                            :
                            isPaid === true ?
                                <div className={styles.bottomContainer}>
                                    <div className={styles.payNowWrapper}>
                                        <div className={styles.payNowAmountWrapper}>
                                            <div className={styles.orgPrice}>
                                                ₹{workshopData?.orgPrice}/-
                                            </div>
                                            <div className={styles.discountedPrice}>
                                                ₹{workshopData?.discountedPrice}/-
                                            </div>
                                        </div>
                                        {initiatingPayment === true ?
                                            <div className={styles.payNowBtnWrapper}>
                                                <button>Please wait...</button>
                                            </div>
                                            :
                                            <div className={styles.payNowBtnWrapper}>
                                                <button onClick={() => initiatePgTxnForWorkshop()}>Pay Now</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className={styles.bottomContainer}>
                                    {isRegistering === false ?
                                        <div className={styles.registerNow}>
                                            <button onClick={() => registerForWorkshopIndividual()} >Register Now</button>
                                        </div>
                                        :
                                        <div className={styles.registerNow}>
                                            <button>Please wait! Registering...</button>
                                        </div>
                                    }
                                </div>

                        }






                    </div>
                </div>

                :

                <div className={styles.mainContainer}>
                    <div className={styles.topWrapper}>
                        <div className={styles.topContainer}>
                            <div>
                                <ShimmerCircularImage />
                            </div>
                            <div>
                                <ShimmerTitle />
                            </div>
                        </div>
                    </div>
                    <div className={styles.centerWrapper}>
                        <div className={styles.centerContainer}>
                            <div className={styles.workshopTitle}>
                                Please wait...
                            </div>

                            <ShimmerThumbnail rounded />

                            <div className={styles.twoInARowWrapper}>
                                <div className={styles.workshopCategory}>
                                    <ShimmerThumbnail fitOnFrame={true} rounded height={40} />
                                </div>
                                <div className={styles.platform}>
                                    <ShimmerThumbnail fitOnFrame={true} rounded />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={styles.bottomWrapper}>

                        <div className={styles.bottomContainer}>
                            <div className={styles.payNowWrapper}>
                                <div className={styles.payNowAmountWrapper}>
                                    <div className={styles.orgPrice}>
                                        <ShimmerBadge width={80} />
                                    </div>
                                    <div className={styles.discountedPrice}>
                                        <ShimmerBadge fitOnFrame width={80} />
                                    </div>
                                </div>
                                <div className={styles.payNowBtnWrapper}>
                                    <ShimmerThumbnail fitOnFrame rounded />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </div>
    )
}

export default RegisterForWorkshopIndividual