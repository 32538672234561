import React, { useEffect, useRef, useState } from 'react'
import { FaRegBookmark, FaShareAlt } from 'react-icons/fa';
import { useParams } from 'react-router';
import NavbarHandler from '../Helper/NavbarHandler';
import { BASE_URL_API } from '../References/urls';
import styles from '../Jobs/EachJob.module.css'

import { ShimmerTitle, ShimmerThumbnail, ShimmerBadge } from "react-shimmer-effects";
import SimilarJobs from '../Components/SimilarJobs';
import ApplyNowCard from '../Components/ApplyNowCard';
import OtherJobsByCompany from '../Components/OtherJobsByCompany';

import { RWebShare } from "react-web-share";
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga';
import { capitalizeEachWord } from '../Helper/SupportingAlgos';
import EachJobFAQs from '../Components/Jobs/EachJobFAQs';


const width = window.innerWidth;


const EachJob = props => {
    let params = useParams();
    let route = params.route;
    let trackIdentifier = params.trackIdentifier;
    let pageTitle = capitalizeEachWord(route?.replace(/-/g, " ")?.toLowerCase())
    // console.log("trackIdentifier",trackIdentifier)

    const [scrollFromTop, setScrollFromTop] = useState(0);
    const [offsetTopRc, setOffsetTopRc] = useState()
    const rightContentColRef = useRef();

    const [jobDetails, setJobDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getJobDetails = async () => {
        setIsLoading(true);
        const getJobDetailsResponse = await fetch(BASE_URL_API + "/getJobDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    route: route
                })
            })

        if (!getJobDetailsResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const getJobDetailsRespo = await getJobDetailsResponse.json();
            if (getJobDetailsRespo.status === "success") {
                setJobDetails(getJobDetailsRespo.response);
            } else {
                console.log(getJobDetailsRespo)
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getJobDetails();
    }, [route])


    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const handleScroll = () => {
        let p = rightContentColRef.current;
        let footerPos = document.getElementById("footerContainerWr").offsetTop;
        setScrollFromTop(-1 * document.body.getBoundingClientRect().top);
    }

    useEffect(() => {
        let p = rightContentColRef.current;
        setOffsetTopRc(p.offsetTop);
    }, [rightContentColRef.current])


    window.addEventListener("scroll", handleScroll)



    const faqs = [
        {
            question: `What is the salary range for ${jobDetails?.title} jobs at ${jobDetails?.company}?`,
            answer: `The salary range for ${jobDetails?.category} jobs at ${jobDetails?.company} varies depending on experience and location. However, the average salary for a beautician at this location & profile is from ₹${jobDetails?.salaryStarting} - ₹${jobDetails?.salaryLimit} per month.`
        },
        {
            question: `Does ${jobDetails?.company} provides food and accomodation?`,
            answer: `${jobDetails?.fna}`
        },
        {
            question: `What are the working hours of ${jobDetails?.title} at ${jobDetails?.company}?`,
            answer: `The working hours of ${jobDetails?.company} job are ${jobDetails?.workingHours}. However, because everyone works here in shifts, your exact timing and working hours can only be determined at the time of your interview.`
        },
        {
            question: `What is the experience required for this job?`,
            answer: `${jobDetails?.experience}`
        },
        {
            question: `What are the skills required to apply for ${jobDetails?.title} job at ${jobDetails?.company}?`,
            answer: `These are some skills that you must be having hand-on experience in order to apply as a ${jobDetails?.title} : ${jobDetails?.skills.replaceAll(",", ", ")}`
        },
        {
            question: `How can I apply for ${jobDetails?.title} job at ${jobDetails?.company}`,
            answer: `To apply for ${jobDetails?.title} job at ${jobDetails?.company}, you can directly call on 8287-09-8287 or you can apply via stylelink mobile app after downloading from App Store / Play Store`
        },
        {
            question: "Is this a part-time or full-time job?",
            answer: `It's a full-time job. Part-time is not allowed while working at ${jobDetails?.company}.`
        },
        {
            question: `Do I need to pay any fee to get job at ${jobDetails?.company}?`,
            answer: `No applying for a job via stylelink app and website is absolutely free and candidates need not to pay any amount!`
        }
    ]

    return (
        <div className={styles.mainContainer}>
            {width > 600 ?
                <NavbarHandler />
                :
                <NavbarHandler type="home" />
            }
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content="India's first and only platform dedicated to salon jobs & professionals."></meta>
                <meta name="keywords" content={jobDetails?.keywords} ></meta>


                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content="India's first and only platform dedicated to salon jobs & professionals." />
                <meta property="og:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp" />

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={pageTitle}/>
                <meta name="twitter:description" content="India's first and only platform dedicated to salon jobs & professionals."/>
                <meta name="twitter:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp"/>

                <meta name="whatsapp:url" content={`https://stylelink.in/job/`+route} />
                <meta name="whatsapp:title" content={pageTitle} />
                <meta name="whatsapp:description" content="India's first and only platform dedicated to salon jobs & professionals." />
                <meta name="whatsapp:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp" />

                <meta name="telegram:url" content={`https://stylelink.in/job/`+route}/>
                <meta name="telegram:title" content={pageTitle}/>
                <meta name="telegram:description" content="India's first and only platform dedicated to salon jobs & professionals."/>
                <meta name="telegram:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/og.webp"/>


                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "JobPosting",
                        "title": "${jobDetails?.title}",
                        "datePosted": "${jobDetails?.datePosted}",
                        "validThrough": "${jobDetails?.validThrough}",
                        "description": "${jobDetails?.description}",
                        "hiringOrganization": {
                            "@type": "Organization",
                            "name": "${jobDetails?.company}",
                            "sameAs": "${jobDetails?.companyUrl}",
                            "logo": "${jobDetails?.logo}"
                        },
                        "jobLocation": {
                            "@type": "Place",
                            "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "${jobDetails?.address}",
                            "addressLocality": "${jobDetails?.address?.split(",")[0] + ", " + jobDetails?.address?.split(",")[1]}",
                            "addressRegion": "${jobDetails?.address?.split(", ")[2]}",
                            "postalCode": "${jobDetails?.postalCode}",
                            "addressCountry": "${jobDetails?.country}"
                            }
                        },
                        "baseSalary": {
                            "@type": "MonetaryAmount",
                            "currency": "${jobDetails?.currency}",
                            "value": {
                            "@type": "QuantitativeValue",
                            "value": ${jobDetails?.salaryLimit},
                            "unitText": "${jobDetails?.unitText}"
                            }
                        },
                        "employmentType": "${jobDetails?.oType}",
                        "jobBenefits": ${JSON.stringify(jobDetails?.benefits)},
                        "qualifications": ${JSON.stringify(jobDetails?.edQuali)},
                        "industry": "${jobDetails?.industry}",
                        "occupationalCategory": "${jobDetails?.category}",
                        "workHours": "${jobDetails?.workHours}",
                        "responsibilities": ${JSON.stringify(jobDetails?.description)},
                        "skills": ${JSON.stringify(jobDetails?.skills)},
                        "remoteJob": "${jobDetails?.remoteJob ? 'true' : 'false'}",
                        "jobLocationAccessibility": "${jobDetails?.jobLocationAccessibility}",
                        "jobURL": "https://stylelink.in/job/${route}"
                        }
                    `}
                </script>
                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                                "@type": "Question",
                                "name": "What is the salary range for ${jobDetails?.title} jobs at ${jobDetails?.company}?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "The salary range for ${jobDetails?.category} jobs at ${jobDetails?.company} varies depending on experience and location. However, the average salary for a beautician at this location & profile is from ₹${jobDetails?.salaryStarting} - ₹${jobDetails?.salaryLimit} per month."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "Does ${jobDetails?.company} provides food and accomodation?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${jobDetails?.fna}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "What are the working hours of ${jobDetails?.title} at ${jobDetails?.company}?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "The working hours of ${jobDetails?.company} job are ${jobDetails?.workingHours}. However, because everyone works here in shifts, your exact timing and working hours can only be determined at the time of your interview."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "What is the experience required for this job?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${jobDetails?.experience}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "What are the skills required to apply for ${jobDetails?.title} job at ${jobDetails?.company}?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "These are some skills that you must be having hand-on experience in order to apply as a ${jobDetails?.title} : ${jobDetails?.skills.replaceAll(',', ', ')}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "How can I apply for ${jobDetails?.title} job at ${jobDetails?.company}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "To apply for ${jobDetails?.title} job at ${jobDetails?.company}, you can directly call on 8287-09-8287 or you can apply via stylelink mobile app after downloading from App Store / Play Store"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "Is this a part-time or full-time job?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "It's a full-time job. Part-time is not allowed while working at ${jobDetails?.company}."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "Do I need to pay any fee to get job at ${jobDetails?.company}?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "No applying for a job via stylelink app and website is absolutely free and candidates need not to pay any amount!"
                                }
                            }
                        ]
                        }
                    `}
                </script>
            </Helmet>
            <div className={styles.mainBody}>
                <div className={styles.mainBodyContainer}>
                    {isLoading === false ?
                        <div className={styles.leftContainer}>
                            <div className={styles.jobDetailsContainer}>
                                <div className={styles.bannerImageContainer}>
                                </div>
                                <div className={styles.profileLogoContainer}>
                                    <div className={styles.profileLogo}>
                                        <div className={styles.profileLogoIcon}>B</div>
                                    </div>
                                </div>
                                <div className={styles.headerContainer}>
                                    <div className={styles.profileNActionContainer}>
                                        <div className={styles.profileContainer}>
                                            <h1>{jobDetails?.title}</h1>
                                        </div>
                                        <div className={styles.actionContainer}>
                                            <div className={styles.eachActionIcon}>
                                                <FaRegBookmark />
                                            </div>
                                            <div className={styles.eachActionIcon}>
                                                <RWebShare
                                                    data={{
                                                        text: "Find guaranteed jobs with Stylelink in salons",
                                                        url: "https://stylelink.in/job/" + route,
                                                        title: "Share",
                                                    }}
                                                    onClick={() => console.log("shared successfully!")}
                                                >
                                                    <FaShareAlt />
                                                </RWebShare>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.locationTimelineContainer}>
                                        <div className={styles.locationContainer}>
                                            {jobDetails.address}
                                        </div>
                                        <div className={styles.timelineContainer}>
                                            Posted few days ago
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.eligiblityContainer}>
                                    <div className={styles.eachElContainer}>
                                        <div className={styles.eachElLabel}>
                                            Salary
                                        </div>
                                        <div className={styles.eachElVal}>
                                            &#8377;{jobDetails?.salaryStarting} - {jobDetails?.salaryLimit}/-
                                        </div>
                                    </div>
                                    <div className={styles.eachElContainer}>
                                        <div className={styles.eachElLabel}>
                                            Experience
                                        </div>
                                        <div className={styles.eachElVal}>
                                            {jobDetails?.experience}
                                        </div>
                                    </div>
                                    <div className={styles.eachElContainer}>
                                        <div className={styles.eachElLabel}>
                                            Job Type
                                        </div>
                                        <div className={styles.eachElVal}>
                                            {jobDetails?.oType}
                                        </div>
                                    </div>

                                </div>

                                <div className={styles.fnaContainer}>
                                    <div className={styles.fnaBox}>
                                        <div className={styles.fnaTitle}>FOOD AND ACCOMODATION</div>
                                        <div className={styles.fnaValue}>{jobDetails?.fna}</div>
                                    </div>
                                </div>

                                <div className={styles.bewareContainer}>
                                    <div className={styles.bewareBlackLayer}>
                                    </div>
                                    <div className={styles.bewareTitle}>
                                        <h3>Beware of Fraudesters!    धोखेबाजों से सावधान</h3>
                                        <p>
                                            Fraudesters may ask you to pay in the pretext of registration fee, commission, refundable fee or so.
                                            Stylelink doesn't charge any fee from the candidates.
                                            <br></br>
                                            If someone asks for payment in kindly let us know by calling +91-8287-09-8287.
                                        </p>
                                        <p>
                                            जालसाज आपसे पंजीकरण शुल्क, कमीशन, वापसी योग्य शुल्क आदि के बहाने भुगतान करने के लिए कह सकते हैं।
                                            स्टाइललिंक उम्मीदवारों से कोई शुल्क नहीं लेता है।
                                            <br></br>
                                            अगर कोई भुगतान मांगता है, तो कृपया हमें +91-8287-09-8287 पर कॉल करके बताएं।
                                        </p>
                                    </div>
                                </div>

                                <div className={styles.skillsContainer}>
                                    {jobDetails.skills.split(",").map((item, index) => (
                                        <div className={styles.eachSkill} key={index + "SUP"} >{item}</div>
                                    ))}
                                </div>
                                <div className={styles.jobDescription} dangerouslySetInnerHTML={{ __html: jobDetails.description }}>

                                </div>

                            </div>
                            <div>
                                <OtherJobsByCompany />

                                <div className={styles.improveYourSkills}>
                                    {/* hey */}
                                </div>


                                <div className={styles.getCertified}>
                                    {/* hey */}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.leftContainer}>
                            <div className={styles.jobDetailsContainer}>
                                <div className={styles.loaderContainer}>
                                    <ShimmerTitle />

                                    <div className={styles.loaderEligiblityContainer}>
                                        <div className={styles.loaderEachElContainer}>
                                            <div className={styles.eachElLabel}>
                                                Salary
                                            </div>
                                            <div className={styles.eachElVal}>
                                                <ShimmerThumbnail height={60} />
                                            </div>
                                        </div>
                                        <div className={styles.loaderEachElContainer}>
                                            <div className={styles.eachElLabel}>
                                                Experience
                                            </div>
                                            <div className={styles.eachElVal}>
                                                <ShimmerThumbnail height={60} />
                                            </div>
                                        </div>
                                        <div className={styles.loaderEachElContainer}>
                                            <div className={styles.eachElLabel}>
                                                Job Type
                                            </div>
                                            <div className={styles.eachElVal}>
                                                <ShimmerThumbnail height={60} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.loaderSkillsContainer}>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={40} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={130} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={90} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={70} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={140} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={80} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={90} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge width={150} /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge /></div>
                                        <div className={styles.eachSkillBadge}><ShimmerBadge /></div>

                                    </div>
                                    <ShimmerThumbnail height={320}  className="m-0" rounded />
                                </div>
                            </div>
                        </div>
                    }
                    <div className={styles.rightContainer}

                    >
                        <div
                            className={styles.rightContentCol}
                            ref={rightContentColRef}
                        >
                            <div className={styles.rightHc}>
                                <div className={styles.rhcEc}>
                                    {!isLoading && <SimilarJobs key={jobDetails.identifier} identifier={jobDetails.identifier} catIdentifier={jobDetails.catIdentifier} />}
                                </div>
                                <div className={styles.rhcEc}>
                                    <ApplyNowCard />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="footerContainerWr">
                <EachJobFAQs faqs={faqs} />
            </section>

        </div >
    )
}

export default EachJob