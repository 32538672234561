import React from 'react'
import styles from '../Workshops/EachWorkshopFAQsWrapper.module.css'
import GCWCertificateFAQs from './GCWCertificateFAQs'
import GCWWorkshopFAQs from './GCWWorkshopFAQs'

const EachWorkshopFAQsWrapper = props => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainTitle}>
                <h2>Frequently Asked Questions (FAQs)</h2>
            </div>
            <div>
                <GCWWorkshopFAQs />
                <GCWCertificateFAQs />
            </div>
        </div>
    )
}

export default EachWorkshopFAQsWrapper