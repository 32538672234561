import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer'
import NavbarHandler from '../Helper/NavbarHandler'
import styles from './Courses.module.css'

import ReactGA from 'react-ga';

const width = window.innerWidth;
const Courses = () => {
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])



    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>India's best hair, beauty, and makeup course - Stylelink Course</title>
                <meta name="description" content="Get exceptional knowledge in beauty, hair, and makeup with stylelink courses."></meta>
            </Helmet>
            <NavbarHandler type={width < 600 ? "home" : ''} />
            <section className={styles.heroContainer}>
                {/* <div className={styles.videoPlayerContainer}>
                    <video autoPlay loop muted src="https://res.cloudinary.com/drotwjbzs/video/upload/v1663246547/Final_5_fh0rtc.mp4" ></video>
                </div> */}
                <div className={styles.heroContent}>
                    <h3 className={styles.heroTitle}>
                        <div>Learn </div>
                        <div>
                            <span className={styles.yellowShape}>beauty
                                <img src="https://educal-react.vercel.app/assets/img/shape/yellow-bg.png" alt="yellow-shape" />
                            </span> Skills
                        </div>
                        <div className={styles.likeNeverBefore}>
                            <div className={styles.centeredHoriLine}>
                            </div>
                            <div className={styles.likeNeverBeforeTxt}>
                                like never before
                            </div>
                        </div>
                    </h3>

                </div>

            </section>

            <section className={styles.courseDomainsContainer}>
                <div className={styles.courseDomainsTitle}>
                    with our interactive courses in
                </div>
                <div className={styles.courseDomainsCardsContainer}>
                    <div className={styles.eachCourseDomains}>
                        <div className={styles.eachCourseDomainsTitle}>Academics</div>
                        <div className={styles.exploreCourseRow}>
                            <Link to="/classroom-courses" className={styles.exploreCourseBtnContainer}>
                                <div className={styles.exploreBtnTxt}>
                                    Explore Classroom Courses
                                </div>
                                <div className={styles.exploreBtnIcon}>
                                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1649160157/web-icons/img_418607_lxiv4r.png" />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.eachCourseDomains}>
                        <div className={styles.eachCourseDomainsTitle}>Online</div>
                        <div className={styles.exploreCourseRow}>
                            <Link to="/online-courses/makeup" className={styles.exploreCourseBtnContainer}>
                                <div className={styles.exploreBtnTxt}>
                                    Explore Online Courses
                                </div>
                                <div className={styles.exploreBtnIcon}>
                                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1649160157/web-icons/img_418607_lxiv4r.png" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.courseCategorySection}>
                <div className={styles.eachCourseCategory}>
                    <div className={styles.eachCourseCategoryLeft}>
                        <div className={styles.eachCategoryTitle}>
                            Designed With Love & Utter Research
                        </div>
                    </div>
                    <div className={styles.eachCourseCategoryRight}>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardLeft}>
                                <div className={styles.cardTitle}>
                                    Designed With Love & Utter Research
                                </div>
                                <div className={styles.cardDescription}>
                                    Our every course / module has been prepared after very deep & technical research.
                                    <p>
                                        We understood the concept of <b>WHAT IS TRENDING </b> and <b>WHAT PEOPLE NEED </b>
                                        and blended the two aspects to create convincing & outstanding modules.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.cardRight}>
                                {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657878702/brush_oko725.png" /> */}
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.eachCourseCategoryCompli}>
                    <div className={styles.eachCourseCategoryRight}>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardRightAlt}>
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657983591/makeup-palette-1080x1920-2_khv3pw.png" />
                            </div>
                            <div className={styles.cardLeftAlt}>
                                <div className={styles.cardTitle}>
                                    Doubt/Interaction Sessions
                                </div>
                                <div className={styles.cardDescription}>
                                    Still, feeling anxious about the doubt-solving part? It’s 2022 now, and we have also understood the points which make the Students go anxious and worried.
                                    <p>
                                        With our 1:1 doubt live doubt sessions,
                                        you can always stay floating at the top of your life.
                                        Our Experienced and Certified Educators will keep your doubts a
                                        priority and help you out at every point.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachCourseCategoryLeftCompli}>
                        <div className={styles.eachCategoryTitle}>
                            Doubt/Interaction Sessions
                        </div>
                    </div>
                </div>


                <div className={styles.eachCourseCategory}>
                    <div className={styles.eachCourseCategoryLeft}>
                        <div className={styles.eachCategoryTitle}>
                            Accreditation & Certifications
                        </div>
                    </div>
                    <div className={styles.eachCourseCategoryRight}>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardLeft}>
                                <div className={styles.cardTitle}>
                                    Accreditation & Certifications
                                </div>
                                <div className={styles.cardDescription}>
                                    We provide Internationally Accredited & widely known courses to you because we want to enhance your skills and raise your level in the game.
                                    <p>
                                        Apart from this, we offer Certifications to our students which will help them to grab and secure Jobs at various Salons/Academies in their respective profiles.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.cardRight}>
                                {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657878702/brush_oko725.png" /> */}
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.eachCourseCategoryCompli}>
                    <div className={styles.eachCourseCategoryRight}>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardLeft}>
                                <div className={styles.cardTitle}>
                                    PAN India Jobs/Internships Guaranteed:
                                </div>
                                <div className={styles.cardDescription}>
                                    Are we kidding? No. Are we high on something? Surely no. Then what is wrong with us? Nothing. We just understood the major pain point of the people involved in this industry, which none other Academy or Portal understood.
                                    <p>
                                        Yes, we are surely sane and we are surely taking a guarantee to provide you with a guaranteed Job/Internship in PAN India, to make sure, that you not only learn but earn well too.
                                    </p>
                                    <p>
                                        Get Certified and grab that job that you always wanted. Show the world what you got and what you can do. Prove people wrong about the Taboos they hold for this industry.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.cardRight}>
                                {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657878702/brush_oko725.png" /> */}
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachCourseCategoryLeftCompli}>
                        <div className={styles.eachCategoryTitle}>
                            PAN India Jobs/Internships Guaranteed:
                        </div>
                    </div>
                </div>


                <div className={styles.eachCourseCategory}>
                    <div className={styles.eachCourseCategoryLeft}>
                        <div className={styles.eachCategoryTitle}>
                            Downloadable Content/Access:

                        </div>
                    </div>
                    <div className={styles.eachCourseCategoryRight}>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardLeft}>
                                <div className={styles.cardTitle}>
                                    Downloadable Content/Access:

                                </div>
                                <div className={styles.cardDescription}>
                                    Are you a Go-to sort of person? Doesn’t hold good with timings & schedules?
                                    <p>
                                        Don’t worry, we offer our courses with lifetime access along with downloading feature. You can surely download our course's videos at Highest Quality and watch them anywhere and at any time.
                                    </p>
                                    <p>
                                        Apart from these videos, you also get to download your Study Material, Assessments and other Resources.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.cardRight}>
                                {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657878702/brush_oko725.png" /> */}
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.eachCourseCategoryCompli}>
                    <div className={styles.eachCourseCategoryRight}>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardLeft}>
                                <div className={styles.cardTitle}>
                                    Pricing:

                                </div>
                                <div className={styles.cardDescription}>
                                    With all such benefits and resources, the course might be very expensive for me. Thinking the same?
                                    <p>
                                        Don’t overthink too much. The course is as affordable as buying a cup of coffee on daily basis. Absolutely. We might be offering you a great bundle of knowledge & resources, but, we made sure that it should fit into every person’s budget.
                                    </p>
                                    <p>
                                        We didn’t create our course for a high-paying audience, we care & value each and everyone’s passion and skills, ie., we gave it a price tag which can be purchased by every type of paying audience.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.cardRight}>
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachCourseCategoryLeftCompli}>
                        <div className={styles.eachCategoryTitle}>
                            Pricing:

                        </div>
                    </div>
                </div>



            </section>

            <section className={styles.courseCategorySectionMobile}>
                <div className={styles.ccsmTitle}>
                    <h2>Why should you do courses from Stylelink?</h2>
                </div>
                <div className={styles.eachCourseCategory}>
                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <div className={styles.cardTitle}>
                                Designed With Love & Utter Research
                            </div>
                            <div className={styles.cardDescription}>
                                Our every course / module has been prepared after very deep & technical research.
                                <p>
                                    We understood the concept of <b>WHAT IS TRENDING </b> and <b>WHAT PEOPLE NEED </b>
                                    and blended the two aspects to create convincing & outstanding modules.
                                </p>
                            </div>
                        </div>
                        <div className={styles.cardRight}>
                            {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657878702/brush_oko725.png" /> */}
                            <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                        </div>
                    </div>

                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <div className={styles.cardTitle}>
                                Doubt/Interaction Sessions
                            </div>
                            <div className={styles.cardDescription}>
                                Still, feeling anxious about the doubt-solving part? It’s 2022 now, and we have also understood the points which make the Students go anxious and worried.
                                <p>
                                    With our 1:1 doubt live doubt sessions,
                                    you can always stay floating at the top of your life.
                                    Our Experienced and Certified Educators will keep your doubts a
                                    priority and help you out at every point.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <div className={styles.cardTitle}>
                                Accreditation & Certifications
                            </div>
                            <div className={styles.cardDescription}>
                                We provide Internationally Accredited & widely known courses to you because we want to enhance your skills and raise your level in the game.
                                <p>
                                    Apart from this, we offer Certifications to our students which will help them to grab and secure Jobs at various Salons/Academies in their respective profiles.
                                </p>
                            </div>
                        </div>
                        {/* <div className={styles.cardRight}>
                            <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                        </div> */}
                    </div>

                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <div className={styles.cardTitle}>
                                PAN India Jobs/Internships Guaranteed:
                            </div>
                            <div className={styles.cardDescription}>
                                Are we kidding? No. Are we high on something? Surely no. Then what is wrong with us? Nothing. We just understood the major pain point of the people involved in this industry, which none other Academy or Portal understood.
                                <p>
                                    Yes, we are surely sane and we are surely taking a guarantee to provide you with a guaranteed Job/Internship in PAN India, to make sure, that you not only learn but earn well too.
                                </p>
                                <p>
                                    Get Certified and grab that job that you always wanted. Show the world what you got and what you can do. Prove people wrong about the Taboos they hold for this industry.
                                </p>
                            </div>
                        </div>
                        {/* <div className={styles.cardRight}>
                            <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                        </div> */}
                    </div>

                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <div className={styles.cardTitle}>
                                Downloadable Content/Access:

                            </div>
                            <div className={styles.cardDescription}>
                                Are you a Go-to sort of person? Doesn’t hold good with timings & schedules?
                                <p>
                                    Don’t worry, we offer our courses with lifetime access along with downloading feature. You can surely download our course's videos at Highest Quality and watch them anywhere and at any time.
                                </p>
                                <p>
                                    Apart from these videos, you also get to download your Study Material, Assessments and other Resources.
                                </p>
                            </div>
                        </div>
                        {/* <div className={styles.cardRight}>
                            <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                        </div> */}
                    </div>

                    <div className={styles.cardContainer}>
                        <div className={styles.cardLeft}>
                            <div className={styles.cardTitle}>
                                Pricing:

                            </div>
                            <div className={styles.cardDescription}>
                                With all such benefits and resources, the course might be very expensive for me. Thinking the same?
                                <p>
                                    Don’t overthink too much. The course is as affordable as buying a cup of coffee on daily basis. Absolutely. We might be offering you a great bundle of knowledge & resources, but, we made sure that it should fit into every person’s budget.
                                </p>
                                <p>
                                    We didn’t create our course for a high-paying audience, we care & value each and everyone’s passion and skills, ie., we gave it a price tag which can be purchased by every type of paying audience.
                                </p>
                            </div>
                        </div>
                        {/* <div className={styles.cardRight}>
                            <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png" />
                        </div> */}
                    </div>

                </div>
            </section>

            {/* <section className={styles.firstContainer}>
                hi

            </section> */}

            <Footer />
        </div>
    )
}

export default Courses;