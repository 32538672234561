import React from 'react'
import { CgChevronDoubleRight } from 'react-icons/cg'
import styles from '../Workshops/GCWCertificateFAQs.module.css'

const GCWCertificateFAQs = props => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainTitle}>
                    Certificate Related FAQs
                </div>
                <div className={styles.eachQuery}>
                    <div className={styles.queryQuestion}>
                        <h2>
                            <CgChevronDoubleRight size={26} />
                            Will I get the certificate for this workshop?
                        </h2>
                    </div>
                    <div className={styles.queryAnswer}>
                        <p>
                            Yes, you'll get the certificate for attending this workshop completely.
                        </p>
                    </div>
                </div>

                <div className={styles.eachQuery}>
                    <div className={styles.queryQuestion}>
                        <h2>
                            <CgChevronDoubleRight size={26} />
                            Am I eligible to get the certificate?
                        </h2>
                    </div>
                    <div className={styles.queryAnswer}>
                        <p>
                            Yes, all the pre-registered individuals are qualified to get certificate.
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default GCWCertificateFAQs