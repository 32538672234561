export const humanReadableTimePassed = (k) => {
    let curTime = new Date();
    curTime = curTime.toISOString();
    try {
        let recTime = new Date(k).toISOString();
        let diff = new Date(curTime) - new Date(recTime);

        let days = Math.floor(diff / (24 * 60 * 60 * 1000));
        let hours = Math.floor((diff - days * (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

        // let minutes = Math.floor(diff - ((diff - days * (24 * 60 * 60 * 1000)) / ( 60*1000)) );

        let minutes = Math.floor((diff / (1000 * 60)) % 60)


        // console.log("Minutes : ",minutes);

        let returningItem = '';
        if (days < 1) {
            if (hours < 1) {
                returningItem = minutes + " mins ago";
            } else if (hours < 5) {
                returningItem = hours + " hours " + minutes + " mins ago";
            } else if (hours < 12) {
                returningItem = hours + " hours ago";
            } else if (hours < 14) {
                returningItem = " today";
            } else {
                returningItem = " yesterday";
            }
        } else if (days < 30) {
            if (days < 2) {
                returningItem = days + " day ago";
            } else if (days < 7) {
                returningItem = days + " days ago";
            } else if (days > 6) {
                returningItem = " around a week ago";
            } else if (days < 13) {
                returningItem = days + " days ago";
            } else if (days < 15) {
                returningItem = " 2 weeks ago";
            } else if (days > 15) {
                returningItem = " 3 weeks ago";
            } else {
                returningItem = " around a month ago";
            }
        } else if (days > 29) {
            if (days < 45) {
                returningItem = " around a month ago";
            } else if (days < 60) {
                returningItem = " one & half month ago";
            } else if (days < 75) {
                returningItem = " 2 months ago";
            } else if (days < 90) {
                returningItem = " around 3 months ago";
            } else if (days < 115) {
                returningItem = " 3 months ago";
            } else if (days < 135) {
                returningItem = " 4 months ago";
            } else if (days < 165) {
                returningItem = " 5 months ago";
            } else if (days < 195) {
                returningItem = " 6 months ago";
            } else if (days < 225) {
                returningItem = " 7 months ago";
            } else if (days < 255) {
                returningItem = " 8 months ago";
            } else if (days < 285) {
                returningItem = " 9 months ago";
            } else if (days < 315) {
                returningItem = " 10 months ago";
            } else if (days < 345) {
                returningItem = " 11 months ago";
            } else if (days < 361) {
                returningItem = " last year";
            }
        } else if (days > 360) {
            returningItem = " last year";
        } else if (days > 720) {
            returningItem = " 1 and half year ago";
        } else {
            returningItem = " 2 years ago";
        }

        return returningItem;
    } catch (error) {
        // console.log(error)
        return "InvalidTime"
    }
}



export const formatDateToReadable = (dateString) => {
    // Check if the date string is valid
    if (isNaN(Date.parse(dateString))) {
        return 'Invalid Date';
    }
    
    const date = new Date(dateString);
    
    // Check if the date object is valid
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }
    
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
}