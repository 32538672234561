import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './RegisterWorkshopPaymentStatus.module.css'
import { IoReload } from "react-icons/io5";
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/AuthContext';
import { Helmet } from 'react-helmet';



const RegisterWorkshopPaymentStatus = () => {
    const authCtx = useContext(AuthContext)
    const { workshopIdentifier } = useParams()
    const [paymentStatus, setPaymentStatus] = useState('PENDING') // SUCCESS, FAILED, PENDNING
    const [isFetchingStatus, setIsFetchingStatus] = useState(false)
    const [amountPaid, setAmountPaid] = useState(0)
    const [amountToPay, setAmountToPay] = useState(0)

    const getWorkshopPaymentStatus = async () => {
        const getWorkshopPaymentStatusResponse = await fetch(BASE_URL_API + "/getWorkshopPaymentStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    workshopRoute: workshopIdentifier,
                    userIdentifier: authCtx.userIdentifier
                })
            });

        if (!getWorkshopPaymentStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkshopPaymentStatusRespo = await getWorkshopPaymentStatusResponse.json()
            if (getWorkshopPaymentStatusRespo.status === "success") {
                if (getWorkshopPaymentStatusRespo?.response?.paymentStatus === "PAYMENT_PENDING") {
                    setPaymentStatus("FAILED")
                }
                if (getWorkshopPaymentStatusRespo?.response?.paymentStatus === "PAYMENT_SUCCESS") {
                    setPaymentStatus("SUCCESS")
                    setAmountPaid(parseFloat((getWorkshopPaymentStatusRespo?.response?.amountPaid) / 100))
                    setAmountToPay(parseFloat((getWorkshopPaymentStatusRespo?.response?.amountToPay) / 100))
                }
            } else {
                if (getWorkshopPaymentStatusRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                }
                setPaymentStatus("PENDING")
            }

            console.log(getWorkshopPaymentStatusRespo)
        }
    }

    useEffect(() => {
        getWorkshopPaymentStatus()
    }, [])





    return (
        <div className={styles.mainWrapper}>
            <Helmet>
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            {isFetchingStatus === true ?
                <div className={styles.mainContainer}>
                    Hold On!
                    Fetching Payment Status
                </div>
                :
                <div className={styles.mainContainer}>
                    {paymentStatus === 'SUCCESS' &&
                        <div className={styles.sectionWrapper}>
                            <div className={styles.upperContainer}>
                                <div className={styles.iconWrapper}>
                                    <img src="https://i.pinimg.com/originals/32/b6/f2/32b6f2aeeb2d21c5a29382721cdc67f7.gif" />
                                </div>
                            </div>
                            <div className={styles.lowerContainer}>
                                <div className={styles.statusTitle}>
                                    Payment Successful!
                                </div>
                                <div className={styles.subTitle}>
                                    We have received your payment of
                                </div>
                                <div className={styles.paymentAmount}>
                                    <div className={styles.amount}>
                                        <span className={styles.rupeeIcon}>₹</span><span>{amountPaid}</span>
                                    </div>
                                </div>
                                <div className={styles.goToBtn}>
                                    <Link to={`/workshop/${workshopIdentifier}`} >Go To Workshop</Link>
                                </div>
                            </div>
                        </div>
                    }
                    {paymentStatus === 'FAILED' &&
                        <div className={styles.sectionWrapper}>
                            <div className={styles.failedUpperContainer}>
                                <div className={styles.iconWrapper}>
                                    <img src="https://i.pinimg.com/originals/9d/16/7e/9d167e72839894c971c90f60ab00d916.gif" />
                                </div>
                            </div>
                            <div className={styles.failedLowerContainer}>
                                <div className={styles.failedTitle}>
                                    Payment Failed!
                                </div>
                                <div className={styles.failedSubTitle}>
                                    Something went wrong we couldn't process your payment. Contact our support if you have lost your money.
                                </div>

                                <div className={styles.retryBtn}>
                                    <Link to={`/workshop/${workshopIdentifier}/register`}>
                                        <IoReload fontSize={20} /> &nbsp; Retry Payment
                                    </Link>
                                </div>

                                <div className={styles.support}>
                                    Support
                                </div>



                            </div>
                        </div>
                    }
                    {paymentStatus === 'PENDING' &&
                        <div className={styles.sectionWrapper}>
                            <div className={styles.upperContainer}>

                            </div>
                            <div className={styles.lowerContainer}>
                                <div className={styles.statusTitle}>
                                    Payment is Pending!
                                </div>
                                <div className={styles.subTitle}>
                                    We have not received your payment for workshop registeration.
                                    Kindly pay the amount before registrations slot gets filled!
                                </div>
                                <div className={styles.goToBtn}>
                                    <Link to={`/workshop/${workshopIdentifier}/register`} >Pay Amount</Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default RegisterWorkshopPaymentStatus