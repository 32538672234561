import React from 'react'
import { FaSearch } from 'react-icons/fa'
import styles from '../Styles/Components/JobSearchSection.module.css'

const JobSearchSection = props => {
    const isLoading = props.isLoading;
    const sortingHandler = props.sortingHandler; 

    return (
        <div className={styles.mainContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.headerTitle}>
                    Search Jobs
                </div>
                <div className={styles.headerSubTitle}>
                    Recent Search
                </div>
            </div>
            <div className={styles.seachRowContainer}>
                <div className={styles.searchBoxContainer}>
                    <FaSearch color="#BFC6CD" />
                    <input placeholder="Search Jobs..." />
                </div>
                <div className={styles.sortingContainer}>
                    <select onChange={(e) => sortingHandler(e.target.value)}>
                        <option value="Newest">Newest</option>
                        <option value="Salary : Lowest To Highest">Salary : Lowest To Highest</option>
                        <option value="Salary : Highest To Lowest">Salary : Highest To Lowest</option>
                        <option value="Earliest Joining Date">Earliest Joining Date</option>
                    </select>
                </div>
            </div>

            {isLoading == true ?
                <div className={styles.searchResultsCount}>
                    finding jobs...
                </div>
                :
                <div className={styles.searchResultsCount}>
                    {props.resultCount} result(s) found!
                </div>
            }
        </div>
    )
}

export default JobSearchSection