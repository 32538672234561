import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import NavbarHandler from '../Helper/NavbarHandler';
import styles from "../Styles/Courses/PremiumCategory.module.css"
import { Link } from 'react-router-dom'
import { FaBookmark, FaCertificate, FaCheck, FaCheckDouble, FaClock, FaCopy, FaEye, FaFire, FaIdBadge, FaInfinity, FaLanguage, FaPaperclip, FaPhone, FaPhotoVideo, FaRegBookmark, FaRegCheckSquare, FaRegIdBadge, FaSearch, FaStar } from 'react-icons/fa';
import { GrCertificate } from 'react-icons/gr';
import { BsFillBriefcaseFill } from 'react-icons/bs';

import { BASE_URL_API } from '../References/urls';
import EachCourseInList from '../Components/EachCourseInList';
import UserLoginCard from '../Components/UserLoginCard';

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
} from "react-shimmer-effects";

import { RWebShare } from "react-web-share";

import ReactGA from 'react-ga';

const width = window.innerWidth;

const PremiumCategory = () => {
    let params = useParams();
    let navigate = useNavigate();
    let cat = params.category;

    const [isLoadingCourses, setIsLoadingCourses] = useState(false);
    const [coursesList, setCoursesList] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);

    const selectCourseHandler = (k) => {
        setSelectedCourse(selectedCourse !== k ? k : "")
    }

    useEffect(() => {
        getAllCourses();
    }, [cat])


    const copyToClipboardHandler = async url => {
        try {
            await navigator.clipboard.writeText(url);
        } catch (errInCopying) {
            console.log("Couldn't copy url, please try again!");
        }
    }

    const moveToSearch = async () => {
        await navigate('/online-courses/search');
    }

    const getAllCourses = async () => {
        setIsLoadingCourses(true);
        const getAllCoursesResponse = await fetch(BASE_URL_API + "/getAllCourses",
            {
                method: "POST",
                body: JSON.stringify({
                    courseCategory: cat
                })
            });

        if (!getAllCoursesResponse.ok) {
            alert("Something went wrong : Server Error");
        } else {
            const getAllCoursesRespo = await getAllCoursesResponse.json();
            if (getAllCoursesRespo.status == "success") {
                setCoursesList(getAllCoursesRespo.response);
            } else {
                console.log(getAllCoursesRespo)
            }
        }
        setIsLoadingCourses(false)
    }



    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])


    return (
        <div className={styles.mainContainer}>
            <NavbarHandler type={width<600 ? "home" : ''} />
            <section className={styles.contentWrapper}>
                <div className={styles.leftContentWrapper}>
                    <div className={styles.categoryTitleCardContainer}>
                        <Link to="/online-courses/makeup" className={cat === "makeup" ? styles.eachCategoryBtnSelected : styles.eachCategoryBtn}>
                            Makeup
                        </Link>
                        <Link to="/online-courses/hair" className={cat === "hair" ? styles.eachCategoryBtnSelected : styles.eachCategoryBtn}>
                            Hair
                        </Link>
                        <Link to="/online-courses/beauty" className={cat === "beauty" ? styles.eachCategoryBtnSelected : styles.eachCategoryBtn}>
                            Beauty
                        </Link>
                    </div>
                    {isLoadingCourses ?
                        <div className={styles.courseLoader}>
                            <div className={styles.eachCourseLoader}>
                                <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                            </div>
                            <div className={styles.eachCourseLoader}>
                                <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                            </div>
                        </div>
                        :
                        <div className={styles.listWrapper}>
                            {coursesList != null ?
                                coursesList.map((item, index) => (
                                    <EachCourseInList
                                        item={item}
                                        key={index}
                                        selectCourseHandler={selectCourseHandler}
                                        selectedCourse={selectedCourse}
                                    />
                                ))
                                :
                                <div className={styles.noCourseFound}>
                                    <div className={styles.havenotLaunchedYet}>
                                        <h2>We haven't launched any course in this category so far!</h2>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {selectedCourse != null ?
                    <div className={styles.centerContentWrapper}>
                        <div className={styles.centerTitleCardContainer}>
                            <input placeholder="Type anything..." onFocus={() => moveToSearch()}></input>
                            <button><FaSearch size={16} /></button>
                        </div>
                        <div className={styles.courseContentWrapper}>
                            <div className={styles.courseContentContainer}>
                                <div className={styles.courseHeroContainer}>
                                    <video autoPlay muted loop>
                                        <source src="https://res.cloudinary.com/drotwjbzs/video/upload/v1658222948/MVI_4335_1_xp1ehm.mp4" ></source>
                                    </video>
                                    <div className={styles.courseHeroContent}>
                                        <div className={styles.courseTitleContainer}>
                                            <div className={styles.courseTitle}>
                                                <h1>{selectedCourse.courseTitle}</h1>
                                            </div>
                                            <div className={styles.bookmarkCourse}>
                                                <FaRegBookmark size={14} className={styles.bookmarkIcon} color="#a5a5a5" />
                                            </div>
                                        </div>
                                        <div className={styles.courseDurationNLectCount}>
                                            <div>
                                                <FaClock /> 4 hours
                                            </div>
                                            <div>
                                                <FaPhotoVideo /> 32 modules
                                            </div>
                                        </div>
                                        <div className={styles.courseRating}>
                                            <div className={styles.courseLanguage}>
                                                <FaLanguage size={28} />  Hindi / English
                                            </div>
                                            <div>
                                                <FaStar color="#ffcb08" />
                                                <FaStar color="#ffcb08" />
                                                <FaStar color="#ffcb08" />
                                                <FaStar color="#ffcb08" />
                                                <FaStar color="#ffcb08" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.courseMainContent}>
                                    <div className={styles.courseAdvantage}>
                                        <div className={styles.isCertificate}>
                                            <GrCertificate size={18} />&nbsp;Certificate
                                        </div>
                                        <div className={styles.isJobOffer}>
                                            <BsFillBriefcaseFill size={18} />&nbsp;Job Offer up to 45,000/- per month
                                        </div>
                                    </div>

                                    <div className={styles.courseAdvantage}>
                                        <div className={styles.whatYoullLearn}>
                                            <div className={styles.whatYoullLearnTitle}>
                                                What you'll learn in this course?
                                            </div>
                                            <div className={styles.whatYoullLearnContent}>
                                                {selectedCourse.wyl != null &&
                                                    selectedCourse.wyl.map((itemWyl, index) => (
                                                        <div className={styles.whatYoullLearnContentEach}>
                                                            <FaCheck size={10} fontWeight={300} /> {itemWyl}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.courseAdvantage}>
                                        <div className={styles.whatYoullLearn}>
                                            <div className={styles.whatYoullLearnTitle}>
                                                This course includes
                                            </div>
                                            <div className={styles.whatYoullLearnContent}>
                                                <div className={styles.whatYoullLearnContentEach}>
                                                    <FaClock size={10} fontWeight={300} /> 40 hours of total video
                                                </div>
                                                <div className={styles.whatYoullLearnContentEach}>
                                                    <FaPaperclip size={10} fontWeight={300} /> 32 short assignments
                                                </div>
                                                <div className={styles.whatYoullLearnContentEach}>
                                                    <FaInfinity size={10} fontWeight={300} /> Lifetime access
                                                </div>
                                                <div className={styles.whatYoullLearnContentEach}>
                                                    <FaPhone size={10} fontWeight={300} /> Accessible on mobile, desktop, and TV
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.courseContentFooter}>
                                <div className={styles.courseActionContainer}>
                                    <div className={styles.courseActionUrl}>
                                        <input readOnly={true} value={"https://stylelink.in/online-course/makeup/" + selectedCourse.courseRoute}></input>
                                        <button onClick={() => copyToClipboardHandler('https://stylelink.in/online-course/makeup/' + selectedCourse.courseRoute)}><FaCopy size={20} /></button>
                                    </div>
                                    <div className={styles.courseActionShare}>
                                        <RWebShare
                                            data={{
                                                text: selectedCourse.courseShortDescription,
                                                url: "https://stylelink.in/online-course/makeup/" + selectedCourse.courseRoute,
                                                title: "Share",
                                            }}
                                            onClick={() => console.log("shared successfully!")}
                                        >
                                            <div className={styles.shareCourse}>Share</div>
                                        </RWebShare>
                                    </div>
                                    <div className={styles.courseActionViewDetails}>
                                        <Link to={"/online-course/makeup/" + selectedCourse.courseRoute} className={styles.viewDetailsOnPage} >View Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.centerContentWrapper}>
                        <div className={styles.noCourseSelectedContainer}>
                            Please select course to start!
                        </div>
                    </div>
                }
                <div className={styles.rightContentWrapper}>
                    <UserLoginCard />
                    {selectedCourse != null &&
                        <div className={styles.buyThisCard}>
                            <Link to={"/online-course/makeup/" + selectedCourse.courseRoute} className={styles.buyCardFooterSection}>
                                <button>Buy this course</button>
                            </Link>
                        </div>
                    }
                </div>
            </section >

            <section className={styles.contentWrapperMobile}>
                <div className={styles.topContentWrapperMobile}>
                    <div className={styles.centerTitleCardContainer}>
                        <input placeholder="Type anything..." onFocus={() => moveToSearch()}></input>
                        <button><FaSearch size={15} /></button>
                    </div>
                </div>
                {isLoadingCourses == true ?
                    <div className={styles.bodyContentMobile}>
                        <div className={styles.courseFetchingLoader}>
                            <div className={styles.eachCourseLoader}>
                                <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                            </div>
                            <div className={styles.eachCourseLoader}>
                                <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                            </div>
                            <div className={styles.eachCourseLoader}>
                                <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                            </div>
                            <div className={styles.eachCourseLoader}>
                                <ShimmerThumbnail height={200} width="100%" className="m-0" rounded />
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.bodyContentMobile}>
                        {coursesList != null ?
                            <div className={styles.courseListWrapper}>
                                {coursesList.map((item, index) => (
                                    <EachCourseInList
                                        item={item}
                                        key={index}
                                        selectCourseHandler={selectCourseHandler}
                                        selectedCourse={selectedCourse}
                                    />
                                ))}
                            </div>
                            :
                            <div className={styles.noCourseFound}>
                                No course found in this category!
                            </div>
                        }
                    </div>
                }

                <div className={styles.footerContentMobile}>
                    <div className={styles.categoryTitleCardContainer}>
                        <Link to="/online-courses/makeup" className={cat === "makeup" ? styles.eachCategoryBtnSelected : styles.eachCategoryBtn}>
                            Makeup
                        </Link>
                        <Link to="/online-courses/hair" className={cat === "hair" ? styles.eachCategoryBtnSelected : styles.eachCategoryBtn}>
                            Hair
                        </Link>
                        <Link to="/online-courses/beauty" className={cat === "beauty" ? styles.eachCategoryBtnSelected : styles.eachCategoryBtn}>
                            Beauty
                        </Link>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default PremiumCategory