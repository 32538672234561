import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../Styles/Components/EachSimilarJob.module.css'

const EachSimilarJob = props => {
    let data = props.data;
    return (
        <Link to={`/job/${data?.route}`}>
            <div className={styles.eachSimilarJob}>
                <div className={styles.esjH}>
                    <div className={styles.esjT}>
                        <div className={styles.esjtP}>
                            {data.title}
                        </div>
                        <div className={styles.esjtL}>
                            {data.address}
                        </div>
                    </div>
                    <div className={styles.esjST}>
                        <span>Unisex Salon</span>
                    </div>
                </div>
                <div className={styles.esJB}>
                    <div className={styles.esjbRow}>
                        <div className={styles.esjbCol}>
                            <div className={styles.esjbcE}>
                                <div className={styles.esjbceT}>
                                    Salary
                                </div>
                                <div className={styles.esjbceV}>
                                    &#8377;{data.salaryMin}-{data.salaryMax}/-
                                </div>
                            </div>
                        </div>

                        <div className={styles.esjbCol}>
                            <div className={styles.esjbcE}>
                                <div className={styles.esjbceT}>
                                    Food And accomodation
                                </div>
                                <div className={styles.esjbceV}>
                                    {data.fna}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default EachSimilarJob