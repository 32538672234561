import React from 'react'
import Collapsible from 'react-collapsible'
import { FaArrowRight, FaChevronRight, FaExpand, FaMinus, FaOpenid } from 'react-icons/fa'
import styles from './FAQsEachCourse.module.css'
import EachFAQCourse from './EachFAQCourse'

const FAQsEachCourse = () => {
    const faqs = [
        {
            question : "Is there any way to preview a course?",
            answer: "Yes! Learn how to preview a course, and review key information about it. This article outlines how you can search Stylelink's finely curated content, and find courses you’re interested in taking.!"
        },
        {
            question : "How can I buy a course?",
            answer: "Stylelink supports several different payment methods, depending on your account country and location."
        },
        {
            question : "What are your cancellation policy?",
            answer: "It's very easy to get refund!"
        },
        {
            question : "Where can I go for help?",
            answer: "If you find you have a question about a paid course while you’re taking it, you can search for answers to your question in the Q&A or ask the instructor.             Our Help Center has extensive information regarding Stylelink's various features, and articles with troubleshooting steps to help you quickly resolve any issues you encounter. Our support team is also happy to help."
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <div className={styles.faqHeader}>
                <div className={styles.smallTitle}>
                    <h3>FAQs </h3>
                </div>
                <div className={styles.bigTitle}>
                    <h2>Frequently Asked Questions</h2>
                </div>
            </div>
            <div className={styles.faqBody}>
                {faqs.map((item, index) => (
                    <div className={styles.eachFAQWrapper} key={index}>
                        <EachFAQCourse
                            question={item.question}
                            answer={item.answer}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FAQsEachCourse