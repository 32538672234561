import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import styles from '../Styles/Certificates/Verify.module.css';
import NavbarHandler from '../Helper/NavbarHandler';
import QRCode from "react-qr-code";
import { BASE_URL_API } from '../References/urls';
import { Helmet } from "react-helmet";


import ReactGA from 'react-ga';

const Verify = () => {
    const ref = useRef(null);
    let params = useParams();
    let certificateIdentifier = params.certificateIdentifier;
    const [isKeyValid, setIsKeyValid] = useState(false);
    const [isValidating, setIsValidating] = useState(true)
    const [certificateKey, setCertificateKey] = useState('https://stylelink.in/certificate/verify/' + certificateIdentifier)
    const [certificateData, setCertificateData] = useState([])
    // console.log(certificateIdentifier)

    const validateKey = async (key) => {
        setIsValidating(true);
        const validateKeyResponse = await fetch(BASE_URL_API + "/validateCertificateKey",
            {
                method: 'POST',
                body: JSON.stringify({
                    certificateKey: key
                })
            });

        if (!validateKeyResponse.ok) {
            console.log("Something went wrong!")
        } else {
            const validateKeyRespo = await validateKeyResponse.json();
            if (validateKeyRespo.status == "success") {
                setCertificateData(validateKeyRespo.response);
                setIsKeyValid(true);
            } else {
                setIsKeyValid(false)
            }
            console.log(validateKeyRespo.message);
        }
        setIsValidating(false)
    }

    useEffect(() => {
        validateKey(certificateIdentifier);

        ReactGA.pageview(window.location.pathname);
    }, [])

    useEffect(() => {
        if (isKeyValid) {
            setTimeout(() => {
                let el = ref.current.style.display = 'none';
            }, 5000);
        }
    }, [isKeyValid])



    if (isValidating) {
        return (
            <div className={styles.mainBodyWrapper}>
                <NavbarHandler type="home" />
                <div className={styles.validatingContent}>
                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1630782519/web-icons/Ajax-loader_w2minh.gif" />
                    <div>Hold On!</div>
                    <div>We are just validating your certificate...</div>
                </div>
            </div>
        )
    }

    if (!isKeyValid) {
        return (
            <div className={styles.mainBodyWrapper}>
                <NavbarHandler type="home" />
                <div className={styles.invalidKey}>
                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1621192438/wrongs_aktsc3.gif" />
                    <p>
                        That's not a valid certificate...
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainBodyWrapper}>
            <Helmet>
                <title>Achievement Certificate | Stylelink - India's best app for salon/makeup courses &amp; jobs</title>
                <meta name="description" content="Workshop completion certificate issued by Stylelink."></meta>
            </Helmet>
            <NavbarHandler type="home" />
            <section>
                <div className={styles.mainCertificate}>
                    <div className={styles.mainCertificateWrapper}>
                        <div className={styles.successParticles} ref={ref}>
                            <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666096682/Fireworks-animation--unscreen_wvgozy.gif" />
                        </div>
                        <div className={styles.mainOuterBorder}>
                            <div className={styles.objectsContainer}>
                                <div className={styles.leftShapeContainer}>
                                    <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666079979/logo-with-design_qeytwg.png" />
                                </div>
                                <div className={styles.centerShapeContainer}>
                                    {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666081258/certificate-design_vpshvg.png" /> */}
                                    <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666095574/certificate-design_t4ys5e.png" />
                                </div>
                                <div className={styles.rightShapeContainer}>
                                    <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666081258/2022-design_auiczq.png" />
                                </div>
                            </div>
                            <div className={styles.certificateOfIcon}>
                                {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666081258/certificate-design_vpshvg.png" /> */}
                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666095574/certificate-design_t4ys5e.png" />
                            </div>
                            <div className={styles.mainOuterBorderSec}>
                                <div className={styles.mainCertificateContent}>
                                    <div className={styles.upperRow}>
                                    </div>
                                    <div className={styles.centerRow}>
                                        <div className={styles.proudlyTitle}>
                                            <p>THIS CERTIFICATE IS PROUDLY PRESENTED TO</p>
                                        </div>
                                        <div className={styles.candiNameWrapper}>
                                            <div className={styles.candiName}>
                                                &nbsp;{certificateData.receiverName}&nbsp;
                                            </div>
                                        </div>
                                        <div className={styles.belowParaContainer}>
                                            <div className={styles.bpcFirst}>
                                                {certificateData.txtOne}
                                            </div>
                                            <div className={styles.bpcSecond}>
                                                {certificateData.txtTwo.substr(0, 46)}
                                                <br/>
                                                {certificateData.txtTwo.substr(46, 1000)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.scanToVerifyWrapper}>
                                        <div className={styles.stvwImg}>
                                            <QRCode
                                                size={80}
                                                style={{ height: "auto", maxWidth: "80px", width: "80px" }}
                                                value={certificateKey}
                                                viewBox={`0 0 80 80`}
                                                fgColor="#ffffff86"
                                                bgColor="black"
                                            />
                                        </div>
                                        <div className={styles.ebrCaptionScan}>
                                            Scan To Verify
                                        </div>
                                    </div>
                                    <div className={styles.bottomRow}>
                                        <div className={styles.eachBtmRow}>
                                            <div className={styles.ebrDate}>
                                                {certificateData.offeredDate}
                                            </div>
                                            <div className={styles.ebrDivider}></div>
                                            <div className={styles.ebrCaption}>
                                                DATE
                                            </div>
                                        </div>
                                        <div className={styles.scanToVerifyRow}>
                                            <div>
                                                <QRCode
                                                    size={100}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={certificateKey}
                                                    viewBox={`0 0 100 100`}
                                                    fgColor="#ffffff86"
                                                    bgColor="black"
                                                />
                                            </div>
                                            <div className={styles.ebrCaptionScan}>
                                                Scan To Verify
                                            </div>
                                        </div>
                                        <div className={styles.eachBtmRow}>
                                            <div className={styles.sign}>
                                                <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1666092847/Signature/manmeet_mam-2_tou0kx.png" />
                                            </div>
                                            <div className={styles.ebrDivider}></div>
                                            <div className={styles.ebrCaption}>
                                                SIGNATURE
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className={styles.footerWrapper}>
                footer
            </div> */}
        </div>
    )
}

export default Verify