import React from 'react'
import { Link } from 'react-router-dom'
import styles from './OurAlliesPLPClassroomCourses.module.css'

const OurAlliesPLPClassroomCourses = props => {
    const { ourAllies } = props
    // console.log(ourAllies)


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.mainHeader}>
                    <h3>{ourAllies?.title}</h3>
                </div>
                <div className={styles.subTitle}>
                    <h6>{ourAllies?.subTitle}</h6>
                </div>
            </div>
            <div className={styles.rowWrapper}>
                {ourAllies?.rows?.map((item, index) => (
                    <div key={index} className={(index % 2) === 0 ? styles.movingRowWrapper : styles.oppMovingRowWrapper}>
                        {item?.map((itemOfRow, index) => (
                            <div className={styles.eachBrand} key={index}>
                                <img src={itemOfRow?.logo} title={itemOfRow?.brandName} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className={styles.viewAllWrapper}>
                <Link to="/brand-partners/makeup">
                    <div className={styles.viewAllBtn}>
                        View All
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default OurAlliesPLPClassroomCourses