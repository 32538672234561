import React from 'react'
import { useLottie } from 'lottie-react';
import locationAnimation from '../Json/locationAnimation.json'

const LocationAI = () => {
    const options = {
        animationData: locationAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default LocationAI