import React, { useEffect } from 'react'
import { BsCheck, BsUpcScan } from 'react-icons/bs'
import { MdAccessTime, MdAssignmentReturn, MdInfoOutline, MdOutlineDangerous, MdVerified } from 'react-icons/md'
import { Link } from 'react-router-dom'
import OnlyCopyright from '../../Components/Footers/OnlyCopyright'
import FBHeader from './FBHeader'
import styles from './SubscriptionsPricing.module.css'

const SubscriptionsPricing = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <FBHeader />
            <div className={styles.mainPageContent}>
                <div className={styles.mainTitleHeader}>
                    <div className={styles.mainTitle}>
                        <h2>Customize Pricing for your Salon Business</h2>
                    </div>
                    <div className={styles.mainSubTitle}>
                        <h4>Start with a plan that benefits your business.</h4>
                    </div>
                </div>

                <div className={styles.plansWrapper}>
                    <div className={styles.eachPlanWrapper}>
                        <div className={styles.eachPlanContainer}>
                            <div className={styles.eachPlanHeader}>
                                <div className={styles.recommendedTxt}>
                                    Recommended
                                </div>
                            </div>
                            <div className={styles.eachPlanBody}>
                                <div className={styles.planFor}>
                                    For Small / Individual Salons
                                </div>
                                <div className={styles.planName}>
                                    <h4>Plus</h4>
                                </div>
                                <div>
                                    <div className={styles.priceQuote}>
                                        ₹0 / month
                                    </div>
                                    <div>
                                        No commitment
                                    </div>
                                </div>
                                <div className={styles.startBtn}>
                                    <Link to="/hire-salon-staff/quickly?plan=plus">Start Now</Link>
                                </div>

                                <div className={styles.placementValueWrapper}>
                                    <div className={styles.placementFeeTitle}>
                                        Hiring Fee
                                    </div>
                                    <div className={styles.placementFeeValue}>
                                        ₹2499/- per hiring
                                    </div>
                                </div>

                                <div className={styles.featuresWrapper}>
                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdAssignmentReturn />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            21 days replacement
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdAccessTime />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Placement within 3 days
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <BsUpcScan />

                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Pre-scanning interviews only
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className={styles.eachPlanFooter}>

                            </div>
                        </div>
                    </div>

                    <div className={styles.eachPlanWrapper}>
                        <div className={styles.eachPlanContainer}>
                            <div className={styles.eachPlanHeader}>
                                <div className={styles.recommendedTxt}>
                                    Recommended
                                </div>
                            </div>

                            <div className={styles.eachPlanBody}>
                                <div className={styles.planFor}>
                                    For Premium / Unisex Salon
                                </div>
                                <div className={styles.planName}>
                                    <h4>Essential</h4>
                                </div>
                                <div>
                                    <div className={styles.priceQuote}>
                                        ₹1000 / month
                                    </div>
                                    <div>
                                        Billed annually
                                    </div>
                                </div>
                                <div className={styles.startBtn}>
                                    <Link to="/hire-salon-staff/quickly?plan=essential">Try for free</Link>
                                </div>
                                <div className={styles.placementValueWrapper}>
                                    <div className={styles.placementFeeTitle}>
                                        Hiring Fee
                                    </div>
                                    <div className={styles.placementFeeValue}>
                                        ₹1499/- per hiring
                                    </div>
                                </div>
                                <div className={styles.featuresWrapper}>
                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdAssignmentReturn />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            60 days replacement
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdAccessTime />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Placement within 48 hours
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdVerified />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Verification & Background check
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <BsUpcScan />

                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Pre-scanning interviews & Demo
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className={styles.eachPlanFooter}>

                            </div>
                        </div>
                    </div>

                    <div className={styles.eachPlanWrapper}>

                        <div className={styles.eachPlanContainer}>
                            <div className={styles.eachPlanHeader}>
                                <div className={styles.recommendedTxt}>
                                    Recommended
                                </div>
                            </div>


                            <div className={styles.eachPlanBody}>
                                <div className={styles.planFor}>
                                    For Premium Salon with store upto 20
                                </div>
                                <div className={styles.planName}>
                                    <h4>Business</h4>
                                </div>
                                <div>
                                    <div className={styles.priceQuote}>
                                        ₹2499 / month
                                    </div>
                                    <div>
                                        Billed annually
                                    </div>
                                </div>
                                <div className={styles.startBtn}>
                                    <Link to="/hire-salon-staff/quickly?plan=business">Try for free</Link>
                                </div>

                                <div className={styles.placementValueWrapper}>
                                    <div className={styles.placementFeeTitle}>
                                        Hiring Fee
                                    </div>
                                    <div className={styles.placementFeeValue}>
                                        ₹999/- per hiring
                                    </div>
                                </div>

                                <div className={styles.featuresWrapper}>
                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdAssignmentReturn />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            90 days replacement
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdAccessTime />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Placement within 48 hours
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <MdVerified />
                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Verification & Background check
                                        </div>
                                    </div>

                                    <div className={styles.eachFeature}>
                                        <div className={styles.eachFeatureIcon}>
                                            <BsUpcScan />

                                        </div>
                                        <div className={styles.eachFeatureTxt}>
                                            Pre-scanning interviews & Demo
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className={styles.eachPlanFooter}>

                            </div>

                        </div>

                    </div>

                </div>

                <div className={styles.largeWrapper}>
                    <div className={styles.largeContainer}>
                        <div className={styles.largeContentIcon}>
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/contact.svg" />
                        </div>
                        <div>
                            <div className={styles.enterpriseTxt}>Enterprise</div>
                            <div className={styles.enterpriseSubTxt}>
                                Customize to your business with enterprise-grade security,
                                integrations with best-in-class security solutions, and live support
                                from dedicated experts.
                            </div>
                            <div className={styles.letsTalk}>
                                <Link to="/hire-salon-staff/quickly?plan=enterprise">
                                    Let's talk
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.compareWrapper}>
                    <div className={styles.compareHeader}>
                        <h4>Compare plans</h4>
                    </div>
                    <div className={styles.comparePlansContent}>
                        <div className={styles.compareHeaderTilesWrapper}>
                            <div className={styles.eachCompareHeaderTilesCaptionWrapper}>
                                -
                            </div>
                            <div className={styles.eachCompareHeaderTilesWrapper}>
                                Plus
                            </div>
                            <div className={styles.eachCompareHeaderTilesWrapper}>
                                Essentials
                            </div>
                            <div className={styles.eachCompareHeaderTilesWrapper}>
                                Business
                            </div>
                        </div>
                        <div className={styles.compareStatusActionWrapper}>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Placement Fee
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    ₹2499
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    ₹1499
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    ₹999
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Replacement Period
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    21 Days
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    60 Days
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    90 Days
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Placement Within
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    3 Days*
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    48 Hours
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    48 Hours
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Annual Fee
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    ₹0
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    ₹12000
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    ₹29988
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Pre-scanning Interviews
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Pre-scanning Demo / Trial
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />

                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Employee Verification
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Background Check
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Offer letters
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Agreements
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Payroll Management
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Performance Reports
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Training
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>
                            <div className={styles.eachCompareStatusWrapper}>
                                <div className={styles.statusActionCaptionWrapper}>
                                    <div>
                                        Dedicated Support
                                    </div>
                                    <div className={styles.sacwIcon}>
                                        <MdInfoOutline />
                                    </div>
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <MdOutlineDangerous size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                                <div className={styles.statusActionWrapper}>
                                    <BsCheck size={28} />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <OnlyCopyright />

            </div>

        </div>
    )
}

export default SubscriptionsPricing