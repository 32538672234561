import React from 'react'
import { CgBookmark } from "react-icons/cg";
import { formatDateToReadable } from '../../References/Helpers/jarvis-jr';
import styles from './EachJobCard.module.css'

const EachJobCard = (props) => {
    const { bgColor, item } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.bodyWrapper} style={{backgroundColor : bgColor}}>
                        <div className={styles.dateWrapper}>
                            <div className={styles.dateTag}>
                                {formatDateToReadable(item?.postedOn)}
                            </div>
                            <div className={styles.bookmarkTag}>
                                <CgBookmark size={24} />
                            </div>
                        </div>

                        <div>
                            <div className={styles.storeName}>
                                <p>{item?.storeName}</p>
                            </div>
                            <div className={styles.profileWrapper}>
                                <div className={styles.profile}>
                                    <h3>{item?.jobTitle}</h3>
                                </div>
                            </div>
                        </div>

                        <div className={styles.benefitWrapper}>
                            <div className={styles.benefitContainer}>
                                <div className={styles.eachBenefitContainer}>{item?.fna}</div>
                                <div className={styles.eachBenefitContainer}>Education Qualifications is {item?.educationQualifications}</div>
                                <div className={styles.eachBenefitContainer}>{item?.experienceRequired} experience required</div>
                                <div className={styles.eachBenefitContainer}>{item?.whoCanApply} can apply</div>
                            </div>

                        </div>

                    </div>
                    <div className={styles.footerWrapper}>
                        <div className={styles.footerContainer}>
                            <div>
                                <div className={styles.salary}>₹{item?.maxSalary}/month</div>
                                <div className={styles.address}>
                                    {item?.storeAddress}
                                </div>
                            </div>
                            <div>
                                <div className={styles.apply}>Apply</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachJobCard