import React from 'react'
import styles from '../Styles/Components/ReadMoreBtn.module.css'

let width = window.innerWidth;

const ReadMoreBtn = props => {
    console.log(props, width)

    return (
        <div className={styles.readMoreContainer} style={{color : props.color, width: props.webW}} >
            <div style={{width: (width>600 ? props.webW : props.mobW)}} className={styles.readMoreBtn} >
                <div className={styles.readMoreTxt}>
                    Read more 
                </div>
                <div className={styles.rmIcon}>
                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1649160157/web-icons/img_418607_lxiv4r.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default ReadMoreBtn