import React from 'react'
import styles from './HairPLPWhyChoose.module.css'

const HairPLPWhyChoose = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainTitle}>
                <h4>Why you should choose stylelink hair course?</h4>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.uspContainer}>
                    <div className={styles.eachUspCard}>
                        <div></div>
                        <div><h4>In-salon Training</h4></div>
                        <div className={styles.content}>
                            <p>Master industry-ready skills with weekend, in-salon training.</p>
                        </div>
                    </div>

                    <div className={styles.eachUspCard}>
                        <div></div>
                        <div><h4>International Internships</h4></div>
                        <div className={styles.content}><p>Unlock global opportunities with our international internships.</p></div>
                    </div>

                    <div className={styles.eachUspCard}>
                        <div></div>
                        <div><h4>100% guaranteed placement</h4></div>
                        <div className={styles.content}><p>With earnings upto ₹1,00,000 per month</p></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HairPLPWhyChoose