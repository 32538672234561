import React from 'react'
import { useLottie } from 'lottie-react';
import trainYourStaffAnimation from '../Json/trainYourStaffAnimation.json'

const TrainYourStaffAI = () => {
    const options = {
        animationData: trainYourStaffAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default TrainYourStaffAI