import React, { useEffect, useState } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import styles from './CitySelector.module.css'

const CitySelector = (props) => {
    const { isOpen, onClose, selectCityHandler, selectedCity, idToRef } = props
    const [ourOperatingAreas, setOurOperatingAreas] = useState(["Delhi", "Noida", "Ghaziabad", "Lucknow", "Gurugram"])
    const [position, setPosition] = useState(0)
    const [searchBarWidth, setSearchBarWidth] = useState(0)
    const [searchBarHeight, setSearchBarHeight] = useState(0)
    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const getOperatingAreas = async () => {
        const getOperatingAreasResponse = await fetch(BASE_URL_API + "/getOperatingAreas",
            {
                method: "POST",
                body: JSON.stringify({

                })
            });

        if (!getOperatingAreasResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getOperatingAreasRespo = await getOperatingAreasResponse.json()
            if (getOperatingAreasRespo.status === "success") {
                setOurOperatingAreas(getOperatingAreasRespo?.response)
                setFilteredData(getOperatingAreasRespo?.response)
            } else {
                setOurOperatingAreas([])
                setFilteredData([])
            }
            console.log(getOperatingAreasRespo)
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.getElementsByTagName("body")[0].classList.add("no-scroll")

            const pos = document.getElementById(idToRef)?.getBoundingClientRect().y
            const height = document.getElementById(idToRef)?.offsetHeight
            // const height = document.getElementById("masterSearchJobWrapper")?.getBoundingClientRect()?.height
            const width = document.getElementById(idToRef)?.getBoundingClientRect()?.width
            // setPosition(pos+height)
            setPosition(pos)
            setSearchBarHeight(height)
            setSearchBarWidth(width)
            getOperatingAreas()
            // console.log(pos, "height : ", height, "width : " + width)
        }
    }, [])

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            // Do something when the Escape key is pressed
            onClose()
        }
    });

    window.onclick = function (event) {
        if (event.target.id === "mwcs") {
            onClose()
        }
    }


    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            item.toString().toLowerCase().includes(searchKey.toLowerCase())
        ));
    };

    useEffect(() => {
        filterData(ourOperatingAreas, searchKey)
    }, [searchKey])


    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#f0f0f0");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#f0f0f0";
            document.head.appendChild(meta);
        }
    }, []);


    console.log(searchBarWidth)



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="mwcs">
                    <div className={styles.mainContent} style={{ marginTop: position + "px", width: searchBarWidth + "px" }}>
                        <div className={styles.mainHeader} style={{ height: searchBarHeight + "px" }}>
                            <div className={styles.profileInputContainer} onClick={() => onClose()}>
                                <IoArrowBack size={24} />
                            </div>
                            <div className={styles.cityInputContainer}>
                                <input autoFocus={true} placeholder="Search for city..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                            </div>
                            <div className={styles.searchBtnContainer}>
                                <Link to={`/jobs`}>Find Jobs</Link>
                            </div>
                        </div>
                        <div
                            className={styles.citiesWrapper}
                            style={{
                                height: `calc(40vh - 10px - ${searchBarHeight}px)`
                            }}
                        >
                            <div className={styles.eachCity}
                                onClick={() => selectCityHandler('Anywhere')}
                            >
                                Anywhere in India
                            </div>
                            {filteredData?.map((item, index) => (
                                <div key={index} className={styles.eachCity}
                                    onClick={() => selectCityHandler(item)}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default CitySelector