import React from 'react'
import { useLottie } from 'lottie-react';
import realTimeTrackingAnimation from '../Json/realTimeTrackingAnimation.json'

const RealTimeTrackingAI = () => {
    const options = {
        animationData: realTimeTrackingAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default RealTimeTrackingAI