import React from 'react'
import PLPEachFAQ from './PLPEachFAQ'
import styles from './PLPFAQWrapper.module.css'

const PLPFAQWrapper = props => {
    const { faqs } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <h3>
                    Frequently Asked Questions (FAQs)
                </h3>
            </div>
            <div className={styles.mainBody}>
                {faqs?.map((item, index) => (
                    <PLPEachFAQ key={index} item={item} />
                ))}
            </div>
        </div>
    )
}

export default PLPFAQWrapper