import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { BASE_URL_API } from '../References/urls'
import styles from '../Styles/Components/SimilarJobs.module.css'
import EachSimilarJob from './EachSimilarJob'

const SimilarJobs = props => {
    const [similarJobs, setSimilarJobs] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let identifier = props.identifier;
    let catIdentifier = props.catIdentifier;

    const getSimilarJobs = async () => {
        if (identifier !== "" && catIdentifier !== "") {
            setIsLoading(true);
            const getSimilarJobsResponse = await fetch(BASE_URL_API + "/getSimilarJobs",
                {
                    method: "POST",
                    body: JSON.stringify({
                        jobId: identifier,
                        categoryId: catIdentifier
                    })
                });

            if (!getSimilarJobsResponse.ok) {
                console.log("Something went wrong, please contact support!");
            } else {
                const getSimilarJobsRespo = await getSimilarJobsResponse.json();
                if (getSimilarJobsRespo.status === "success") {
                    setSimilarJobs(getSimilarJobsRespo.response);
                } else {
                    console.log(getSimilarJobsRespo);
                }
            }
            setIsLoading(false);
        } else {
            console.log("Either identifier or cat identifier missing", identifier, catIdentifier);
        }
    }

    useEffect(() => {
        getSimilarJobs();
    }, [identifier])

    

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainTitle}>
                <h4>
                    Similar jobs that you might like
                </h4>
            </div>
            <div className={styles.similarJobsBody}>
                {isLoading ?
                    <div>
                        <ShimmerThumbnail height={220} className="m-0" rounded />
                    </div>
                    :
                    similarJobs != null ?
                        similarJobs.map((item, index) => (
                            <EachSimilarJob data={item} key={index} />
                        ))
                        :
                        <div>
                            No similar jobs found!
                        </div>
                }
            </div>
            <div className={styles.similarJobsFooter}>
                <Link to="/jobs">
                    See Other Jobs
                </Link>
            </div>
        </div>
    )
}

export default SimilarJobs