import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { SiGooglescholar } from 'react-icons/si';
import { Link } from 'react-router-dom'
import PLPFAQWrapper from '../../../Components/Classroom/PLPFAQWrapper';
import OnlyCopyright from '../../../Components/Footers/OnlyCopyright';
import styles from './HairPLP.module.css'
import HairPLPWhyChoose from './HairPLPWhyChoose';

export const HairPLP = () => {
    const pageTitle = "Best hair dressing / styling course in India : Stylelink";
    const pageDescription = "Best hair dressing and styling course academy in Delhi NCR, India are being offered by Stylelink Academy in Noida with 100% job guarantee."
    const pageKeywords = "hair dressing / styling courses, professional hair dressing and hair styling class, courses hair dressing, hair academy, hair styling course, hair styling, hair dressing courses near me, best hair dressing / styling training near me, hair academies in delhi, bollywood hair styling specialist"
    const [visible, setVisible] = useState(false);
    const faqs = [
        {
            question: "What sets your hairdressing and styling courses apart from others in the market?",
            answer: "Our courses are designed to meet international standards, preparing our students to excel in the hairdressing and styling industry."
        },
        {
            question: "What makes Stylelink Academy the best hairdressing/styling school in Noida?",
            answer: "Stylelink Academy is one of the most luxurious hairdressing/styling schools in Noida, with a team of highly educated and experienced trainers. We offer a comprehensive curriculum that is designed to make students job-ready and internationally recognized. We also provide 100% guaranteed job placement."
        },
        {
            question: "How do I enroll in the hairdressing/styling course at Stylelink Academy?",
            answer: "To enroll in the hairdressing/styling course at Stylelink Academy, please visit our website or call our support team."
        },
        {
            question: "Do you provide job placement assistance for graduates of your hairdressing courses?",
            answer: "We go beyond assistance and offer guaranteed job placements to our graduates. With a track record of six years, we have strong industry connections."
        },
        {
            question : "Are international students welcome to enroll in your hairdressing courses?",
            answer : "Yes, we welcome both local and international students who are passionate about hairdressing and styling."
        },
        {
            question : "What is the duration of your hairdressing and styling courses, and what are the class schedules?",
            answer : "The duration and class schedules vary based on the specific course you choose. Details can be found on our website or by contacting our admissions team."
        },
        {
            question : "What are the costs of your hairdressing and styling courses, and do you offer financial assistance or scholarships?",
            answer : "Course costs vary, and we do offer financial assistance and scholarships to eligible students. Details can be found on our website or by speaking with our admissions team."
        }
    ]
    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const x = 250; // Adjust the number of pixels for visibility as needed
            const y = 100; // Adjust the number of pixels before reaching the bottom as needed

            const scrollPosition = window.scrollY;

            // Show the component if scrolled more than triggerScroll pixels
            if (scrollPosition > x && scrollPosition < documentHeight - windowHeight - y) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <div className={styles.mainWrapper}>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription}></meta>
                <meta name="keywords" content={pageKeywords} ></meta>

                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="whatsapp:url" content={`https://stylelink.in/classroom-courses/hair-dresser-courses`} />
                <meta name="whatsapp:title" content={pageTitle} />
                <meta name="whatsapp:description" content={pageDescription} />
                <meta name="whatsapp:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />

                <meta name="telegram:url" content={`https://stylelink.in/classroom-courses/hair-dresser-courses`} />
                <meta name="telegram:title" content={pageTitle} />
                <meta name="telegram:description" content={pageDescription} />
                <meta name="telegram:image" content="https://stylelink.s3.ap-south-1.amazonaws.com/meta-images/makeup-course-meta-img.webp" />


                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                                "@type": "Question",
                                "name": "${faqs[0]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[0].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[1]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[1].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[2]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[2].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[3]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[3].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[4]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[4].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[5]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[5].answer}"
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "${faqs[6]?.question}",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${faqs[6].answer}"
                                }
                            },
                        ]
                        }
                    `}
                </script>



            </Helmet>
            <Link
                to="enroll-now"
                className={visible ? styles.stickyBtn : styles.stickyBtnHidden}
            >
                <div className={visible ? styles.hireNowSticky : styles.hireNowStickyHidden}>
                    Enroll Now
                </div>
            </Link>

            <div className={styles.firstPaintWrapper}>
                <div className={styles.firstPaintContainer}>
                    <div className={styles.bgContainer}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/unique-hair-style.png" />
                    </div>
                    <div className={styles.fpContentContainer}>
                        <div className={styles.fpContentMain}>
                            <div className={styles.fpUpperContent}>
                                <div className={styles.reinventingTxt}>
                                    <h3>Re-Inventing India's Hair culture</h3>
                                </div>
                                <div className={styles.mainTitle}>
                                    <h1 className={styles.webView}>Be a pro hair stylist</h1>
                                    <h1 className={styles.mobileView}>Be a pro <br></br> hair stylist</h1>
                                </div>
                                <div className={styles.oneTxt}>
                                    <p>at India's #1 hair academy</p>
                                </div>
                            </div>
                            <div className={styles.fpLowerContent}>
                                <Link to="/classroom-courses/hair-dresser-courses/enroll-now">
                                    <div className={styles.enrollNow}>
                                        Enroll Now
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.secondPaintWrapper}>
                <div className={styles.spTitle}>
                    <h2 className={`${styles.sptFirstTxt} ${styles.webView}`}>
                        Express yourself with our team.
                    </h2>
                    <h2 className={`${styles.sptFirstTxt} ${styles.mobileView}`}>
                        Express yourself <br></br> with our team.
                    </h2>
                    <h2 className={styles.sptSecondTxt}>
                        Become a professional hair dresser.
                    </h2>
                    <h5 className={styles.sptThirdTxt}>
                        Start your career with earnings upto ₹1,00,000 / month.
                    </h5>
                    <p className={styles.scholarship}>Enroll Now to get extra 10% scholarship.</p>
                    <Link to="enroll-now">
                        <div className={styles.enrollNowLink}>Enroll Now</div>
                    </Link>
                </div>

                <div className={styles.enroll}>
                    <SiGooglescholar size={100} />
                </div>
            </div>

            <HairPLPWhyChoose />

            {/* 
            <div className={styles.thirdPaintWrapper}>

            </div> */}
            <PLPFAQWrapper faqs={faqs} />

            <OnlyCopyright />
        </div>
    )
}
