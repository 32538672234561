import React from 'react'
import styles from '../Components/PastWorkshopsSection.module.css'
import EachPastWorkshopCard from './EachPastWorkshopCard';

const PastWorkshopsSection = props => {
    let data = props.data;
    return (
        <section className={styles.mainWrappper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.mainTitle}>
                        Past workshops
                    </div>
                </div>
                <div className={styles.mainBody}>
                    {data.filter((item) => new Date(item.startingTime).toISOString() < new Date().toISOString()).sort((a, b) => { if (a.startingTime < b.startingTime) { return 1 } else if (a.startingTime > b.startingTime) { return -1 } else { return 0 } }).map((itemPer, index) => (
                        <EachPastWorkshopCard
                            data={itemPer}
                            key={index}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default PastWorkshopsSection