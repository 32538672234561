import React, { useEffect, useState } from 'react'
import { BsFilterRight } from "react-icons/bs";
import { FaChevronDown, FaChevronUp, FaFilter, FaSearch } from 'react-icons/fa';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';
import CitySelector from '../../Components/Jobs/CitySelector';
import EachJobCard from '../../Components/Jobs/EachJobCard'
import ProfileAndCitySelectorHolder from '../../Components/Jobs/ProfileAndCitySelectorHolder';
import ProfileSelector from '../../Components/Jobs/ProfileSelector';
import { BASE_URL_API } from '../../References/urls';
import styles from './JobsListingCategorised.module.css'

import { MdRadioButtonUnchecked, MdRadioButtonChecked } from "react-icons/md";
import SlideInContent from '../../Components/UI/SlideInContent';


import {
    ShimmerThumbnail
} from "react-shimmer-effects";


const JobsListingCategorised = () => {
    const { routeIdentifier } = useParams()
    const [colors, setColors] = useState(['#FFE1CC', '#D5F6ED', '#E4DBFA', '#E0F2FE', '#FBE2F4', '#EDEFF5'])
    const [jobsRecords, setJobsRecords] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    const [isGettingJobs, setIsGettingJobs] = useState(false)

    const [profiles, setProfiles] = useState([])
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [isProfileAndCitySelectorOpen, setIsProfileAndCitySelectorOpen] = useState(false)
    const [isCitySelectorActive, setIsCitySelectorActive] = useState(false)
    const [selectedCity, setSelectedCity] = useState('')
    const [forwardingUrl, setForwardingUrl] = useState('')
    const [isProfileSelectorActive, setIsProfileSelectorActive] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState('')

    const [selectedSalaryRange, setSelectedSalaryRange] = useState('')
    const [selectedFnA, setSelectedFnA] = useState('')

    const citySelectorToggler = () => {
        setIsCitySelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    const selectCityHandler = (k) => {
        setSelectedCity(k)
        setIsCitySelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }
    const profileSelectorToggler = () => {
        setIsProfileSelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    const selectProfileHandler = (k) => {
        setSelectedProfile(k)
        setIsProfileSelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    const generateLandingUrl = () => {
        let fu = (selectedProfile?.label === 'Any') ? 'salon-jobs' : `${selectedProfile?.label || 'salon'}-jobs`;

        if (selectedProfile?.label === 'Any' && (!selectedCity || selectedCity.toLowerCase() === 'near-me')) {
            fu += '-near-me';
        } else if (selectedProfile?.label !== 'Any') {
            if (!selectedCity || selectedCity.toLowerCase() === 'near-me') {
                fu += '-near-me';
            } else if (selectedCity.toLowerCase() !== 'anywhere') {
                fu += `-in-${selectedCity}`;
            }
        }

        fu = fu.toLowerCase().trim()
            .replace(/[^a-z0-9\s-]/g, '')  // Remove special characters except spaces and hyphens
            .replace(/\s+/g, '-')          // Replace spaces with hyphens
            .replace(/-$/, '');            // Remove trailing hyphen if any

        setForwardingUrl(fu);
    };

    useEffect(() => {
        generateLandingUrl()
    }, [selectedCity, selectedProfile])



    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#f0f0f0");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#f0f0f0";
            document.head.appendChild(meta);
        }
    }, []);

    const getJobCategories = async () => {
        const getJobCategoriesResponse = await fetch(BASE_URL_API + "/getJobCategories",
            {
                method: "POST",
                body: JSON.stringify({

                })
            });

        if (!getJobCategoriesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobCategoriesRespo = await getJobCategoriesResponse.json()
            if (getJobCategoriesRespo.status === "success") {
                setProfiles(getJobCategoriesRespo?.response)
            } else {
                setProfiles([])
            }
        }
    }

    useEffect(() => {
        getJobCategories()
    }, [])

    const closeProfileAndCitySelectorHandler = () => {
        setIsProfileAndCitySelectorOpen(false)
    }

    useEffect(() => {
        setIsProfileAndCitySelectorOpen(false)
        getCategorizedJobs()
    }, [routeIdentifier])


    const getCategorizedJobs = async () => {
        setIsGettingJobs(true)
        const getCategorizedJobsResponse = await fetch(BASE_URL_API + "/getCategorizedJobs",
            {
                method: "POST",
                body: JSON.stringify({
                    routeIdentifier: routeIdentifier
                })
            });

        if (!getCategorizedJobsResponse.ok) {
            console.log('Something went wrong : Server Error')
        } else {
            const getCategorizedJobsRespo = await getCategorizedJobsResponse.json()
            if (getCategorizedJobsRespo.status === "success") {
                setJobsRecords(getCategorizedJobsRespo?.response)
            } else {
                setJobsRecords([])
            }
        }
        setIsGettingJobs(false)
    }



    // console.log(profiles)


    return (
        <div className={styles.mainWrapper}>
            {isProfileAndCitySelectorOpen && <ProfileAndCitySelectorHolder isOpen={isProfileAndCitySelectorOpen} onClose={closeProfileAndCitySelectorHandler} />}

            {isCitySelectorActive && <CitySelector
                isOpen={isCitySelectorActive}
                onClose={() => citySelectorToggler()}
                selectCityHandler={selectCityHandler}
                selectedCity={selectedCity}
                idToRef="jlcsc"
            />}
            {isProfileSelectorActive && <ProfileSelector
                isOpen={isProfileSelectorActive}
                onClose={() => profileSelectorToggler()}
                selectProfileHandler={selectProfileHandler}
                selectedProfile={selectedProfile}
                idToRef="jlcsc"
            />}


            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.mainHeaderContainer}>
                        <div className={styles.upperTxt}>
                            {routeIdentifier?.replaceAll("-", " ")}
                        </div>
                        <div className={styles.searchWrapper}>
                            <div className={styles.searchContainer} id="jlcsc">
                                <div className={styles.profileSearchContainer}>
                                    <div className={styles.inputWrapper}>
                                        <input placeholder="Search for job profile..."
                                            onFocus={() => setIsProfileSelectorActive(true)}
                                            value={selectedProfile?.label}
                                        />
                                    </div>
                                </div>
                                <div className={styles.citySearchContainer}>
                                    <div className={styles.inputWrapper}>
                                        <input placeholder="Search for city..."
                                            onFocus={() => setIsCitySelectorActive(true)}
                                            value={selectedCity}
                                        />
                                    </div>
                                </div>
                                <div className={styles.profileSearchContainerSmall}>
                                    <div className={styles.inputWrapper}>
                                        <input placeholder="Search for job profile..."
                                            onFocus={() => setIsProfileAndCitySelectorOpen(true)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.citySearchContainerSmall}>
                                    <div className={styles.inputWrapper}>
                                        <input placeholder="Search for city..."
                                            onFocus={() => setIsProfileAndCitySelectorOpen(true)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.findJobContainer}>
                                    <Link to={`/${forwardingUrl}`}>Find Jobs</Link>
                                </div>
                                <div className={styles.findJobContainerMobile}>
                                    <Link to={`/`}>
                                        <FaSearch size={20} />
                                    </Link>
                                </div>
                            </div>
                            <div className={styles.filterIconSmall}>
                                <div>

                                    <div className={styles.filterIconSmallBox}>
                                        <FaFilter size={20} color="white" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.bodyWrapper}>
                    <div className={styles.bodyContainer}>
                        <div className={styles.leftColWrapper}>
                            <div>
                                <div className={styles.filterRight}>Salary Range</div>
                                <div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedSalaryRange('0-10000')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedSalaryRange === '0-10000' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            Below ₹10000
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedSalaryRange('10001-15000')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedSalaryRange === '10001-15000' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            ₹10,001 - ₹15,000
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedSalaryRange('15001-20000')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedSalaryRange === '15001-20000' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            ₹15,001 - ₹20,000
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedSalaryRange('20001-25000')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedSalaryRange === '20001-25000' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            ₹20,001 - ₹25,000
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedSalaryRange('25001-30000')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedSalaryRange === '25001-30000' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            ₹25,001 - ₹30,000
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedSalaryRange('35000-450000')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedSalaryRange === '35000-450000' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            Above ₹35,000
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className={styles.filterRight}>Food And Accomodation</div>
                                <div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedFnA('1-Time Food')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedFnA === '1-Time Food' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            1-Time Food
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedFnA('2-Time Food')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedFnA === '2-Time Food' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            2-Time Food
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedFnA('1-Time Food With Accomodation')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedFnA === '1-Time Food With Accomodation' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            1-Time Food With Acc..
                                        </div>
                                    </div>
                                    <div className={styles.eachSalaryRange}
                                        onClick={() => setSelectedFnA('2-Time Food With Accomodation')}
                                    >
                                        <div className={styles.esrBtn}>
                                            {selectedFnA === '2-Time Food With Accomodation' ?
                                                <MdRadioButtonChecked size={20} />
                                                :
                                                <MdRadioButtonUnchecked size={20} />
                                            }
                                        </div>
                                        <div className={styles.esrTxt}>
                                            2-Time Food With Acc..
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div className={styles.rightColWrapper}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.leftHeaderWrapper}>
                                    <div className={styles.jobTagTitle}>
                                        <h3>
                                            Popular jobs
                                        </h3>
                                    </div>
                                    <div className={styles.jobCount}>
                                        <div className={styles.jobCountTag} >{jobsRecords?.length}</div>
                                    </div>
                                </div>
                                <div className={styles.rightHeaderWrapper}>
                                    <div className={styles.rightHeaderContainer}>
                                        <div className={styles.sortOutTag}>
                                            Sort By
                                        </div>
                                        <div className={styles.dropdownWrapper}>
                                            <select>
                                                <option>Distance</option>
                                                <option>Salary Highest First</option>
                                                <option>Salary Lowest First</option>
                                            </select>
                                        </div>
                                        <div className={styles.filterIconWrapper}>
                                            <div className={styles.filterIcon}>
                                                <BsFilterRight size={22} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.jobsHolder}>
                                {isGettingJobs === true ?
                                    <>
                                        {jobsRecords?.map((item, index) => (
                                            <div className={styles.eachJobCardWrapperLoader} key={index}>
                                                <ShimmerThumbnail rounded fitOnFrame={true} width='100%' />
                                            </div>
                                        ))}
                                    </>
                                    :
                                    jobsRecords?.length > 0 ?
                                        <>
                                            {jobsRecords?.map((item, index) => (
                                                <Link to={`/job/${item?.jobRoute}`} className={styles.eachJobCardWrapper} key={index}>
                                                    <SlideInContent>
                                                        <EachJobCard bgColor={colors[index % colors.length]} item={item} />
                                                    </SlideInContent>
                                                </Link>
                                            ))}
                                        </>
                                        :
                                        <div className={styles.thatsAllWrapper}>
                                            No jobs available in this category!
                                        </div>
                                }
                                <div className={styles.thatsAllWrapper}>
                                    That's it!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default JobsListingCategorised