import React from 'react'
import { useLottie } from 'lottie-react';
import calendarAnimation from '../Json/calendarAnimation.json'

const CalendarAI = () => {
    const options = {
        animationData: calendarAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div>
            {View}
        </div>
    )
}

export default CalendarAI