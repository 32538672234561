import React from 'react'
import styles from '../Components/MissedCallCard.module.css'

const MissedCallCard = props => {
    return (
        <div className={styles.userLoginCard}>
            <div className={styles.personProfilePic}>
                <img src="https://res.cloudinary.com/iamironman/image/upload/v1682579786/web-icons/phone-icon_scbicj.png" />
            </div>
            <div className={styles.personDetails}>
                <h4>Give us a missed call</h4>
                <p>+91-8287-09-8287</p>
            </div>
            <div className={styles.editProfileBtn}>
                <button>
                    <a href="tel:+91-8287098287">
                        Call Now
                    </a>
                </button>
            </div>
        </div>
    )
}

export default MissedCallCard