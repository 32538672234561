import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import NavbarHandler from '../Helper/NavbarHandler'
import styles from '../Styles/Pages/About.module.css'

import ReactGA from 'react-ga';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname);
    }, [])

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);


    return (
        <div className={styles.mainContainer}>
            <NavbarHandler />
            <div className={styles.heroContainer}>
                <h1>About Stylelink</h1>
                <p className={styles.purposeTitle}>
                    <b>STYLELINK</b> was started with only ONE PURPOSE:
                </p>
                <p>
                    To <b>Serve & Empower</b> The People Of the Beauty Industry.
                    <br></br>
                    We created this platform for the artists and we dedicate it to them. STYLELINK is a platform where people of the same interests meet and share great enthusiasm for the industry.
                </p>
                <p>
                    Our MANTRA is Simple:
                    <br></br>
                    <b>Connect. Learn. Earn. Grow.</b>
                </p>
                <p>
                    We abide by this mantra and work hard every day to create a difference in people’s lives.
                </p>
            </div>
            <div className={styles.ourMission}>
                <div className={styles.leftBorder}></div>
                <div className={styles.ourMissionContent}>
                    <div className={styles.ourMissionContentContainer}>
                        <div className={styles.sectionalTitle}>
                            Our mission
                        </div>
                        <div className={styles.sectionalSubTitle}>
                            It’s simple. To transform the way people think about careers in the Beauty & Styling Industry.
                        </div>
                        <div className={styles.sectionalDescription}>
                            Our vision is to help aspiring Beauty Stylists & Struggling Salons in establishing a successful career.
                        </div>
                    </div>
                </div>
            </div>




            <Footer />
        </div>
    )
}

export default About