import React from 'react'
import EachFAQJob from './EachFAQJob'
import styles from './EachJobFAQs.module.css'

const EachJobFAQs = ({faqs}) => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.faqHeader}>
                <div className={styles.smallTitle}>
                    <h2>FAQs </h2>
                </div>
                <div className={styles.bigTitle}>
                    <h3>Frequently Asked Questions</h3>
                </div>
            </div>
            <div className={styles.faqBody}>
                {faqs.map((item, index) => (
                    <div className={styles.eachFAQWrapper} key={index}>
                        <EachFAQJob
                            question={item.question}
                            answer={item.answer}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EachJobFAQs