import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import styles from './SalonJobsFooter.module.css'

const SalonJobsFooter = () => {
    const [data, setData] = useState([])
    const [visibleCount, setVisibleCount] = useState(500) // Initial visible count
    const [showMoreVisible, setShowMoreVisible] = useState(true) // To toggle Show More button

    const getJobFooterLinks = async () => {
        try {
            const response = await fetch(BASE_URL_API + "/getJobFooterLinks", {
                method: "POST",
                body: JSON.stringify({})
            });

            if (!response.ok) {
                console.log("Something went wrong: Server Error");
                return;
            }

            const result = await response.json();
            if (result.status === "success") {
                setData(result.response);
                // Check if initially showing 500 results is enough
                if (result.response.length <= 500) {
                    setShowMoreVisible(false);
                }
            } else {
                setData([]);
            }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }

    useEffect(() => {
        getJobFooterLinks()
    }, [])

    const formatString = (pageUrl) => {
        if (typeof pageUrl !== 'string' || !pageUrl.trim()) {
            return '';
        }

        let formattedString = pageUrl.replace(/-/g, ' ');
        formattedString = formattedString.replace(/\b\w/g, char => char.toUpperCase());
        formattedString = formattedString.trim();

        return formattedString;
    }

    const showMore = () => {
        const newVisibleCount = visibleCount + 400;
        setVisibleCount(newVisibleCount);

        // Check if all results are now visible
        if (data.length <= newVisibleCount) {
            setShowMoreVisible(false);
        }
    }

    return (
        <div className={styles.mainWrapper}>
            <div>
                <div className={styles.title}>
                    <h3>More Important Job Links</h3>
                </div>
                <div className={styles.urlWrapper}>
                    {data.slice(0, visibleCount).map((item, index) => (
                        <Link key={index} to={`/${item?.pageUrl}`} className={styles.eachPageUrl}>
                            <div>
                                {formatString(item?.pageUrl)}
                            </div>
                        </Link>
                    ))}
                </div>
                {showMoreVisible && (
                    <div onClick={() => showMore()} className={styles.showMoreButton}>
                        <button>
                            Show more
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SalonJobsFooter
