import React, { useEffect } from 'react';
import { FaPlay, FaPlayCircle } from 'react-icons/fa';
import Footer from '../Components/Footer';
import NavbarHandler from '../Helper/NavbarHandler';
import styles from '../Styles/Pages/SLCs.module.css';

import ReactGA from 'react-ga';


const SLCs = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])
    



    return (
        <div className={styles.mainContainer}>
            <NavbarHandler />
            <section className={styles.heroContainer}>
                <div className={styles.centerContainer}>
                    <h1>Stylelink Learning Consultants (SLCs)</h1>
                    <h2>
                        Get an opportunity to earn with Stylelink
                    </h2>
                    <h3>
                        Become a Stylelink Learning Consultant today and change the way salon/beauty industry operates
                    </h3>
                </div>
            </section>
            <section className={styles.sectionTwo}>
                <div className={styles.bgimg1}>
                    <div className={styles.caption}>
                        <span className={styles.border}>EARN WITH STYLELINK</span>
                    </div>
                </div>

                <div className={styles.bgimg1Txt}>
                    <p>
                        Want to earn upto <b>₹30,000/- as extra income</b>? 
                        Become a Stylelink Learning Consultants today & start earning on your own terms.
                        Make it a full time, part time or flexi time earning opportunity,
                        the Stylelink Learning Consultants program is
                        for everyone looking at earning extra money with freedom & flexibility.
                    </p>
                    <div className={styles.watchThisVideoRow}>
                        <div className={styles.watchThisVideoBtnContainer}>
                            <FaPlayCircle className={styles.playBtn} size={60} />
                        </div>
                        <div className={styles.watchThisVideoMsg}>
                            Watch <b>this video </b> to know more about
                            Stylelink Learning Consultants Program
                        </div>
                    </div>
                </div>

                <div className={styles.bgimg2}>
                    <div className={styles.caption}>
                        <span className={styles.border, styles.borderSpanStyle} >LESS HEIGHT</span>
                    </div>
                </div>

                <div className={styles.bgimg2TxtContainer}>
                    <div className={styles.bgimg2ParaContainer}>
                        <p>Scroll up and down to really get the feeling of how Parallax Scrolling works.</p>
                    </div>
                </div>

                <div className={styles.bgimg3}>
                    <div className={styles.caption}>
                        <span className={styles.border, styles.borderSpanStyle}>SCROLL UP</span>
                    </div>
                </div>

                <div className={styles.bgimg2TxtContainer}>
                    <div className={styles.bgimg2ParaContainer}>
                        <p>Scroll up and down to really get the feeling of how Parallax Scrolling works.</p>
                    </div>
                </div>

                <div className={styles.bgimg1}>
                    <div className={styles.caption}>
                        <span className={styles.border}>COOL!</span>
                    </div>
                </div>

            </section>
            <Footer />
        </div>
    )
}

export default SLCs