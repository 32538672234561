import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import styles from './TrainYourStaff.module.css'

const TrainYourStaff = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.firstPaintWrapper}>
                <div className={styles.firstPaintContainer}>
                    <h1>
                        Educate / Train your salon staff
                    </h1>
                    <p>
                        Conduct a training needs analysis for employees and source appropriate training to help improve and develop their skills.
                    </p>

                    <div>
                        <button className={styles.contactUsBtn}>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.centersTagline}>
                    We have 7+ centres across the top metro cities in India
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TrainYourStaff