import React, { useState, useEffect, useRef } from 'react'
import styles from '../Styles/Components/BookYourSeatNow.module.css'
import OtpInput from 'react-otp-input';


import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
} from "react-shimmer-effects";
import { FaCheckDouble } from 'react-icons/fa';
import { BASE_URL_API } from '../References/urls';

let ageRange = [];

for (let index = 11; index < 70; index++) {
    ageRange.push(index)
}
const BookYourSeatNow = props => {
    const [resendOtpIn, setResendOtpIn] = useState(45)
    const [otpVerificationStatus, setOtpVerificationStatus] = useState('NOT_VERIFYING'); //VERIFYING, VERIFIED, INVALID_OTP, OTP_EXPIRED
    const [sendingOtpStatus, setSendingOtpStatus] = useState('NOT_SENDING'); // SENT, COULD_NOT_SENT, SENDING
    let workshopData = props.data;

    // console.log("All workshops data", workshopData)

    const getUpcomingWorkshops = () => {

    }


    const [isPhoneVerified, setIsPhoneVerified] = useState(false)
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [isOtpVerified, setIsOtpVerified] = useState(false)
    const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
    const [requestCount, setRequestCount] = useState(0);
    const [sessionKey, setSessionKey] = useState('');

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [workshop, setWorkshop] = useState('');
    const [location, setLocation] = useState('');
    const [age, setAge] = useState('');

    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [enteredOtp, setEnteredOtp] = useState({ otp: '' });

    const fullNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const workshopRef = useRef();
    const locationRef = useRef();
    const ageRef = useRef();

    const enterFullNameHandler = (k) => {
        if (k.length > 3) {
            fullNameRef.current.style.border = "1px solid rgba(255, 255, 255, 0.834)"
        }
        setFullName(k)
    }

    const enterEmailHandler = (k) => {
        if (k.length > 6) {
            emailRef.current.style.border = "1px solid rgba(255, 255, 255, 0.834)"
        }
        setEmail(k)
    }

    const enterPhoneHandler = (k) => {
        let p = k.toString();
        if (p.length == 10) {
            phoneRef.current.style.border = "1px solid rgba(255, 255, 255, 0.834)"
        } else {
            phoneRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
        }
        setPhone(k)
    }

    const enterOtpHandler = (otp) => {
        setOtpVerificationStatus('NOT_VERIFYING');
        setEnteredOtp({ otp })
        if (otp.length == 4) {
            verfiyWorkshopOtp(otp);
            console.log("let's verify otp")
        }
    }

    const selectWorkshopHandler = (k) => {
        if (k != '') {
            workshopRef.current.style.border = "1px solid rgba(255, 255, 255, 0.834)"
        } else {
            workshopRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
        }
        setWorkshop(k);
    }

    const enterLocationHandler = (k) => {
        if (k.length > 1) {
            locationRef.current.style.border = "1px solid rgba(255, 255, 255, 0.834)"
        } else {
            locationRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
        }
        setLocation(k)
    }

    const selectAgeHandler = (k) => {
        if (k != '') {
            ageRef.current.style.border = "1px solid rgba(255, 255, 255, 0.834)"
        } else {
            ageRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
        }
        setAge(k)
    }

    const validateFieldHandler = () => {
        if (fullName.length > 3) {
            if (email.length > 6) {
                if (workshop != '') {
                    if (location.length > 1) {
                        if (age != '') {
                            if (phone.length == 10) {
                                return true;
                            } else {
                                phoneRef.current.focus();
                                phoneRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
                                return false
                            }
                        } else {
                            ageRef.current.focus();
                            ageRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
                            return false
                        }
                    } else {
                        locationRef.current.focus();
                        locationRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
                        return false
                    }
                } else {
                    workshopRef.current.focus();
                    workshopRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
                    return false
                }
            } else {
                emailRef.current.focus();
                emailRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
                return false
            }
        } else {
            fullNameRef.current.focus();
            fullNameRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
            return false
        }
    }

    const bookNowHandler = () => {
        if (validateFieldHandler()) {
            sendOtpHandler()
        } else {
            console.log("field verification failed!")
        }
        console.log("I have clicked")
    }

    const sendOtpHandler = async () => {
        let p = phone.toString();
        if (p.length == 10) {
            setResendOtpIn(45);
            setSendingOtpStatus('SENDING');
            setOtpVerificationStatus('NOT_VERIFYING');
            const registerForWorkshopResponse = await fetch(BASE_URL_API + "/registerForWorkshop",
                {
                    method: "POST",
                    body: JSON.stringify({
                        actionType: 'sendOtp',
                        phone: phone,
                        fullName: fullName,
                        email: email,
                        requestCount: requestCount,
                        sessionKey: sessionKey
                    })
                });

            if (!registerForWorkshopResponse.ok) {
                console.log("Something went wrong, server Error!");
            } else {
                const registerForWorkshopRespo = await registerForWorkshopResponse.json();
                if (registerForWorkshopRespo.status == 'success') {
                    setIsOtpSent(true);
                    setSessionKey(registerForWorkshopRespo.sessionKey);
                    setRequestCount(registerForWorkshopRespo.requestCount);
                    setSendingOtpStatus('SENT');
                } else {
                    setSendingOtpStatus('COULD_NOT_SENT');
                    console.log("Something went wrong, we could not send a OTP, please try again!");
                }
                console.log(registerForWorkshopRespo);
            }

        } else {
            phoneRef.current.focus();
            phoneRef.current.style.border = "1px solid rgba(255, 0, 0, 0.747)";
            setPhoneError('Please enter 10 digit mobile number')
        }
    }



    useEffect(() => {
        if (isOtpSent) {
            let crot = resendOtpIn;
            const resendOtpTimer = setInterval(() => {
                if (crot > -1) {
                    setResendOtpIn(crot--);
                }
            }, 1000);

            return () => clearInterval(resendOtpTimer);
        }
    }, [isOtpSent, resendOtpIn])

    const verfiyWorkshopOtp = (k) => {
        setIsVerifyingOtp(true)
        setIsVerifyingOtp(false)
        registerForWorkshop(k);

    }

    const registerForWorkshop = async (k) => {
        // console.log(fullName, email, phone, workshop, location, age)
        setOtpVerificationStatus('VERIFYING');
        const registerForWorkshopResponse = await fetch(BASE_URL_API + "/registerForWorkshop",
            {
                method: "POST",
                body: JSON.stringify({
                    actionType: 'verifyOtp',
                    phone: phone,
                    fullName: fullName,
                    email: email,
                    requestCount: requestCount,
                    sessionKey: sessionKey,
                    otp: k,
                    workshopId: workshop
                })
            });

        if (!registerForWorkshopResponse.ok) {
            console.log("Something went wrong, server Error!");
        } else {
            const registerForWorkshopRespo = await registerForWorkshopResponse.json();
            if (registerForWorkshopRespo.status == 'success') {
                setIsOtpVerified(true)
                setOtpVerificationStatus('VERIFIED')
            } else {
                setIsOtpVerified(false)
                if (registerForWorkshopRespo.message === 'Invalid Otp') {
                    setOtpVerificationStatus('INVALID_OTP')
                } else {
                    setOtpVerificationStatus('NOT_VERIFYING')
                }
                console.log("Something went wrong, we could not verify the OTP, please try again!");
            }
            console.log(registerForWorkshopRespo);
        }


    }



    return (
        <section className={styles.bookYourSeatWrapper}>
            <div className={styles.mainTitle}>
                <h3>Book Your Seat Now</h3>
            </div>
            <div className={styles.mainContent}>
                {isOtpSent !== true ?
                    <div className={styles.fieldsWrapper}>
                        <div className={styles.eachrow}>
                            <div className={styles.eachCol}>
                                <div className={styles.eachField}>
                                    <div className={styles.efLabel}>
                                        Enter Your Full Name
                                    </div>
                                    <div className={styles.efField}>
                                        <input
                                            value={fullName}
                                            ref={fullNameRef}
                                            placeholder="Please enter the full name..."
                                            onChange={(e) => enterFullNameHandler(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachCol}>
                                <div className={styles.eachField}>
                                    <div className={styles.efLabel}>
                                        Enter Your E-mail
                                    </div>
                                    <div className={styles.efField}>
                                        <input
                                            ref={emailRef}
                                            placeholder="Please enter your e-mail..."
                                            onChange={(e) => enterEmailHandler(e.target.value)}
                                            value={email}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.eachrow}>
                            <div className={styles.eachCol}>
                                <div className={styles.eachField}>

                                    <div className={styles.efLabel}>
                                        Please enter your phone number
                                    </div>

                                    <div className={styles.efField}>
                                        <input
                                            className={styles.phoneNumber} placeholder="Please enter your 10 digits phone..."
                                            type="number"
                                            onChange={(e) => enterPhoneHandler(e.target.value)}
                                            ref={phoneRef}
                                            value={phone}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachCol}>
                                <div className={styles.eachField}>
                                    <div className={styles.efLabel}>
                                        Please select workshop
                                    </div>
                                    <div className={styles.efField}>
                                        <select
                                            onChange={(e) => selectWorkshopHandler(e.target.value)}
                                            ref={workshopRef}
                                        >
                                            <option value=''>Select Workshop</option>
                                            {workshopData != null &&
                                                workshopData.filter((item) => new Date(item.startingTime).toISOString() > new Date().toISOString()).map((item, index) => (
                                                    <option value={item.identifier} selected={item.identifier == workshop && 'selected'} >{item.workshopTitle}</option>
                                                ))}


                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.eachrow}>
                            <div className={styles.eachCol}>
                                <div className={styles.eachField}>
                                    <div className={styles.efLabel}>
                                        Please enter your city/town/area
                                    </div>
                                    <div className={styles.efField}>
                                        <input
                                            ref={locationRef}
                                            value={location}
                                            placeholder="Please enter your city / town / address..."
                                            onChange={(e) => enterLocationHandler(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachCol}>
                                <div className={styles.eachField}>
                                    <div className={styles.efLabel}>
                                        Please select your age
                                    </div>
                                    <div className={styles.efField}>
                                        <select
                                            ref={ageRef}
                                            onChange={(e) => selectAgeHandler(e.target.value)}
                                        >
                                            <option value=''>Please select your Age</option>
                                            {ageRange.map((item, index) => (
                                                <option value={item} key={index} selected={item == age && 'selected'} >{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.bookNowBtnContainer}>
                            {sendingOtpStatus === 'NOT_SENDING' ?
                                <div>
                                    <button onClick={() => bookNowHandler()}>Book Now</button>
                                </div>
                                :
                                sendingOtpStatus === 'SENDING' ?
                                    <div>
                                        <button>Hold On! We are just sending an OTP for verification!</button>
                                    </div>
                                    : sendingOtpStatus === 'COULD_NOT_SENT' ?
                                        <div>
                                            <div className={styles.couldNotSendOtpWrapper}>
                                                <div>
                                                    Unfortunately, we could not send OTP on your phone number. Please try again!
                                                </div>
                                                <div>
                                                    If problem persists, please contact our support team!
                                                </div>
                                            </div>
                                            <div>
                                                <button onClick={() => bookNowHandler()}>Book Now</button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            Otp sent!
                                        </div>
                            }
                        </div>
                    </div>
                    :
                    <div className={styles.enterOtpWrapper}>
                        {otpVerificationStatus === 'VERIFIED' ?
                            <div className={styles.otpVerifiedContainer}>
                                <h4>OTP Successfully Verified!</h4>
                                <h3>
                                    Congratulations, you have been successfully registered for the Stylelink workshop!
                                </h3>
                                <h5>
                                    We will share further details via e-mail and phone!
                                </h5>
                                <div className={styles.verifiedIcon}>
                                    <FaCheckDouble color="green" size={34} />
                                </div>
                            </div>
                            :

                            <div className={styles.otpEnterContainer}>
                                <div className={styles.eachField}>
                                    <div className={styles.efLabel}>
                                        <span>Please enter the OTP received on +91-{phone}</span>
                                        <button onClick={() => setIsOtpSent(false)}>Change Phone</button>
                                    </div>
                                    <div className={styles.efField}>
                                        <OtpInput
                                            value={enteredOtp.otp}
                                            onChange={(otp) => enterOtpHandler(otp)}
                                            numInputs={4}
                                            separator={<span>-</span>}
                                            containerStyle={{
                                                padding: "8px 8px",
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                            inputStyle={{
                                                border: "1px solid #dfdfdf",
                                                width: "30px",
                                                borderRadius: "4px",
                                                margin: "0px 6px",
                                                color: "white"

                                            }}
                                            isInputNum={true}
                                        />
                                    </div>
                                </div>

                                {otpVerificationStatus === 'NOT_VERIFYING' ?
                                    <div>
                                        <div className={isOtpVerified === true ? styles.optActionContainerCenter : styles.optActionContainer}>
                                            {isOtpVerified !== true &&
                                                <>
                                                    {resendOtpIn < 1 ?
                                                        <button className={styles.resendOtpBtn} onClick={() => sendOtpHandler()} >Resend OTP</button>
                                                        :
                                                        <button className={styles.resendOtpBtn}>Resend Otp in {resendOtpIn}</button>}
                                                </>
                                            }
                                            {enteredOtp.otp.length == 4 ?
                                                isVerifyingOtp === true ?
                                                    <button className={styles.verifyingOtp}>
                                                        Verifying Otp..
                                                    </button>
                                                    :
                                                    isOtpVerified === true ?
                                                        <span></span>
                                                        :
                                                        <button className={styles.submitBtnDull} onClick={() => alert('Please enter the correct OTP')} >Submit</button>
                                                :
                                                <button className={styles.submitBtnDull} onClick={() => alert('Please enter the correct OTP')} >Submit</button>
                                            }
                                        </div>
                                    </div>
                                    : otpVerificationStatus === 'INVALID_OTP' ?
                                        <div>
                                            <div className={styles.otpVerifyResponseWrapper}>
                                                <div className={styles.invalidOtpWrapper}>
                                                    <div>
                                                        Invalid OTP!
                                                    </div>
                                                    <div>
                                                        Please enter the correct OTP
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={isOtpVerified === true ? styles.optActionContainerCenter : styles.optActionContainer}>
                                                {isOtpVerified !== true &&
                                                    <>
                                                        {resendOtpIn < 1 ?
                                                            <button className={styles.resendOtpBtn} onClick={() => sendOtpHandler()} >Resend OTP</button>
                                                            :
                                                            <button className={styles.resendOtpBtn}>Resend Otp in {resendOtpIn}</button>}
                                                    </>
                                                }
                                                {enteredOtp.otp.length == 4 ?
                                                    isVerifyingOtp === true ?
                                                        <button className={styles.verifyingOtp}>
                                                            Verifying Otp..
                                                        </button>
                                                        :
                                                        isOtpVerified === true ?
                                                            <span></span>
                                                            :
                                                            <button className={styles.submitBtnDull} onClick={() => alert('Please enter the correct OTP')} >Submit</button>
                                                    :
                                                    <button className={styles.submitBtnDull} onClick={() => alert('Please enter the correct OTP')} >Submit</button>
                                                }
                                            </div>

                                        </div>
                                        : otpVerificationStatus === 'VERIFYING' ?
                                            <div>
                                                <div className={styles.otpVerifyResponseWrapper}>
                                                    <div className={styles.verifyingOtp}>
                                                        <div>
                                                            Hold On!
                                                        </div>
                                                        <div>
                                                            We are just verifying your OTP
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            <div></div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default BookYourSeatNow