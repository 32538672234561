import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import OtpInput from 'react-otp-input'
import { useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import NavbarHandler from '../../Helper/NavbarHandler'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/AuthContext'
import LoginForWorkshop from '../Authentication/LoginForWorkshop'
import styles from './RegisterForWorkshop.module.css'
import RegisterForWorkshopIndividual from './RegisterForWorkshopIndividual'


const RegisterForWorkshop = () => {
    const { workshopIdentifier } = useParams()
    const authCtx = useContext(AuthContext)





    return (
        <div>
            {authCtx?.isLoggedIn === false ?
                <LoginForWorkshop />
                :
                <RegisterForWorkshopIndividual workshopIdentifier={workshopIdentifier} />
            }
        </div>
    )
}

export default RegisterForWorkshop