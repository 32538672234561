import React, { useContext } from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import Verify from '../Certificates/Verify'
import GCWCertificate from '../Components/Workshops/GCWCertificate'
import GCWCertificateAdmittance from '../Components/Workshops/GCWCertificateAdmittance'
import GCWCertificateAssessmentCover from '../Components/Workshops/GCWCertificateAssessmentCover'
import GCWCertificateAssessmentExam from '../Components/Workshops/GCWCertificateAssessmentExam'
import GCWCertificateAssessmentResult from '../Components/Workshops/GCWCertificateAssessmentResult'
import Beauty from '../Courses/Beauty'
import Hair from '../Courses/Hair'
import Makeup from '../Courses/Makeup'
import PremiumAsset from '../Courses/PremiumAsset'
import PremiumCategory from '../Courses/PremiumCategory'
import SearchAmongCourses from '../Courses/SearchAmongCourses'
import EachJob from '../Jobs/EachJob'
import JobsListing from '../Jobs/JobsListing'
import About from '../Pages/About'
import LoginForWorkshop from '../Pages/Authentication/LoginForWorkshop'
import BPProducts from '../Pages/BrandPartners/BPProducts'
import CHCourseEnquiryForm from '../Pages/Classroom/CHCourseEnquiryForm'
import ClassroomHome from '../Pages/Classroom/ClassroomHome'
import { HairPLP } from '../Pages/Classroom/PromotionalLandingPages/HairPLP'
import MakeupPromotionalLandingPage from '../Pages/Classroom/PromotionalLandingPages/MakeupPromotionalLandingPage'
import Community from '../Pages/Community'
import Courses from '../Pages/Courses'
import HireSalonStaffHome from '../Pages/ForBusinesses/HireSalonStaffHome'
import HireSalonStaffQuickly from '../Pages/ForBusinesses/HireSalonStaffQuickly'
import SubscriptionsPricing from '../Pages/ForBusinesses/SubscriptionsPricing'
import FindSalonJobs from '../Pages/LandingPages/FindSalonJobs'
import ForSalon from '../Pages/LandingPages/ForSalon'
import Home from '../Pages/LandingPages/Home'
import JobsListingCategorised from '../Pages/LandingPages/JobsListingCategorised'
import Resume from '../Pages/LandingPages/Resume'
import Login from '../Pages/Login'
import OurPresence from '../Pages/OurPresence'
import PrivacyPolicy from '../Pages/PrivacyPolicy'
import InterviewDetails from '../Pages/Recruitment/InterviewDetails'
import RefundPolicy from '../Pages/RefundPolicy'
import SLCs from '../Pages/SLCs'
import TermsOfUse from '../Pages/TermsOfUse'
import Trainings from '../Pages/Trainings'
import TrainYourStaff from '../Pages/TrainYourStaff'
import UnderConstruction from '../Pages/UnderConstruction'
import Workshops from '../Pages/Workshops'
import PremiumAssestsWorkshops from '../Pages/Workshops/PremiumAssestsWorkshops'
import RegisterForWorkshop from '../Pages/Workshops/RegisterForWorkshop'
import RegisterWorkshopPaymentStatus from '../Pages/Workshops/RegisterWorkshopPaymentStatus'
import AuthContext from '../store/AuthContext'



const MainNavigator = () => {
    const authCtx = useContext(AuthContext)
    let isLoggedIn = authCtx.isLoggedIn

    console.log(authCtx)

    if (!isLoggedIn) {
        return (
            <Routes>
                <Route path="/login" element={<LoginForWorkshop />}></Route>


                <Route path="*" element={<Home />} ></Route>
                <Route path="/" element={<Home />} ></Route>
                <Route path="/home" element={<Home />} ></Route>
                <Route path="/slc" element={<SLCs />} ></Route>
                <Route path="/about" element={<About />} ></Route>
                <Route path="/courses" element={<Courses />} ></Route>

                <Route path="/online-courses/:category" element={<PremiumCategory />} ></Route>
                <Route path="/online-course/:category/:identifier" element={<PremiumAsset />} ></Route>
                <Route path="/online-courses/search" element={<SearchAmongCourses />} />
                <Route path="/privacy" element={<PrivacyPolicy />} ></Route>
                <Route path="/terms-of-use" element={<TermsOfUse />} ></Route>
                <Route path="/jobs" element={<JobsListing />} ></Route>
                <Route path="/job/:route" element={<EachJob />} ></Route>
                <Route path="/job/:route/:trackIdentifier" element={<EachJob />} ></Route>
                <Route path="/certificate/verify/:certificateIdentifier" element={<Verify />} ></Route>

                <Route path="/login" element={<Login />} ></Route>
                <Route path="/workshops" element={<Workshops />} ></Route>
                <Route path="/workshop/:identifier" element={<PremiumAssestsWorkshops />} ></Route>

                <Route path="/workshop/certificate/admittance/:identifier" element={<GCWCertificateAdmittance />} ></Route>
                <Route path="/workshop/certificate/assessment/:identifier/cover" element={<GCWCertificateAssessmentCover />} ></Route>
                <Route path="/workshop/certificate/assessment/:identifier/exam" element={<GCWCertificateAssessmentExam />} ></Route>
                <Route path="/workshop/certificate/assessment/:identifier/result" element={<GCWCertificateAssessmentResult />} ></Route>
                <Route path="/workshop/certificate/:identifier" element={<GCWCertificate />} ></Route>

                <Route path="/training" element={<Trainings />} ></Route>
                <Route path="/community" element={<Community />} ></Route>


                {/* adding this on 9th october 2023 */}
                <Route path="/hire-salon-staff" element={<HireSalonStaffHome />} ></Route>
                <Route path="/hire-salon-staff/quickly" element={<HireSalonStaffQuickly />} ></Route>


                {/* adding this on 19th october 2023 */}
                <Route path="/classroom-courses" element={<ClassroomHome />} ></Route>
                <Route path="/classroom-courses/make-up-courses" element={<MakeupPromotionalLandingPage />} ></Route>
                <Route path="/classroom-courses/:categoryIdentifier/enroll-now" element={<CHCourseEnquiryForm />}></Route>

                {/* adding this on 22nd October 2023 */}
                <Route path="/classroom-courses/hair-dresser-courses" element={<HairPLP />} ></Route>


                {/*adding this on 2nd November 2023 */}
                <Route path="/brand-partners/:category" element={<BPProducts />} ></Route>

                {/* adding  this on 24th Feb 2024 */}
                <Route path="/recruitment/interview-details/:identifier" element={<InterviewDetails />} ></Route>

                {/* adding this on 10th May 2024 */}
                <Route path="/hire-salon-staff/pricing" element={<SubscriptionsPricing />} ></Route>



                {/* adding this on 1st July 2024 */}
                <Route path="/refund-policy" element={<RefundPolicy />} ></Route>

                {/* adding this on 8th july for online paid workshops  */}
                <Route path="/workshop/:workshopIdentifier/register" element={<RegisterForWorkshop />} ></Route>

                {/* adding this on 20th July 2024 as now we need to share our presence information with YesMadam */}
                <Route path="/our-presence" element={<OurPresence />} ></Route>
                <Route path="/train-your-salon-staff" element={<TrainYourStaff />} ></Route>

                {/* adding this on 27th August 2024 as now we need to share our deck with investors */}
                <Route path="/get-my-salon-resume" element={<Resume />} ></Route>
                <Route path="/solutions-for-businesses" element={<ForSalon />} ></Route>

                {/* adding this on 28th August 2024 as now we need to share our deck with investors */}
                <Route path="/find-salon-jobs" element={<FindSalonJobs />} ></Route>
                
                {/* adding this on 30th August 2024 as now we need to share our deck with investors */}
                <Route path="/:routeIdentifier" element={<JobsListingCategorised />} ></Route>

            </Routes>
        )
    } else {
        return (
            <Routes>
                {/* <Route path="/" element={<UnderConstruction />} ></Route> */}
                <Route path="*" element={<Home />} ></Route>
                <Route path="/" element={<Home />} ></Route>
                <Route path="/home" element={<Home />} ></Route>
                <Route path="/slc" element={<SLCs />} ></Route>
                <Route path="/about" element={<About />} ></Route>
                <Route path="/courses" element={<Courses />} ></Route>

                <Route path="/online-courses/:category" element={<PremiumCategory />} ></Route>
                <Route path="/online-course/:category/:identifier" element={<PremiumAsset />} ></Route>
                <Route path="/online-courses/search" element={<SearchAmongCourses />} />
                <Route path="/privacy" element={<PrivacyPolicy />} ></Route>
                <Route path="/terms-of-use" element={<TermsOfUse />} ></Route>
                <Route path="/jobs" element={<JobsListing />} ></Route>
                <Route path="/job/:route" element={<EachJob />} ></Route>
                <Route path="/job/:route/:trackIdentifier" element={<EachJob />} ></Route>
                <Route path="/certificate/verify/:certificateIdentifier" element={<Verify />} ></Route>

                <Route path="/login" element={<Login />} ></Route>
                <Route path="/workshops" element={<Workshops />} ></Route>
                <Route path="/workshop/:identifier" element={<PremiumAssestsWorkshops />} ></Route>

                <Route path="/workshop/certificate/admittance/:identifier" element={<GCWCertificateAdmittance />} ></Route>
                <Route path="/workshop/certificate/assessment/:identifier/cover" element={<GCWCertificateAssessmentCover />} ></Route>
                <Route path="/workshop/certificate/assessment/:identifier/exam" element={<GCWCertificateAssessmentExam />} ></Route>
                <Route path="/workshop/certificate/assessment/:identifier/result" element={<GCWCertificateAssessmentResult />} ></Route>
                <Route path="/workshop/certificate/:identifier" element={<GCWCertificate />} ></Route>

                <Route path="/training" element={<Trainings />} ></Route>
                <Route path="/community" element={<Community />} ></Route>


                {/* adding this on 9th october 2023 */}
                <Route path="/hire-salon-staff" element={<HireSalonStaffHome />} ></Route>
                <Route path="/hire-salon-staff/quickly" element={<HireSalonStaffQuickly />} ></Route>


                {/* adding this on 19th october 2023 */}
                <Route path="/classroom-courses" element={<ClassroomHome />} ></Route>
                <Route path="/classroom-courses/make-up-courses" element={<MakeupPromotionalLandingPage />} ></Route>
                <Route path="/classroom-courses/:categoryIdentifier/enroll-now" element={<CHCourseEnquiryForm />}></Route>

                {/* adding this on 22nd October 2023 */}
                <Route path="/classroom-courses/hair-dresser-courses" element={<HairPLP />} ></Route>


                {/*adding this on 2nd November 2023 */}
                <Route path="/brand-partners/:category" element={<BPProducts />} ></Route>

                {/* adding  this on 24th Feb 2024 */}
                <Route path="/recruitment/interview-details/:identifier" element={<InterviewDetails />} ></Route>

                {/* adding this on 10th May 2024 */}
                <Route path="/hire-salon-staff/pricing" element={<SubscriptionsPricing />} ></Route>



                {/* adding this on 1st July 2024 */}
                <Route path="/refund-policy" element={<RefundPolicy />} ></Route>

                {/* adding this on 8th july 2024 for online paid workshops  */}
                <Route path="/workshop/:workshopIdentifier/register" element={<RegisterForWorkshop />} ></Route>
                <Route path="/workshop/:workshopIdentifier/payment-status" element={<RegisterWorkshopPaymentStatus />} ></Route>

                {/* adding this on 20th July 2024 as now we need to share our presence information with YesMadam */}
                <Route path="/our-presence" element={<OurPresence />} ></Route>
                <Route path="/train-your-salon-staff" element={<TrainYourStaff />} ></Route>

                {/* adding this on 27th August 2024 as now we need to share our deck with YC */}
                <Route path="/get-my-salon-resume" element={<Resume />} ></Route>
                <Route path="/solutions-for-businesses" element={<ForSalon />} ></Route>


                {/* adding this on 28th August 2024 as now we need to share our deck with investors */}
                <Route path="/find-salon-jobs" element={<FindSalonJobs />} ></Route>

                {/* adding this on 30th August 2024 as now we need to share our deck with investors */}
                <Route path="/:routeIdentifier" element={<JobsListingCategorised />} ></Route>


            </Routes>
        )
    }
}

export default MainNavigator