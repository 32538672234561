import React, { useEffect, useState } from 'react'
import { FaPlayCircle } from 'react-icons/fa'
import { BASE_URL_API } from '../References/urls';

import styles from '../Styles/Courses/EachSubModules.module.css'

const EachSubModules = props => {
    const moduleId = props.moduleId;
    const [subModules, setSubModules] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const getSubModuleData = async () => {
        setIsLoading(true);
        const getSubModuleDataResponse = await fetch(BASE_URL_API + "/getSubModules",
            {
                method: "POST",
                body: JSON.stringify({
                    moduleId: moduleId
                })
            });

        if (!getSubModuleDataResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getSubModuleDataRespo = await getSubModuleDataResponse.json();
            setSubModules(getSubModuleDataRespo.response);
            // console.log(getSubModuleDataRespo);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getSubModuleData()
    }, [moduleId])

    if (isLoading) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <>
            {subModules != null &&
                <>
                    {subModules.map((item, index) => (
                        <div className={styles.eachModuleContent}>
                            <div className={styles.eachModuleLeft}>
                                <div className={styles.subModuleIcon}>
                                    <FaPlayCircle />
                                </div>
                                <div className={styles.subModuleTitle}>
                                    <h5>{item.subModuleTitle}</h5>
                                </div>
                            </div>
                            <div className={styles.eachModuleRight}>
                                07:28
                            </div>
                        </div>
                    ))}
                </>
            }
        </>

    )
}

export default EachSubModules