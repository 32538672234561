import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import NavbarHandler from '../Helper/NavbarHandler'
import styles from '../Styles/Pages/Community.module.css'

import ReactGA from 'react-ga';

const Community = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])
  

  return (
      <div>
          <NavbarHandler type='home' />
          <div className={styles.mainContainer}>Download the stylelink app to join the community</div>
          <Footer />
      </div>
  )
}

export default Community