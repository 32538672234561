import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { FaChevronRight, FaExpand, FaMinus } from 'react-icons/fa'
import styles from '../Styles/Components/EachFAQCourse.module.css'

const EachFAQCourse = props => {
    let question = props.question;
    let answer = props.answer;
    const [isOpen, setIsOpen] = useState(true);
    return (
        <Collapsible
            trigger={
                <div className={styles.eachFAQQuestion}>
                    <div className={styles.questionContainer}>
                        <div className={styles.question}>
                            <h4>
                                {question}
                            </h4>
                        </div>
                    </div>
                    <div>
                        {isOpen == true ?
                            <FaMinus />
                            :
                            <FaExpand />
                        }
                    </div>
                </div>
            }
            open={true}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
        >
            <div className={styles.eachFAQAnswer}>
                <p>
                    {answer}
                </p>
            </div>
        </Collapsible>
    )
}

export default EachFAQCourse